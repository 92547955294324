import axios from "axios";
import { encryption } from '../components/utils/utils.js';
import { getApiBase } from "../env.js";
// const api_base = "https://hrmsapi.resolutesolutions.co/";
// const api_base = "http://hrmsapi.resolutesolutions.in.net/";

const api_base = getApiBase();
export const Login_api = async (username, password) => {
  try {
    const response = await axios.post(api_base + "UserLoginDet", {
      Username: username,
      Password: password,
      // headers: {
      //   'Authorization': `Bearer ${localStorage.getItem("token")}`
      // }
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const forgot_pass = async (mail) => {
  try {
    const response = await axios.post(api_base + "ForgotPassword/ForgetPasswordOTPData", {
      Username: encryption(mail),

    });
    return response;
  } catch (error) {
    return error;
  }
};

export const otp_api = async (otp, Username) => {
  try {
    const response = await axios.post(api_base + "ForgotPassword/forgetpasswordOTPCheck", {
      OTP: otp,
      Username: encryption(Username),
    });
    return response;
  } catch (error) {
    return error;
  }
};

// export const resend_otp_api = async (mail) => {
//   try {
//     const response = await axios.post(api_base + "ForgotPassword/resendForgetPasswordOTPData", {
//       EmailID: mail,
//     });
//     return response;
//   } catch (error) {
//     return error;
//   }
// };

export const reset_pass = async (OTP, Username, password) => {
  try {
    const response = await axios.patch(api_base + "ForgotPassword/passwordUpdate", {
      OTP: OTP,
      Username: encryption(Username),
      Password: encryption(password)
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const Designation_entry = async (data) => {
  try {
    const response = await axios.post(api_base + "Designation/", {
      DesignationName: data.designation,
      DesignationSName: data.shortName,
      Description: data.description,
      IsActive: data.active,
      AddedBy: 'John Doe'
    },
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      });
    return response;
  } catch (error) {
    return error.response;
  }
};
export const Designation_get = async () => {
  try {
    const response = await axios.get(`${api_base}Designation/GetDesignationDropdown`, {

      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

export const ExportDesignation_api = async () => {
  try {
    const response = await axios.get(`${api_base}Designation/DesignationExcelFileDownload`, {

      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}
export const Designation_entry_get = async (data) => {
  try {
    const response = await axios.get(`${api_base}Designation/GetDesignationColumnChoose`, {
      params: {
        filter: data.filter,
        page: data.page,
        limit: data.limit,
      },
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    return error.response;
  }
}
export const Designation_get_by_id = async (id) => {
  try {
    const response = await axios.get(`${api_base}Designation/GetDesignationData/${id}`, {

      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    return error.response;
    // // Handle the error more gracefully, e.g., log it or throw a custom error
    // console.error('Error fetching designation data:', error);
    // throw error;
  }
}

export const ImportDesignation_api = async (FileName) => {
  try {

    const response = await axios.post(`${api_base}Designation/DesignationExcelFileImport`,
      { FileName: FileName }, // Move params to the data object
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      }
    );
    return response; // Assuming you are interested in the response data
  } catch (error) {
    console.error('Error importing designation:', error);
    return error.response;
  }
}
export const ImportDesignation_api_final = async (FileName) => {
  try {

    const response = await axios.post(`${api_base}Designation/DesignationExcelFileImportFinal`,
      { FileName: FileName }, // Move params to the data object
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      }
    );
    return response; // Assuming you are interested in the response data
  } catch (error) {
    console.error('Error importing designation:', error);
    return error.response;
  }
}

export const DeleteDesignation_by_id = async (id) => {
  try {
    const response = await axios.delete(`${api_base}Designation/deleteDesignationData/${id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    return error.response;
  }
}

export const DesignationConfigurationSave = async (data) => {
  try {
    const response = await axios.put(`${api_base}Designation/UpdateDesignationConfiguration/`, data, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    return error.response;
  }
}

export const Designation_edit = async (id, data) => {
  try {
    const response = await axios.patch(api_base + `Designation/UpdateDesignationData/${id}`, {
      DesignationName: data.designation,
      DesignationSName: data.shortName,
      Description: data.description,
      IsActive: data.active,
      AddedBy: 'John Doe'
    },
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const Department_entry = async (data) => {
  try {
    const response = await axios.post(api_base + "Department/", {
      DepartmentName: data.department,
      DepartmentSName: data.shortName,
      Description: data.description,
      IsActive: data.active,
      AddedBy: 'John Doe',
      LastModifiedBy: 'John Doe',
    },
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      });
    return response;
  } catch (error) {
    return error.response;
  }
};
export const Department_get = async () => {
  try {
    const response = await axios.get(`${api_base}Department/GetDepartmentDropdown`, {

      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}
export const Department_export_api = async () => {
  try {
    const response = await axios.get(`${api_base}Department/DepartmentExcelFileDownload`, {

      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}
export const Department_entry_get = async (data) => {
  try {

    const response = await axios.get(api_base + "Department/GetDepartmentColumnChoose/", {
      params: {
        filter: data.filter,
        page: data.page,
        limit: data.limit,
      },
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response;
  } catch (error) {
    return error.response;
  }
};
export const Department_entry_get_by_id = async (id) => {
  try {

    const response = await axios.get(api_base + `Department/GetDepartmentData/${id}`, {

      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const Department_edit = async (id, data) => {
  try {
    const response = await axios.patch(api_base + `Department/UpdateDepartmentData/${id}`, {
      DepartmentName: data.department,
      DepartmentSName: data.shortName,
      DepartmentID: id,
      Description: data.description,
      IsActive: data.active,
      AddedBy: 'John Doe',
      LastModifiedBy: 'John Doe',
    },
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      });
    return response;
  } catch (error) {
    return error.response;
  }
};
export const Delete_department_by_id = async (id) => {
  try {
    const response = await axios.delete(`${api_base}Department/deleteDepartmentData/${id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    return error.response;
  }
}
export const Department_configiration_update = async (data) => {
  try {
    const response = await axios.put(`${api_base}Department/UpdateDepartmentConfiguration/`, data, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    return error;
  }
}
export const ImportDepartment_api = async (FileName) => {
  try {

    const response = await axios.post(`${api_base}Department/DepartmentExcelFileImport`,
      { FileName: FileName }, // Move params to the data object
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      }
    );
    return response; // Assuming you are interested in the response data
  } catch (error) {
    console.error('Error importing designation:', error);
    return error.response;
  }
}
export const ImportDepartment_api_final = async (FileName) => {
  try {

    const response = await axios.post(`${api_base}Department/DepartmentExcelFileImportFinal`,
      { FileName: FileName }, // Move params to the data object
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      }
    );
    return response; // Assuming you are interested in the response data
  } catch (error) {
    console.error('Error importing designation:', error);
    return error.response;
  }
}

export const User_entry_api = async (data) => {
  try {
    const response = await axios.post(api_base + "Users/", {
      Username: data.Username,
      Password: encryption(data.Password),
      FirstName: data.Firstname,
      LastName: data.Lastname,
      MobileNumber1: data.Mobilenumber1,
      MobileNumber2: data.Mobilenumber2,
      EmailID: encryption(data.Emailid),
      HomeAddress: data.Homeaddress,
      DesignationID: Number(data.Designation),
      Gender: Number(data.Gender),
      DepartmentID: Number(data.Department),
      IsTL: data.Role,
      Remarks: data.Remarks,
      AddedBy: 'john',
      LastModifiedBy: 'john',
      UserAccessData: data.UserAccessData
    },
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const User_PageAccess = async (id) => {
  try {
    const response = await axios.post(api_base + "Users/PageAccess/", {
      PageID: id,

    },
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const User_get = async (data) => {
  try {
    const response = await axios.get(api_base + "Users/GetUsersColumnChoose/", {
      params: {
        filter: data.filter,
        page: data.page,
        limit: data.limit,
      },
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const User_get_by_id = async (id) => {
  try {
    const response = await axios.get(api_base + `Users/GetUserData/${id}`, {

      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const User_configiration_update = async (data) => {
  try {
    const response = await axios.put(`${api_base}Users/UpdateUsersConfiguration/`, data, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    return error;
  }
};
export const Delete_User_by_id = async (id) => {
  try {
    const response = await axios.delete(`${api_base}Users/deleteUsersData/${id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    return error.response;
  }
}
export const User_edit = async (id, data) => {
  try {

    const response = await axios.patch(api_base + `Users/UpdateUsersData/${id}`, {
      Username: data.Username,
      Password: data.Password ? encryption(data.Password) : '',
      FirstName: data.Firstname,
      LastName: data.Lastname,
      MobileNumber1: data.Mobilenumber1,
      MobileNumber2: data.Mobilenumber2,
      EmailID: encryption(data.Emailid),
      HomeAddress: data.Homeaddress,
      DesignationID: data.Designation,
      DepartmentID: data.Department,
      IsTL: data.Role,
      Remarks: data.Remarks,
      AddedBy: 'john',
      LastModifiedBy: 'john',
      Gender: data.Gender,
      UserAccessData: data.UserAccessData

    },
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      });
    return response;
  } catch (error) {
    return error.response;
  }
};
export const ExportUser_api = async () => {
  try {
    const response = await axios.get(`${api_base}Users/UsersExcelFileDownload`, {

      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}
export const ImportUser_api = async (FileName) => {
  try {

    const response = await axios.post(`${api_base}Users/UsersExcelFileImport`,
      { FileName: FileName }, // Move params to the data object
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      }
    );
    return response; // Assuming you are interested in the response data
  } catch (error) {
    console.error('Error importing designation:', error);
    return error.response;
  }
}
export const ImportUser_api_final = async (FileName) => {
  try {

    const response = await axios.post(`${api_base}Users/UsersExcelFileImportFinal`,
      { FileName: FileName }, // Move params to the data object
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      }
    );
    return response; // Assuming you are interested in the response data
  } catch (error) {
    return error.response;
  }
}
export const ImportFileFormatUser_api = async (FileName) => {
  try {

    const response = await axios.get(`${api_base}Users/UsersExcelFileDownloadDemo`,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      }
    );
    return response; // Assuming you are interested in the response data
  } catch (error) {
    return error.response;
  }
}

export const UserPages_get = async () => {
  try {
    const response = await axios.get(`${api_base}Users/GetUserPages`, {

      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

// Client Panel api

export const Client_get = async (data) => {
  try {
    const response = await axios.get(api_base + "Client/GetClientColumnChoose/", {
      params: {
        filter: data.filter,
        page: data.page,
        limit: data.limit,
      },
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response;
  } catch (error) {
    return error.response;
  }
};


export const Client_entry = async (data) => {
  try {
    const updatedPOCDetData = data.ClientPOCDetData.map(contact => {
      return {
        ...contact,
        EmailID: encryption(contact.EmailID)
      };
    });
    const response = await axios.post(api_base + "Client/", {

      OrgName: data.OrgName,
      RegisteredEmailID: encryption(data.RegisteredEmailID),
      LocationName: data.LocationName,
      AddLine2: data.AddLine2,
      CityID: data.CityID,
      LegalFilingStatus: data.LegalFilingStatus,
      RegisterdMobileNo: data.RegisterdMobileNo,
      AddLine1: data.AddLine1,
      StateID: data.StateID,
      Pincode: data.Pincode,

      PAN: data.PAN,
      IsGSTApplicable: data.IsGSTApplicable,
      GSTIN: data.GSTIN,
      IsPFApplicable: data.IsPFApplicable,
      PFNumber: data.PFNumber,
      PFPassword: data.PFPassword,
      PFMobileNo: data.PFMobileNo,
      PFUsername: data.PFUsername,
      PFEmailID: encryption(data.PFEmailID),

      IsESICApplicable: data.IsESICApplicable,
      ESICNumber: data.ESICNumber,
      ESICPassword: data.ESICPassword,
      ESICMobileNo: data.ESICMobileNo,
      ESICUsername: data.ESICUsername,
      ESICEmailID: encryption(data.ESICEmailID),

      IsPTApplicable: data.IsPTApplicable,
      PTNumber: data.PTNumber,
      PTEmployerNo: data.PTEmployerNo,
      PTEmployeeNo: data.PTEmployeeNo,

      IsFactoryLicenseApplicable: data.IsFactoryLicenseApplicable,
      FactoryLicenseNo: data.FactoryLicenseNo,
      EndDate: data.EndDate,
      StartDate: data.StartDate,

      ClientPOCDetData: updatedPOCDetData,
    },
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const Client_configiration_update = async (data) => {
  try {
    const response = await axios.put(`${api_base}Client/UpdateClientConfiguration/`, data, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    return error;
  }
};
export const Delete_Client_by_id = async (id) => {
  try {
    const response = await axios.delete(`${api_base}Client/deleteClientData/${id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    return error.response;
  }
}
export const Client_edit = async (id, data) => {

  const updatedPOCDetData = data.ClientPOCDetData ? data.ClientPOCDetData.map(contact => {
    return {
      ...contact,
      EmailID: encryption(contact.EmailID) // Decrypting email ID if necessary
    };
  }) : [];

  try {

    const response = await axios.patch(api_base + `Client/UpdateClientData/${id}`, {

      OrgName: data.OrgName,
      RegisteredEmailID: encryption(data.RegisteredEmailID),
      LocationName: data.LocationName,
      AddLine2: data.AddLine2,
      CityID: data.CityID,
      LegalFilingStatus: data.LegalFilingStatus,
      RegisterdMobileNo: data.RegisterdMobileNo,
      AddLine1: data.AddLine1,
      StateID: data.StateID,
      Pincode: data.Pincode,

      PAN: data.PAN,
      IsGSTApplicable: data.IsGSTApplicable,
      GSTIN: data.GSTIN,
      IsPFApplicable: data.IsPFApplicable,
      PFNumber: data.PFNumber,
      PFPassword: data.PFPassword,
      PFMobileNo: data.PFMobileNo,
      PFUsername: data.PFUsername,
      PFEmailID: encryption(data.PFEmailID),

      IsESICApplicable: data.IsESICApplicable,
      ESICNumber: data.ESICNumber,
      ESICPassword: data.ESICPassword,
      ESICMobileNo: data.ESICMobileNo,
      ESICUsername: data.ESICUsername,
      ESICEmailID: encryption(data.ESICEmailID),

      IsPTApplicable: data.IsPTApplicable,
      PTNumber: data.PTNumber,
      PTEmployerNo: data.PTEmployerNo,
      PTEmployeeNo: data.PTEmployeeNo,

      IsFactoryLicenseApplicable: data.IsFactoryLicenseApplicable,
      FactoryLicenseNo: data.FactoryLicenseNo,
      EndDate: data.EndDate,
      StartDate: data.StartDate,

      ClientPOCDetData: updatedPOCDetData,
    },
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const Client_get_by_id = async (id) => {
  try {
    const response = await axios.get(api_base + `Client/GetClientDataByID/${id}`, {

      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const State_get = async () => {
  try {
    const response = await axios.get(`${api_base}Client/GetStateByCountryID/1`, {

      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}
export const City_get = async (StateID) => {
  try {
    const response = await axios.get(`${api_base}Client/GetCityByStateID/${StateID}`, {

      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

export const Add_Client_Location = async (id, data) => {
  try {

    const response = await axios.patch(api_base + `Client/AddClientLocationData/${id}`, {

      LocationName: data.LocationName,
      AddLine2: data.AddLine2,
      CityID: data.CityID,
      AddLine1: data.AddLine1,
      StateID: data.StateID,
      Pincode: data.Pincode,

      PAN: data.PAN,
      IsGSTApplicable: data.IsGSTApplicable,
      GSTIN: data.GSTIN,
      IsPFApplicable: data.IsPFApplicable,
      PFNumber: data.PFNumber,
      PFPassword: data.PFPassword,
      PFMobileNo: data.PFMobileNo,
      PFUsername: data.PFUsername,
      PFEmailID: encryption(data.PFEmailID),

      IsESICApplicable: data.IsESICApplicable,
      ESICNumber: data.ESICNumber,
      ESICPassword: data.ESICPassword,
      ESICMobileNo: data.ESICMobileNo,
      ESICUsername: data.ESICUsername,
      ESICEmailID: encryption(data.ESICEmailID),

      IsPTApplicable: data.IsPTApplicable,
      PTNumber: data.PTNumber,
      PTEmployerNo: data.PTEmployerNo,
      PTEmployeeNo: data.PTEmployeeNo,

      IsFactoryLicenseApplicable: data.IsFactoryLicenseApplicable,
      FactoryLicenseNo: data.FactoryLicenseNo,
      EndDate: data.EndDate,
      StartDate: data.StartDate,
      IsPFDuplicate: data.IsPFDuplicate,
      IsESICDuplicate: data.IsESICDuplicate,
      IsPTDuplicate: data.IsPTDuplicate,
      IsFactoryDuplicate: data.IsFactoryDuplicate,

    },
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const Client_Location_get = async (id, data) => {
  try {
    const response = await axios.get(`${api_base}Client/GetLocationByClientID/${id}`, {

      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const ClientLocation_get_by_id = async (id) => {
  try {
    const response = await axios.get(api_base + `Client/GetLocationByLocationID/${id}`, {

      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const ClientLocation_edit = async (ClientID, id, data) => {
  try {

    const response = await axios.patch(api_base + `Client/UpdateClientLocByLocationID/${ClientID}/${id}`, {

      LocationName: data.LocationName,
      AddLine2: data.AddLine2,
      CityID: data.CityID,
      AddLine1: data.AddLine1,
      StateID: data.StateID,
      Pincode: data.Pincode,

      PAN: data.PAN,
      IsGSTApplicable: data.IsGSTApplicable,
      GSTIN: data.GSTIN,
      IsPFApplicable: data.IsPFApplicable,
      PFNumber: data.PFNumber,
      PFPassword: data.PFPassword,
      PFMobileNo: data.PFMobileNo,
      PFUsername: data.PFUsername,
      PFEmailID: encryption(data.PFEmailID),

      IsESICApplicable: data.IsESICApplicable,
      ESICNumber: data.ESICNumber,
      ESICPassword: data.ESICPassword,
      ESICMobileNo: data.ESICMobileNo,
      ESICUsername: data.ESICUsername,
      ESICEmailID: encryption(data.ESICEmailID),

      IsPTApplicable: data.IsPTApplicable,
      PTNumber: data.PTNumber,
      PTEmployerNo: data.PTEmployerNo,
      PTEmployeeNo: data.PTEmployeeNo,

      IsFactoryLicenseApplicable: data.IsFactoryLicenseApplicable,
      FactoryLicenseNo: data.FactoryLicenseNo,
      EndDate: data.EndDate,
      StartDate: data.StartDate,
      IsPFDuplicate: data.IsPFDuplicate,
      IsESICDuplicate: data.IsESICDuplicate,
      IsPTDuplicate: data.IsPTDuplicate,
      IsFactoryDuplicate: data.IsFactoryDuplicate,

    },
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const Delete_ClientLocation_by_id = async (id) => {
  try {
    const response = await axios.delete(`${api_base}Client/DeleteClientLocationData/${id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    return error.response;
  }
}
export const CheckBoxDuplicateRecord = async (data) => {
  try {
    const response = await axios.post(`${api_base}Generic/DuplicateDataCheck/`, {
      table: data.table,
      name: data.name,
      id: data.id
    }, {
      // 
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    return error.response;
  }
}

export const UploadFile_api = async (FileName) => {
  try {
    const response = await axios.post(`${api_base}Upload/FileUpload/`, {
      FileName: FileName,
    }, {

      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
        'Content-Type': 'multipart/form-data'
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    return error.response;
  }
}
export const CheckMailDuplicateRecord = async (data) => {
  try {
    const response = await axios.post(`${api_base}GenericMail/DuplicateMailCheck`, {
      useremail: data.useremail,
      id: data.id,

    }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response.data;
  } catch (error) {
    return error.response;
  }
}

// Salary Components API

export const SalaryComponent_entry = async (data) => {
  try {
    const response = await axios.post(api_base + "SalaryComponents/", {
      ComponentName: data.ComponentName,
      ComponentShortName: data.ComponentShortName,

    },
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const SalaryComponent_entry_get = async (data) => {
  try {
    const response = await axios.get(`${api_base}SalaryComponents/GetSalaryComponentsColumnChoose`, {
      params: {
        filter: data.filter,
        page: data.page,
        limit: data.limit,
      },
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    return error.response;
  }
}
export const SalaryComponentConfigurationSave = async (data) => {
  try {
    const response = await axios.put(`${api_base}SalaryComponents/UpdateSalaryComponentsConfiguration/`, data, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    return error.response;
  }
}
export const SalaryComponent_get_by_id = async (id) => {
  try {
    const response = await axios.get(`${api_base}SalaryComponents/GetSalaryComponentsByID/${id}`, {

      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    return error.response;
    // // Handle the error more gracefully, e.g., log it or throw a custom error
    // console.error('Error fetching designation data:', error);
    // throw error;
  }
}
export const SalaryComponent_edit = async (id, data) => {
  try {
    const response = await axios.patch(api_base + `SalaryComponents/UpdateSalaryComponentsData/${id}`, {
      ComponentName: data.ComponentName,
      ComponentShortName: data.ComponentShortName,
    },
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      });
    return response;
  } catch (error) {
    return error.response;
  }
};
export const DeleteSalaryComponent_by_id = async (id) => {
  try {
    const response = await axios.delete(`${api_base}SalaryComponents/deleteSalaryComponentsData/${id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    return error.response;
  }
}

// Professional Tax

export const ProfessionalTax_entry = async (data) => {
  try {
    const response = await axios.post(api_base + "ProfessionalTax/", {
      DeductionName: data.DeductionName,
      DeductionShortName: data.DeductionShortName,
      DeductionDisplayName: data.DeductionDisplayName,
      StateID: data.StateID,
      StartDate: data.StartDate,
      EndDate: data.EndDate,
      Amount: data.Amount,
      IsPercentage: data.IsPercentage,
      StartRange: data.StartRange,
      EndRange: data.EndRange,
      Month: data.Month,

    },
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const ProfessionalTax_entry_get = async (data) => {
  try {
    const response = await axios.get(`${api_base}ProfessionalTax/GetProfessionalTaxColumnChoose`, {
      params: {
        filter: data.filter,
        page: data.page,
        limit: data.limit,
      },
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    return error.response;
  }
}
export const ProfessionalTaxConfigurationSave = async (data) => {
  try {
    const response = await axios.put(`${api_base}/ProfessionalTax/UpdateProfessionalTaxConfiguration/`, data, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    return error.response;
  }
}
export const ProfessionalTax_get_by_id = async (id) => {
  try {
    const response = await axios.get(`${api_base}ProfessionalTax/GetProfessionalTaxData/${id}`, {

      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    return error.response;
  }
}

export const ProfessionalTax_Update_by_id = async (id, data) => {
  try {
    const response = await axios.patch(api_base + `ProfessionalTax/UpdateProfessionalTaxData/${id}`, {
      DeductionName: data.DeductionName,
      DeductionShortName: data.DeductionShortName,
      DeductionDisplayName: data.DeductionDisplayName,
      StateID: data.StateID,
      StartDate: data.StartDate,
      EndDate: data.EndDate,
      Amount: data.Amount,
      IsPercentage: data.IsPercentage,
      StartRange: data.StartRange,
      EndRange: data.EndRange,
      Month: data.Month,
    },
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const ProfessionalTaxCheckRange = async (data) => {
  try {
    const response = await axios.post(`${api_base}ProfessionalTax/ProfessionalTaxCheckRange/`, {
      StartDate: data.StartDate,
      EndDate: data.EndDate,
      StateID: data.StateID
    }, {

      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    return error.response;
  }
}