// professionalTax_entry.js
import React, { useState, useEffect, useRef } from "react";
import Dashboard from "../components/dashboard.js";
import { AiFillCloseCircle } from "react-icons/ai";
import Input2 from "../components/parts/input2.js";
import {
  ProfessionalTaxCheckRange,
  ProfessionalTax_Update_by_id,
  ProfessionalTax_entry,
  ProfessionalTax_get_by_id,
  SalaryComponent_edit,
  SalaryComponent_entry,
  SalaryComponent_get_by_id,
  State_get,
  User_PageAccess,
} from "../service/api.js";
import { Helmet } from "react-helmet";
import { useParams, Link, useNavigate } from "react-router-dom";
import ErrorSnackbar from "./../components/ErrorSnackbar.js";
import SuccessSnackbar from "./../components/SuccessSnackbar.js";
import NoPermission from "../components/NoPermission.js";
import LoaderComponent from "../components/LoaderComponent.js";
import LoaderComponent2 from "../components/LoaderComponent2.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Autocomplete, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";

const ProfessionalTax = () => {
  const [LoaderShow, setLoaderShow] = useState(true);
  const [LoaderShow2, setLoaderShow2] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [ExceptionError, setExceptionError] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);
  const [buttonClicked, setButtonClicked] = useState("");
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [DesignationAccess, setDesignationAccess] = useState(false);

  const [stateOption, setstateoption] = useState([]);
  const [isChecked, setIsChecked] = useState(true);

  const [data, setData] = useState({
    DeductionName: "",
    DeductionShortName: "",
    DeductionDisplayName: "",
    StateID: "",
    StartDate: "",
    EndDate: "",
    Amount: "",
    IsPercentage: true,
    StartRange: "",
    EndRange: "",
    Month: [
      { Month: "January", Employee: "", Employer: "" },
      { Month: "February", Employee: "", Employer: "" },
      { Month: "March ", Employee: "", Employer: "" },
      { Month: "April", Employee: "", Employer: "" },
      { Month: "May", Employee: "", Employer: "" },
      { Month: "June", Employee: "", Employer: "" },
      { Month: "July", Employee: "", Employer: "" },
      { Month: "August", Employee: "", Employer: "" },
      { Month: "September", Employee: "", Employer: "" },
      { Month: "October", Employee: "", Employer: "" },
      { Month: "November", Employee: "", Employer: "" },
      { Month: "December", Employee: "", Employer: "" },
    ],
  });

  const [validationErrors, setValidationErrors] = useState({
    DeductionName: "",
    DeductionShortName: "",
    DeductionDisplayName: "",
    StateID: "",
    StartDate: "",
    EndDate: "",
    Amount: "",
    StartRange: "",
    EndRange: "",
  });

  const handleAmountChange = (value) => {
    value = Math.max(value, 0).toString().slice(0, 18);
    const isValidAmount = /^\d{0,18}(\.\d{1,2})?$/.test(value);
    const amountValidation = /^\d{1,18}(?:\.\d{0,2})?$/;

    const formattedValue = Math.min(Math.max(parseFloat(value), 0), 100);

    setData((prevData) => ({
      ...prevData,
      Amount: data.IsPercentage ? formattedValue : value,
    }));
    setValidationErrors((prevErrors) => ({ ...prevErrors, Amount: "" }));

    setData((prevData) => ({
      ...prevData,
      Month: prevData.Month.map((month) => ({
        ...month,
        Employee: data.IsPercentage ? formattedValue : value,
        Employer: data.IsPercentage ? formattedValue : value,
      })),
    }));
  };

  const handleClientPOCDetDataChange = (index, field, value) => {
    value = Math.max(value, 0).toString().slice(0, 18);

    setData((prevData) => ({
      ...prevData,
      Month: prevData.Month.map((month, i) => {
        if (i === index) {
          return {
            ...month,
            [field]: value,
          };
        }
        return month;
      }),
    }));
  };

  const isEffectExecutedRef = useRef(false);
  useEffect(() => {
    if (!isEffectExecutedRef.current) {
      PageAccess();
      isEffectExecutedRef.current = true;
    }
  }, []);

  const PageAccess = async () => {
    try {
      const res = await User_PageAccess("8");
      if (res.status == 200) {
        fetchStates();
        if (id ? res.data.AllowUpdate === true : res.data.AllowAdd === true) {
          setDesignationAccess(true);
          if (id) {
            api_get();
          }
          setLoaderShow(false);
        }
        setLoaderShow(false);
      } else if (res.status === 400) {
        if (res.data.ErrorCode) {
          const validationErrorMessage = res.data.ErrorMessage;
          const errorMessagesArray = validationErrorMessage.split(", ");

          errorMessagesArray.forEach((errorMessage) => {
            const [, fieldName, errorMessageText] =
              errorMessage.match(/\"(.*?)\" (.*)/);
            handleExceptionError(`${fieldName} - ${errorMessageText}`);
            setLoaderShow(false);
          });
        }
        if (res.data.Message) {
          handleExceptionError(res.data.Message);
          setLoaderShow(false);
        }
      } else if (res.status === 401) {
        handleExceptionError("Unauthorized");
        setTimeout(() => {
          localStorage.clear();
          navigate("/");
        }, 1000);
        ///logout();
      } else if (res.status === 200) {
        var dataError = res.data.Errors;
        dataError.map((message, index) => {
          handleExceptionError(message.Message);
          setLoaderShow(false);
        });
      } else if (res.status === 500) {
        handleExceptionError(res.statusText);
        setLoaderShow(false);
      }
    } catch (error) {
      handleExceptionError(error.message);
      setLoaderShow(false);
    } finally {
      setLoaderShow(false);
    }
  };

  const api_get = async () => {
    setLoaderShow(true);
    try {
      console.log(id);
      const resp = await ProfessionalTax_get_by_id(id);
      console.log(resp);
      if (resp.status === 200) {
        const dataString = resp.data.ProfessionalTaxData.Month;
        const dataArray = JSON.parse(`${dataString.replace(/\\/g, "")}`);
        setData({
          DeductionName: resp.data.ProfessionalTaxData.DeductionName,
          DeductionShortName: resp.data.ProfessionalTaxData.DeductionShortName,
          DeductionDisplayName:
            resp.data.ProfessionalTaxData.DeductionDisplayName,
          StateID: resp.data.ProfessionalTaxData.StateID,
          StartDate: resp.data.ProfessionalTaxData.StartDate,
          EndDate: resp.data.ProfessionalTaxData.EndDate,
          Amount: resp.data.ProfessionalTaxData.Amount,
          IsPercentage: resp.data.ProfessionalTaxData.IsPercentage,
          StartRange: resp.data.ProfessionalTaxData.StartRange,
          EndRange: resp.data.ProfessionalTaxData.EndRange,
          Month: dataArray,
        });
        setLoaderShow(false);
      } else if (resp.status === 400) {
        handleExceptionError(resp);
        setLoaderShow(false);
      } else if (resp.status === 401) {
        handleExceptionError("Unauthorized");
        setTimeout(() => {
          localStorage.clear();
          navigate("/");
        }, 1000);
        ///logout();
      } else if (resp.status === 500) {
        handleExceptionError(resp.statusText);
        setLoaderShow(false);
      }
    } catch (error) {
      handleExceptionError(error.message);
    }
  };

  function handleExceptionError(res) {
    setExceptionError((ExceptionError) => [
      ...ExceptionError,
      { id: Date.now(), message: res },
    ]);
  }
  function handleExceptionSuccessMessages(resp) {
    setSuccessMessages((successMessages) => [
      ...successMessages,
      { id: Date.now(), message: resp },
    ]);
  }
  function clearErrors(id) {
    setExceptionError((prevMessages) =>
      prevMessages.filter((msg) => msg.id !== id)
    );
  }
  function clearSuccess(id) {
    setSuccessMessages((prevMessages) =>
      prevMessages.filter((msg) => msg.id !== id)
    );
  }

  const handleDeductionNameChange = async (value) => {
    if (value.trim().length > 50) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        DeductionName: "Deduction Name length should be 50 digits.",
      }));
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        DeductionName: "",
      }));
    }
    setData((prevData) => ({ ...prevData, DeductionName: value.slice(0, 50) }));

    // if (value === '') {
    //     return;
    // }
    // var ob = {
    //     "table": "Designation",
    //     "name": value.trim(),
    // }
    // const resp = await CheckBoxDuplicateRecord(ob);
    // if (resp.status === 200) {
    //     setDisabledBtn(false)
    //     // setValidationErrors((prevErrors) => ({ ...prevErrors, designation: '' }));
    //     if (value.trim().length > 100) {
    //         setValidationErrors((prevErrors) => ({ ...prevErrors, designation: 'Designation length should be 100 digits.' }));
    //     } else {
    //         setValidationErrors((prevErrors) => ({ ...prevErrors, designation: '' }));
    //     }
    // } else if (resp.status === 400) {
    //     setDisabledBtn(true)
    //     setValidationErrors((prevErrors) => ({ ...prevErrors, designation: resp.data.Errors[0].Message }));
    // } else if (resp.status === 401) {

    //     handleExceptionError('Unauthorized');
    //     setTimeout(() => {
    //         localStorage.clear();
    //         navigate('/')
    //     }, 1000);
    //     ///logout();
    // }
    // else if (resp.status === 500) {
    //     setDisabledBtn(true)
    //     handleExceptionError(resp.statusText);
    // }
  };

  const handleDeductionShortNameChange = (value) => {
    // setData((prevData) => ({ ...prevData, shortName: value.slice(0, 10) }));
    // setValidationErrors((prevErrors) => ({ ...prevErrors, shortName: '' }));

    if (value.trim().length > 10) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        DeductionShortName: "Short Name length should be 10 digits.",
      }));
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        DeductionShortName: "",
      }));
    }
    setData((prevData) => ({
      ...prevData,
      DeductionShortName: value.slice(0, 10),
    }));
  };

  const handleIsPercentageChange = (value) => {
    setIsChecked(!isChecked);
    setData((prevData) => ({ ...prevData, IsPercentage: value }));
  };

  const handleStartRangeChange = (value) => {
    value = parseFloat(value);
    value = Math.max(value, 0);

    if (data.EndRange && value > data.EndRange) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        StartRange: "Start range should be less than end range",
      }));
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        StartRange: "",
        // EndRange: "",
      }));
    }
    if (data.EndRange && value <= data.EndRange) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        EndRange: "",
      }));
    }

    const newValue = String(value).slice(0, 18);
    setData((prevData) => ({
      ...prevData,
      StartRange: parseFloat(newValue),
    }));
  };

  const handleEndRangeChange = (value) => {
    value = parseFloat(value);
    value = Math.max(value, 0);

    const startRange = parseFloat(data.StartRange);
    const endRange = parseFloat(data.EndRange);

    if (value < startRange) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        EndRange: "End range should be greater than start range",
      }));
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        EndRange: "",
        // StartRange: "",
      }));
    }
    if (data.StartRange && value >= data.StartRange) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        StartRange: "",
      }));
    }

    const newValue = String(value).slice(0, 18);
    setData((prevData) => ({
      ...prevData,
      EndRange: parseFloat(newValue),
    }));
  };

  const handleStartDate = async (date) => {
    if (!date) return;
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setData((prevData) => ({
      ...prevData,
      StartDate: formattedDate,
    }));

    var ob = {
      StartDate: formattedDate,
      EndDate: data.EndDate,
      StateID: data.StateID,
    };
    try {
      const resp = await ProfessionalTaxCheckRange(ob);
      if (resp.status === 200) {
        setDisabledBtn(false);
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          StartDate: "",
        }));
      } else if (resp.status === 400) {
        setDisabledBtn(true);
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          StartDate: resp.data.Message,
        }));
      } else if (resp.status === 401) {
        handleExceptionError("Unauthorized");
        setTimeout(() => {
          localStorage.clear();
          navigate("/");
        }, 1000);
      } else if (resp.status === 500) {
        setDisabledBtn(false);
        handleExceptionError(resp.statusText);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const handleEndDate = async (date) => {
    if (!date) return;
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setData((prevData) => ({
      ...prevData,
      EndDate: formattedDate,
    }));

    var ob = {
      StartDate: data.StartDate,
      EndDate: formattedDate,
      StateID: data.StateID,
    };
    try {
      const resp = await ProfessionalTaxCheckRange(ob);
      if (resp.status === 200) {
        setDisabledBtn(false);
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          EndDate: "",
        }));
      } else if (resp.status === 400) {
        setDisabledBtn(true);
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          EndDate: resp.data.Message,
        }));
      } else if (resp.status === 401) {
        handleExceptionError("Unauthorized");
        setTimeout(() => {
          localStorage.clear();
          navigate("/");
        }, 1000);
      } else if (resp.status === 500) {
        setDisabledBtn(false);
        handleExceptionError(resp.statusText);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = {};
    if (data.DeductionName.trim() === "") {
      errors.DeductionName = "Deduction Name is required.";
    }
    if (data.DeductionShortName.trim() === "") {
      errors.DeductionShortName = "Short Name is required.";
    }
    if (data.StateID === 0 || data.StateID === "") {
      errors.StateID = "State is required.";
    }
    if (data.StartDate.trim() === "") {
      errors.StartDate = "Start Date is required.";
    }
    if (String(data.StartRange).trim() === "") {
      errors.StartRange = "Start Range is required.";
    }
    if (String(data.EndRange).trim() === "") {
      errors.EndRange = "End Range is required.";
    }
    if (String(data.Amount).trim() === "") {
      errors.Amount = "Amount is required.";
    }
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    setValidationErrors({});
    if (id) {
      try {
        setLoaderShow2(true);
        const res = await ProfessionalTax_Update_by_id(id, data);
        if (res.status == 200) {
          handleExceptionSuccessMessages(res.data);
          setLoaderShow2(false);
          setTimeout(function () {
            navigate("/professionalTax-data");
          }, 2000);
        } else if (res.status === 200) {
          var dataError = res.data.Errors;
          dataError.map((message, index) => {
            handleExceptionError(message.Message);
          });
        } else if (res.status === 400) {
          if (res.data.ErrorCode) {
            const validationErrorMessage = res.data.ErrorMessage;
            const errorMessagesArray = validationErrorMessage.split(", ");
            errorMessagesArray.forEach((errorMessage) => {
              const [, fieldName, errorMessageText] =
                errorMessage.match(/\"(.*?)\" (.*)/);
              handleExceptionError(`${fieldName} - ${errorMessageText}`);
              setLoaderShow2(false);
            });
          }
          if (res.data.Message) {
            handleExceptionError(res.data.Message);
            setLoaderShow2(false);
          }
        } else if (res.status === 401) {
          handleExceptionError("Unauthorized");
          setLoaderShow2(false);
          setTimeout(() => {
            localStorage.clear();
            navigate("/");
          }, 1000);
          ///logout();
        } else if (res.status === 500) {
          handleExceptionError(res.statusText);
          setLoaderShow2(false);
        }
      } catch (error) {
        handleExceptionError(error.message);
      } finally {
        setLoaderShow2(false);
      }
    } else {
      try {
        setLoaderShow2(true);
        const res = await ProfessionalTax_entry(data);
        if (res.status == 200) {
          handleExceptionSuccessMessages(res.data);
          handleFormReset();
          setLoaderShow2(false);
          if (buttonClicked == "submit") {
            setTimeout(function () {
              navigate("/professionalTax-data");
            }, 2000);
          }
        } else if (res.status === 200) {
          var dataError = res.data.Errors;
          dataError.map((message, index) => {
            handleExceptionError(message.Message);
            setLoaderShow2(false);
          });
        } else if (res.status === 400) {
          if (res.data.ErrorCode) {
            const validationErrorMessage = res.data.ErrorMessage;
            const errorMessagesArray = validationErrorMessage.split(", ");
            errorMessagesArray.forEach((errorMessage) => {
              const [, fieldName, errorMessageText] =
                errorMessage.match(/\"(.*?)\" (.*)/);
              handleExceptionError(`${fieldName} - ${errorMessageText}`);
              setLoaderShow2(false);
            });
          }
          if (res.data.Message) {
            handleExceptionError(res.data.Message);
            setLoaderShow2(false);
          }
        } else if (res.status === 401) {
          handleExceptionError("Unauthorized");
          setTimeout(() => {
            localStorage.clear();
            navigate("/");
          }, 1000);
          ///logout();
        } else if (res.status === 500) {
          handleExceptionError(res.statusText);
          setLoaderShow2(false);
        }
      } catch (error) {
        handleExceptionError(error.message);
      } finally {
        setLoaderShow2(false);
      }
    }
  };

  const handleFormReset = () => {
    setData({
      DeductionName: "",
      DeductionShortName: "",
      DeductionDisplayName: "",
      StateID: "",
      StartDate: "",
      EndDate: "",
      Amount: "",
      IsPercentage: false,
      StartRange: "",
      EndRange: "",
      Month: [
        { Month: "January", Employee: "", Employer: "" },
        { Month: "February", Employee: "", Employer: "" },
        { Month: "March ", Employee: "", Employer: "" },
        { Month: "April", Employee: "", Employer: "" },
        { Month: "May", Employee: "", Employer: "" },
        { Month: "June", Employee: "", Employer: "" },
        { Month: "July", Employee: "", Employer: "" },
        { Month: "August", Employee: "", Employer: "" },
        { Month: "September", Employee: "", Employer: "" },
        { Month: "October", Employee: "", Employer: "" },
        { Month: "November", Employee: "", Employer: "" },
        { Month: "December", Employee: "", Employer: "" },
      ],
    });
    setValidationErrors({
      DeductionName: "",
      DeductionShortName: "",
      StateID: "",
      StartDate: "",
      EndDate: "",
      Amount: "",
      StartRange: "",
      EndRange: "",
    });
  };

  const fetchStates = async () => {
    try {
      const res = await State_get();
      if (res.status === 200) {
        const data = res.data.data;
        if (Array.isArray(data)) {
          const newOptions1 = data
            .filter((item) => item.IsActive === true)
            .map((item) => ({
              id: item.StateID,
              name: item.StateName,
              countryId: item.CountryID,
            }));
          setstateoption(newOptions1);
        } else {
          console.error("State data is not an array:", data);
        }
      } else if (res.status === 401) {
        handleExceptionError("Unauthorized");
        setTimeout(() => {
          localStorage.clear();
          navigate("/");
        }, 1000);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const handleStateIDChange = async (stateId, value) => {
    console.log(stateId);
    setData((prevData) => ({
      ...prevData,
      StateID: value ? value.id : "",
      StartDate: "",
      EndDate: "",
    }));

    setValidationErrors((prevErrors) => ({ ...prevErrors, StateID: "" }));
    if (data.StartDate) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, StartDate: "" }));
    }
    if (data.EndDate) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, EndDate: "" }));
    }
  };

  return (
    <Dashboard
      title={
        id === undefined ? "New Professional Tax" : "Edit Professional Tax"
      }
    >
      <Helmet>
        <title>Add Professional Tax | J mehta</title>
      </Helmet>
      <ErrorSnackbar
        errorMessages={ExceptionError}
        onClearErrors={clearErrors}
      />
      <SuccessSnackbar
        successMessages={successMessages}
        onclearSuccess={clearSuccess}
      />
      {LoaderShow ? (
        <LoaderComponent />
      ) : (
        <>
          {DesignationAccess ? (
            <div>
              <div className="new_client_title">
                <Link to="/professionalTax-data">
                  <button>
                    <AiFillCloseCircle /> Close
                  </button>
                </Link>
              </div>
              <div className="new_client_content_wrapper">
                <div className="new_client_menu"></div>
                <form
                  onSubmit={handleSubmit}
                  onReset={handleFormReset}
                  className="salary-form"
                >
                  <div className="row new_client_form">
                    <div className="col new_client_form">
                      <div className="new_client_part_1 w-100">
                        <Input2
                          type={"text"}
                          placeholder="Enter Here"
                          label="Deduction Name"
                          required
                          value={data.DeductionName}
                          onChange={(e) => handleDeductionNameChange(e)}
                        />
                        {validationErrors.DeductionName && (
                          <div className="error">
                            {validationErrors.DeductionName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col ">
                      <div className="new_client_part_1 w-100">
                        <Input2
                          type={"text"}
                          placeholder="Enter Here"
                          label="Deduction Short Name"
                          required
                          value={data.DeductionShortName}
                          onChange={(e) => handleDeductionShortNameChange(e)}
                        />
                        {validationErrors.DeductionShortName && (
                          <div className="error">
                            {validationErrors.DeductionShortName}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row new_client_form">
                    <div className="col new_client_form">
                      <div className="new_client_part_1 w-100">
                        <div class="dashboard_input_feild">
                          <h3 className="mb-2">
                            State <span style={{ color: "red" }}>*</span>{" "}
                          </h3>
                          <Autocomplete
                            className="mt-0 border-0"
                            disabled={id}
                            value={
                              data.StateID
                                ? stateOption.find(
                                    (option) => option.id === data.StateID
                                  ) || null
                                : null
                            }
                            onChange={handleStateIDChange}
                            options={stateOption}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select State"
                                variant="outlined"
                                InputLabelProps={{ className: "custom-label" }}
                              />
                            )}
                          />
                          {validationErrors.StateID && (
                            <div className="error mt-1">
                              {validationErrors.StateID}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col ">
                      <div className="new_client_part_1 w-100">
                        <div className="dashboard_input_feild client_locationDate">
                          <div>
                            <h3 htmlFor="startDate">
                              Start Date{" "}
                              {<span style={{ color: "red" }}>*</span>}{" "}
                            </h3>
                          </div>
                          <DatePicker
                            id="startDate"
                            placeholderText="Enter Here"
                            selected={data.StartDate}
                            onChange={handleStartDate}
                            dateFormat="dd/MM/yyyy"
                            maxDate={data.EndDate}
                            minDate={id ? data.StartDate : ""}
                            // disabled={id ? data.StartDate : ''}
                          />
                        </div>
                        {validationErrors.StartDate && (
                          <div className="error">
                            {validationErrors.StartDate}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row new_client_form ">
                    <div className="col new_client_form">
                      <div className="new_client_part_1 w-100">
                        <div className="dashboard_input_feild client_locationDate">
                          <div>
                            <h3 htmlFor="endDate">End Date</h3>
                          </div>
                          <DatePicker
                            id="endDate"
                            placeholderText="Enter Here"
                            selected={data.EndDate}
                            onChange={handleEndDate}
                            minDate={data.StartDate}
                            dateFormat="dd/MM/yyyy"
                          />
                        </div>
                        {validationErrors.EndDate && (
                          <div className="error">
                            {validationErrors.EndDate}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col ">
                      <div className="new_client_part_1 w-100">
                        <Input2
                          type={"number"}
                          placeholder="Enter Here"
                          label="Start Range"
                          required
                          value={data.StartRange}
                          onChange={(e) => handleStartRangeChange(e)}
                        />
                        {validationErrors.StartRange && (
                          <div className="error">
                            {validationErrors.StartRange}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row new_client_form align-items-center">
                    <div className="col new_client_form">
                      <div className="new_client_part_1 w-100">
                        <Input2
                          type={"number"}
                          placeholder="Enter Here"
                          label="End Range"
                          required
                          value={data.EndRange}
                          onChange={(e) => handleEndRangeChange(e)}
                        />
                        {validationErrors.EndRange && (
                          <div className="error">
                            {validationErrors.EndRange}
                          </div>
                        )}
                      </div>
                      {/* <div className='dashboard_input_feild client_locationDate'>
                                                    <div><h3>Range</h3></div>
                                                    <Box sx={{ width: 300 }}>
                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            {stepValues.map((stepValue) => (
                                                                <Typography key={stepValue} variant="body2">
                                                                    {stepValue}
                                                                </Typography>
                                                            ))}
                                                        </Box>
                                                        <Slider
                                                            getAriaLabel={() => 'Temperature range'}
                                                            value={value}
                                                            onChange={handleChange}
                                                            valueLabelDisplay="auto"
                                                            min={0}
                                                            max={200}
                                                        // step={50}
                                                        />
                                                    </Box>
                                                </div> */}
                    </div>
                    <div className="col ">
                      <div className="new_client_part_1 w-100 d-flex">
                        <label className="label_main px-4 mt-1">
                          IsPercentage
                          <input
                            type="checkbox"
                            checked={data.IsPercentage}
                            onChange={(e) =>
                              handleIsPercentageChange(e.target.checked)
                            }
                          />
                          <span class="geekmark"> </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row new_client_form ">
                    <div className="col new_client_form">
                      <div className="new_client_part_1 w-100">
                        <Input2
                          type={"number"}
                          placeholder="Enter Here"
                          label="Amount"
                          required
                          value={data.Amount}
                          onChange={(e) => handleAmountChange(e)}
                        />
                        {validationErrors.Amount && (
                          <div className="error">{validationErrors.Amount}</div>
                        )}
                      </div>
                    </div>
                    <div className="col "></div>
                  </div>

                  <div className="overflow-hidden">
                    <div className="client_panel_list d-flex align-items-start scroll-container">
                      <table
                        className="client_panel_list_table mb-4 pt-table"
                        cellPadding="0"
                        cellSpacing="0"
                      >
                        <tr>
                          <th>Month</th>
                          <th>Employee</th>
                          <th>Employer</th>
                        </tr>
                        {data.Month &&
                          data.Month.map((row, index) => (
                            <>
                              <tr key={index}>
                                <td>{row.Month}</td>
                                <td>
                                  <input
                                    type="number"
                                    className="tbl_enter"
                                    value={row.Employee}
                                    onChange={(e) =>
                                      handleClientPOCDetDataChange(
                                        index,
                                        "Employee",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="tbl_enter"
                                    value={row.Employer}
                                    onChange={(e) =>
                                      handleClientPOCDetDataChange(
                                        index,
                                        "Employer",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                              </tr>
                            </>
                          ))}
                      </table>
                    </div>
                  </div>

                  <div className="btn_save d-flex justify-content-end">
                    <button type="reset" className="tab1 save_button">
                      <img src="../img/clockwise.svg" />
                      Reset
                    </button>
                    <button
                      type="submit"
                      disabled={disabledBtn}
                      name="save"
                      onClick={() => setButtonClicked("submit")}
                      className="tab1 save_button me-4"
                    >
                      <>
                        <img src="../img/Save.svg" />
                        {id === undefined ? "Save" : "Update"}
                        {LoaderShow2 && buttonClicked === "submit" && (
                          <LoaderComponent2 />
                        )}
                      </>
                    </button>
                    {id === undefined && (
                      <button
                        type="submit"
                        disabled={disabledBtn}
                        name="saveAndMore"
                        onClick={() => setButtonClicked("saveAndMore")}
                        className="tab1 save_button me-0"
                      >
                        <>
                          <img src="../img/Save.svg" />
                          Save & More
                          {LoaderShow2 && buttonClicked === "saveAndMore" && (
                            <LoaderComponent2 />
                          )}
                        </>
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <NoPermission />
          )}
        </>
      )}
    </Dashboard>
  );
};

export default ProfessionalTax;
