// App.js
import React, { useState, useEffect, useRef } from 'react';
import Dashboard from '../components/dashboard.js';
import { AiFillCloseCircle } from 'react-icons/ai';
import Input2 from '../components/parts/input2.js';
import Textarea from '../components/parts/textarea.js';
import Checkbox from '../components/parts/checkbox.js';
import { Department_entry, User_PageAccess } from '../service/api.js';
import { Department_entry_get_by_id, CheckBoxDuplicateRecord } from '../service/api.js';
import { Department_edit } from '../service/api.js';
import { Helmet } from 'react-helmet';
import { useParams, Link, useNavigate } from 'react-router-dom';
import ErrorSnackbar from './../components/ErrorSnackbar.js';
import SuccessSnackbar from './../components/SuccessSnackbar.js';
import NoPermission from '../components/NoPermission.js';
import LoaderComponent from "../components/LoaderComponent.js";
import LoaderComponent2 from "../components/LoaderComponent2.js";
import { debounce } from 'lodash';

const Department = () => {
  const [LoaderShow, setLoaderShow] = useState(true)
  const [LoaderShow2, setLoaderShow2] = useState(false)
  const { id } = useParams();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(true);
  const [buttonClicked, setButtonClicked] = useState('');
  const [ExceptionError, setExceptionError] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [DepartmentAccess, setDepartmentAccess] = useState(false)

  const [data, setData] = useState({
    department: '',
    shortName: '',
    description: '',
    active: true,
  });
  const [validationErrors, setValidationErrors] = useState({
    department: '',
    shortName: '',
    description: '',
  });

  const isEffectExecutedRef = useRef(false)
  useEffect(() => {
    if (!isEffectExecutedRef.current) {
      PageAccess()
      isEffectExecutedRef.current = true
    }
  }, []);

  const PageAccess = async () => {
    try {
      const res = await User_PageAccess("2");
      if (res.status == 200) {
        if (id ? res.data.AllowUpdate === true : res.data.AllowAdd === true) {
          setDepartmentAccess(true)
          if (id) {
            api_get();
          }
          setLoaderShow(false)
        }
        setLoaderShow(false)
      } else if (res.status === 400) {
        if (res.data.ErrorCode) {
          const validationErrorMessage = res.data.ErrorMessage;
          const errorMessagesArray = validationErrorMessage.split(', ');

          errorMessagesArray.forEach(errorMessage => {
            const [, fieldName, errorMessageText] = errorMessage.match(/\"(.*?)\" (.*)/);

            handleExceptionError(`${fieldName} - ${errorMessageText}`);
            setLoaderShow(false)
          });
        }
        if (res.data.Message) {
          handleExceptionError(res.data.Message);
          setLoaderShow(false)
        }
      } else if (res.status === 401) {

        handleExceptionError('Unauthorized');
        setTimeout(() => {
          localStorage.clear();
          navigate('/')
        }, 1000);
        ///logout();
      }
      else if (res.status === 200) {
        var dataError = res.data.Errors;
        dataError.map((message, index) => {
          handleExceptionError(message.Message);
          setLoaderShow(false)
        })
      }
      else if (res.status === 500) {
        handleExceptionError(res.statusText);
      }
    } catch (error) {
      handleExceptionError(error.message);
      setLoaderShow(false)
    }
    finally {
      setLoaderShow(false)
    }
  }

  const api_get = async () => {
    setLoaderShow(true)
    try {
      const resp = await Department_entry_get_by_id(id);
      console.log(id)
      if (resp.status === 200) {
        setData({
          department: resp.data.data.DepartmentName === null ? '' : resp.data.data.DepartmentName,
          shortName: resp.data.data.DepartmentSName === null ? '' : resp.data.data.DepartmentSName,
          description: resp.data.data.Description === null ? '' : resp.data.data.Description,
          active: resp.data.data.IsActive
        });
        setLoaderShow(false)
      } else if (resp.status === 400) {
        handleExceptionError(resp);
        setLoaderShow(false)
      } else if (resp.status === 401) {
        handleExceptionError('Unauthorized');
        setTimeout(() => {
          localStorage.clear();
          navigate('/')
        }, 1000);
        ///logout();
      }
      else if (resp.status === 500) {
        handleExceptionError(resp.statusText);
        setLoaderShow(false)
      }
    } catch (error) {
      handleExceptionError(error.message);
      setLoaderShow(false)
    } finally {
      setLoaderShow(false)
    }
  }

  function handleExceptionError(res) {
    setExceptionError(ExceptionError => [
      ...ExceptionError,
      { id: Date.now(), message: res },
    ]);
  }
  function handleExceptionSuccessMessages(resp) {
    setSuccessMessages(successMessages => [
      ...successMessages,
      { id: Date.now(), message: resp },
    ]);
  }
  function clearErrors(id) {
    setExceptionError(prevMessages =>
      prevMessages.filter(msg => msg.id !== id)
    );
  }
  function clearSuccess(id) {
    setSuccessMessages(prevMessages =>
      prevMessages.filter(msg => msg.id !== id)
    );
  }
  // const [DepamentSearch, setDepamentSearch] = useState({});

  // const handleDepartmentChange = async (value) => {
  //   console.log(value);
  //   setDepamentSearch(value);
  //   setData((prevData) => ({ ...prevData, department: value.slice(0, 100) }));
  // };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const ob = {
  //         table: "Department",
  //         name: DepamentSearch,
  //       };

  //       const resp = await CheckBoxDuplicateRecord(ob);
  //       console.log(resp);

  //       if (resp.status === 200) {
  //         setDisabledBtn(false);
  //         if (ob.name.length > 100) {
  //           setValidationErrors((prevErrors) => ({ ...prevErrors, department: "Department length should be 100 digits." }));
  //         } else {
  //           setValidationErrors((prevErrors) => ({ ...prevErrors, department: "" }));
  //         }
  //       } else if (resp.status === 400) {
  //         setDisabledBtn(true);
  //         setValidationErrors((prevErrors) => ({ ...prevErrors, department: resp.data.Errors[0].Message }));
  //       } else if (resp.status === 401) {
  //         handleExceptionError("Unauthorized");
  //         setTimeout(() => {
  //           localStorage.clear();
  //           navigate("/");
  //         }, 1000);
  //       } else if (resp.status === 500) {
  //         setDisabledBtn(false);
  //         handleExceptionError(resp.statusText);
  //       }
  //     } catch (error) {
  //       handleExceptionError(error.message);
  //     }
  //   };

  //   const timeoutId = setTimeout(fetchData, 1500);

  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, [DepamentSearch]);



  const handleDepartmentChange = async (value) => {
    setData((prevData) => ({ ...prevData, department: value.slice(0, 100) }));
    if (value === '') {
      return;
    }
    var ob = {
      "table": "Department",
      "name": value.trim(),
      "id": id ? id : "0",
    }

    const resp = await CheckBoxDuplicateRecord(ob);
    console.log(resp)
    if (resp.status === 200) {
      setDisabledBtn(false)
      // setValidationErrors((prevErrors) => ({ ...prevErrors, department: '' }));
      if (value.trim().length > 100) {
        setValidationErrors((prevErrors) => ({ ...prevErrors, department: 'Department length should be 100 digits.' }));
      } else {
        setValidationErrors((prevErrors) => ({ ...prevErrors, department: '' }));
      }
    } else if (resp.status === 400) {
      setDisabledBtn(true)
      setValidationErrors((prevErrors) => ({ ...prevErrors, department: resp.data.Errors[0].Message }));
    } else if (resp.status === 401) {

      handleExceptionError('Unauthorized');
      setTimeout(() => {
        localStorage.clear();
        navigate('/')
      }, 1000);
      ///logout();
    }
    else if (resp.status === 500) {
      setDisabledBtn(false)
      handleExceptionError(resp.statusText);
    }
  };

  const handleShortNameChange = (value) => {
    // setData((prevData) => ({ ...prevData, shortName: value.slice(0, 10) }));
    // setValidationErrors((prevErrors) => ({ ...prevErrors, shortName: '' }));

    if (value.trim().length > 10) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, shortName: 'Short Name length should be 10 digits.' }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, shortName: '' }));
    }

    setData((prevData) => ({ ...prevData, shortName: value.slice(0, 10) }));
  };

  const handleDescriptionChange = (value) => {
    // setData((prevData) => ({ ...prevData, description: value.slice(0, 500) }));
    // setValidationErrors((prevErrors) => ({ ...prevErrors, description: '' }));

    if (value.trim().length > 500) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, description: 'Description length should be 500 digits.' }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, description: '' }));
    }
    setData((prevData) => ({ ...prevData, description: value.slice(0, 500) }));
  };

  const handleActiveChange = (value) => {
    setIsChecked(!isChecked);
    setData((prevData) => ({ ...prevData, active: !prevData.active }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = {};

    if (data.department.trim() === "") {
      errors.department = "Department Name is required.";
    }
    if (data.shortName.trim() === "") {
      errors.shortName = "Department Short Name is required.";
    }
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    setValidationErrors({});
    if (id) {
      setLoaderShow2(true)
      try {
        const res = await Department_edit(id, data);
        if (res.status == 200) {
          handleExceptionSuccessMessages(res.data.message);
          setLoaderShow2(false)
          if (buttonClicked == 'submit') {
            setTimeout(function () {
              navigate('/all-department')
            }, 2000);
          }
        } else if (res.status === 400) {
          if (res.data.ErrorCode) {
            const validationErrorMessage = res.data.ErrorMessage;
            const errorMessagesArray = validationErrorMessage.split(', ');

            errorMessagesArray.forEach(errorMessage => {
              const [, fieldName, errorMessageText] = errorMessage.match(/\"(.*?)\" (.*)/);
              handleExceptionError(`${fieldName} - ${errorMessageText}`);
              setLoaderShow2(false)
            });
          }
          if (res.data.Message) {
            handleExceptionError(res.data.Message);
            setLoaderShow2(false)
          }
        } else if (res.status === 401) {
          handleExceptionError('Unauthorized');
          setLoaderShow2(false)
          setTimeout(() => {
            localStorage.clear();
            navigate('/')
          }, 3000);
          ///logout();
        }
        else if (res.status === 500) {
          handleExceptionError(res.statusText);
          setLoaderShow2(false)
        }
      } catch (error) {
        handleExceptionError(error.message);
        setLoaderShow2(false)
      } finally {
        setLoaderShow2(false);
      }
    } else {
      setLoaderShow2(true)
      try {
        const res = await Department_entry(data);
        if (res.status == 200) {
          handleExceptionSuccessMessages(res.data);
          handleFormReset();
          setLoaderShow2(false)
          if (buttonClicked == 'submit') {
            setTimeout(function () {
              navigate('/all-department')
            }, 2000);
          }
        } else if (res.status === 400) {
          if (res.data.ErrorCode) {
            const validationErrorMessage = res.data.ErrorMessage;
            const errorMessagesArray = validationErrorMessage.split(', ');

            errorMessagesArray.forEach(errorMessage => {
              const [, fieldName, errorMessageText] = errorMessage.match(/\"(.*?)\" (.*)/);
              handleExceptionError(`${fieldName} - ${errorMessageText}`);
              setLoaderShow2(false)
            });
          }
          if (res.data.Message) {
            handleExceptionError(res.data.Message);
            setLoaderShow2(false)
          }
        } else if (res.status === 401) {
          handleExceptionError('Unauthorized');
          setLoaderShow2(false)
          setTimeout(() => {
            localStorage.clear();
            navigate('/')
          }, 1000);
          ///logout();
        }
        else if (res.status === 200) {
          var dataError = res.data.Errors;
          dataError.map((message, index) => {
            handleExceptionError(message.Message);
            setLoaderShow2(false)
          })
        }
        else if (res.status === 500) {
          handleExceptionError(res.statusText);
          setLoaderShow2(false)
        }
      } catch (error) {
        handleExceptionError(error.message);
        setLoaderShow2(false)
      }
      finally {
        setLoaderShow2(false)
      }
    }
  };
  const handleFormReset = () => {
    setData({
      department: '',
      shortName: '',
      description: '',
      active: true,
    });
    setValidationErrors({
      department: '',
      shortName: '',
      description: '',
    })
  };

  return (
    <Dashboard title={id === undefined ? 'New Department' : 'Edit Department'}>
      <Helmet>
        <title>Add Department | J mehta</title>
      </Helmet>
      <ErrorSnackbar errorMessages={ExceptionError} onClearErrors={clearErrors} />
      <SuccessSnackbar successMessages={successMessages} onclearSuccess={clearSuccess} />
      {LoaderShow ? (
        <LoaderComponent />
      ) : (
        <>
          {DepartmentAccess ? (
            <div>
              <div className="new_client_title">
                {/* <h2>{id === undefined ? 'New' : 'Edit'} Department</h2> */}
                <Link to='/all-department'>
                  <button>
                    <AiFillCloseCircle /> Close
                  </button>
                </Link>
              </div>
              <div className="new_client_content_wrapper">
                <div className="new_client_menu"></div>
                <form onSubmit={handleSubmit} onReset={handleFormReset}>
                  <div className="row new_client_form">
                    <div className="col new_client_form">
                      <div className="new_client_part_1 w-100">
                        <Input2 placeholder="Enter Here" label="Department" required
                          value={data.department}
                          onChange={(e) => handleDepartmentChange(e)} />
                        {validationErrors.department && <div className="error">{validationErrors.department}</div>}
                      </div>
                    </div>
                    <div className="col ">
                      <div className="new_client_part_1 w-100">
                        <Input2
                          placeholder="Enter Here"
                          label="Department Short Name" required
                          value={data.shortName}
                          onChange={(e) => handleShortNameChange(e)}
                        />
                        {validationErrors.shortName && <div className="error">{validationErrors.shortName}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-xl-12">
                      <div className="new_client_part_1 w-100">
                        <Textarea
                          label="Description"
                          placeholder="Enter your description..."
                          rows={4}
                          cols={50}
                          value={data.description}
                          onChange={(e) => handleDescriptionChange(e)}
                        />
                        {validationErrors.description && <div className="error mt-2">{validationErrors.description}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-xl-12">
                      <div className="new_client_part_1 mt-3">
                        <label className='label_main'> Active
                          <input type="checkbox" checked={data.active}
                            onChange={(e) => handleActiveChange(e.target.checked)} />
                          <span class="geekmark"> </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="btn_save d-flex justify-content-end">
                    <button type='reset' className="tab1 save_button me-4">
                      <img src="../img/clockwise.svg" />
                      Reset
                    </button>
                    <button
                      type='submit'
                      disabled={disabledBtn}
                      name='save'
                      onClick={() => setButtonClicked('submit')}
                      className="tab1 save_button me-4"
                    >
                      <>
                        <img src="../img/Save.svg" />
                        {id === undefined ? 'Save' : 'Update'}
                        {LoaderShow2 && buttonClicked === 'submit' && (
                          <LoaderComponent2 />
                        )}
                      </>
                    </button>
                    {id === undefined && (
                      <button
                        type='submit'
                        disabled={disabledBtn}
                        name='saveAndMore'
                        onClick={() => setButtonClicked('saveAndMore')}
                        className="tab1 save_button me-0"
                      >
                        <>
                          <img src="../img/Save.svg" />
                          Save & More
                          {LoaderShow2 && buttonClicked === 'saveAndMore' && (
                            <LoaderComponent2 />
                          )}
                        </>
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <NoPermission />
          )}
        </>
      )}
    </Dashboard>
  );
};

export default Department;