import React, { useState } from 'react'
import Dashboard from './dashboard'
import { Helmet } from 'react-helmet'
import ErrorSnackbar from './ErrorSnackbar'
import SuccessSnackbar from './SuccessSnackbar'
import { SlLock } from "react-icons/sl";

export default function NoPermission() {

    const [ExceptionError, setExceptionError] = useState([]);
    const [successMessages, setSuccessMessages] = useState([]);


    return (
        <div className='mt-5'>
            {/* <ErrorSnackbar errorMessages={ExceptionError} onClearErrors={clearErrors} />
                <SuccessSnackbar successMessages={successMessages} onclearSuccess={clearSuccess} /> */}
            <div class="container">
                <div class="message">
                    <SlLock />
                    <h1>You don't have permission to access</h1>
                    <p>Please contact the administrator for assistance.</p>
                </div>
            </div>
        </div>
    )
}
