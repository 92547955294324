// App.js
import React, { useState, useEffect, useRef } from 'react';
import Dashboard from '../components/dashboard.js';
import { AiFillCloseCircle,AiFillPlusCircle } from 'react-icons/ai';
import Input2 from '../components/parts/input2.js';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const Page_list = () => {

    const [isOptionsDropdownOpen, setIsOptionsDropdownOpen] = useState(false);
    const [isColumnChooserDropdownOpen , setIsColumnChooserDropdownOpen] = useState(false);
    const [setsrno, setSetsrno] = useState(true);
    const [Pages, setPages] = useState(true);
    const [Add, setAdd] = useState(true);
    const [View, setView] = useState(true);
    const [Modify, setModify] = useState(true);
    const [Delete, setDelete] = useState(true);
    const handleCheckboxChange = (event) => {
        setSetsrno(event.target.checked);
    };
    const handlePagesChange = (event) => {
        setPages(event.target.checked);
    };
    const handleAddChange = (event) => {
        setAdd(event.target.checked);
    };
    const handleViewChange = (event) => {
        setView(event.target.checked);
    };
    const handleModifyChange = (event) => {
        setModify(event.target.checked);
    };
    const handleDeleteChange = (event) => {
        setDelete(event.target.checked);
    };
    return (
        <Dashboard>
            <Helmet>
            <title>Page List | J mehta</title>
        </Helmet>
            <div className='client_panel_menu new_client_title'>
                <div className='client_panel_title'>
                    <h2>Page</h2>
                </div>
                <div className='client_panel_search'>
                    <input type="text" placeholder='Search' />
                </div>
                <div className='client_panel_option'>
                    <button onClick={() => setIsOptionsDropdownOpen(!isOptionsDropdownOpen)}>
                        <img src="./img/Menu.svg" alt="Options" />
                        Options
                    </button>


                    <ul className={`dropdown-menu ${isOptionsDropdownOpen ? 'open' : ''}`}>
                        <li>
                        <img src="./img/Editing.svg" alt="Edit" />Edit</li>
                        <li> <img src="./img/Delete.svg" alt="Delete" />Delete</li>
                        <li> <img src="./img/Location.svg" alt="Location" />Add a New Location</li>
                        <li> <img src="./img/Power_Button.svg" alt="Activate" />De-activate & Activate</li>
                    </ul>

                </div>
                <div className='client_panel_column_chooser'>
                    <button onClick={() => setIsColumnChooserDropdownOpen(!isColumnChooserDropdownOpen )}>
                        <img src="./img/right.svg" alt="Column Chooser" />
                        Column Chooser
                    </button>

                    <ul className={`dropdown-menu ${isColumnChooserDropdownOpen  ? 'open' : ''}`}>
                        <li>
                            <label className='label_main'>Sr.no
                                <input type="checkbox" checked={setsrno}
                                    onChange={handleCheckboxChange}/>
                                <span class="geekmark"> </span>
                            </label>
                        </li>
                        <li>
                            <label className='label_main'>Pages
                                <input type="checkbox" checked={Pages}
                                    onChange={handlePagesChange}/>
                                <span class="geekmark"> </span>
                                
                            </label>
                        </li>
                        <li>
                            <label className='label_main'>Add
                                <input type="checkbox" checked={Add}
                                    onChange={handleAddChange}/>
                                <span class="geekmark"></span>
                            </label>
                        </li>
                        <li>
                            <label className='label_main'>View
                                <input type="checkbox" checked={View}
                                    onChange={handleViewChange}/>
                                <span class="geekmark"></span>
                            </label>
                        </li>
                        <li>
                            <label className='label_main'>Modify
                                <input type="checkbox" checked={Modify}
                                    onChange={handleModifyChange}/>
                                <span class="geekmark"></span>
                            </label>
                        </li>
                        <li>
                            <label className='label_main'>Delete
                                <input type="checkbox" checked={Delete}
                                    onChange={handleDeleteChange}/>
                                <span class="geekmark"></span>
                                
                            </label>
                        </li>
                        
                    </ul>
                </div>

                <div className='client_panel_new'>
                    <Link to='/client_panel_checkbox'><button><AiFillPlusCircle />New</button></Link>

                </div>
                <div className='client_panel_close'>
                    <button><img src="./img/Back.svg" alt="close" />Back</button>

                </div>
            </div>
            <div className='client_panel_list d-flex'>
            

                <table className='page_list_table'>
                    <tr>
                        <th>Select</th>
                    </tr>
                    <tr>
                        <td><label className='label_main'>
                            <input type="checkbox" />
                            <span class="geekmark"></span>
                        </label></td>
                    </tr>
                    <tr>
                        <td><label className='label_main'>
                                <input type="checkbox" />
                                <span class="geekmark"></span>
                            </label></td>
                    </tr>
                    <tr>
                        <td><label className='label_main'>
                                <input type="checkbox" />
                                <span class="geekmark"></span>
                            </label></td>
                    </tr>
                    <tr>
                        <td><label className='label_main'>
                                <input type="checkbox" />
                                <span class="geekmark"></span>
                            </label></td>
                    </tr>
                </table>

                <table className='client_panel_list_table' cellPadding="0" cellSpacing="0">

                    <tr>
                    {setsrno == true && (

                        <th>Sr. no</th>
                    )}
                    {Pages == true && (

                        <th>Pages</th>
                    )}

                    {Add == true && (

                        <th>Add</th>
                        )}
                    
                        {View == true && (

                        <th>View</th>
                        )}
                    
                        {Modify == true && (

                        <th>Modify</th>
                        )}
                    
                        {Delete == true && (

                        <th>Delete</th>
                    )}
                        </tr>
                    <tr>
                    {setsrno == true && (

                        <td>01</td>
                    )}
                    {Pages == true && (

                        <td>Designation</td>
                        )}
                        {Add == true && (

                        <td className='checked'><img src="./img/big_check.svg" alt="close" /></td>
                        )}
                        {View == true && (

                        <td className='checked'><img src="./img/big_check.svg" alt="close" /></td>
                        )}
                        {Modify == true && (

                        <td></td>
                        )}
                        {Delete == true && (

                        <td></td>
                        )}  
                    </tr>
                    <tr>
                    {setsrno == true && (

                        <td>02</td>
                    )}
                    {Pages == true && (

                        <td>Department</td>
                        )}
                        {Add == true && (

                        <td></td>
                        )}
                        {View == true && (

                        <td></td>
                        )}
                        {Modify == true && (

                        <td></td>
                        )}
                        {Delete == true && (

                        <td></td>
                        )}
                        </tr>
                    <tr>
                    {setsrno == true && (

                        <td>03</td>
                    )}
                    {Pages == true && (

                        <td>User</td>
                        )}
                        {Add == true && (
                        
                        <td></td>
                        )}
                        {View == true && (

                        <td></td>
                        )}
                        {Modify == true && (

                        <td className='checked'><img src="./img/big_check.svg" alt="close" /></td>
                        )}
                        {Delete == true && (

                        <td className='checked'><img src="./img/big_check.svg" alt="close" /></td>
                        )}
                        </tr>
                    <tr>
                    {setsrno == true && (

                        <td>03</td>
                    )}
                    {Pages == true && (

                        <td>Client</td>
                        )}
                    {Add == true && (

                        <td></td>
                        )}
                    {View == true && (

                        <td></td>
                        )}
                    {Modify == true && (

                        <td></td>
                        )}
                    {Delete == true && (

                        <td></td>
                    )}
                    </tr>

                </table>
            </div>
        </Dashboard>
    );
};

export default Page_list;