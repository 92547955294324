import React, { useState, useEffect, useRef } from "react";
import Dashboard from "../components/dashboard.js";
import { AiFillCloseCircle } from "react-icons/ai";
import Input2 from "../components/parts/input2.js";
import Select from "../components/parts/select.js";
import RadioButton2 from "../components/parts/radiobutton.js";
import { User_entry_api, User_get_by_id, CheckBoxDuplicateRecord, CheckMailDuplicateRecord, UserPages_get, User_PageAccess } from '../service/api.js';
import { User_edit } from '../service/api.js';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Designation_get } from '../service/api.js';
import { Helmet } from 'react-helmet';
import { Department_get } from '../service/api.js';
import Textarea from '../components/parts/textarea.js';
import ErrorSnackbar from './../components/ErrorSnackbar.js';
import SuccessSnackbar from './../components/SuccessSnackbar.js';
import { decryption } from "../components/utils/utils.js";

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Swal from "sweetalert2";
import NoPermission from "../components/NoPermission.js";
import LoaderComponent from "../components/LoaderComponent.js";
import LoaderComponent2 from "../components/LoaderComponent2.js";
import { FaEyeSlash, FaRegEye } from "react-icons/fa";


const steps = ['Step 1', 'Step 2',];

const User_entry = () => {
  const [departmentOption2, setDepartmentOption2] = useState([

  ]);
  const [departmentOption, setDepartmentOption] = useState([

  ]);
  const [LoaderShow, setLoaderShow] = useState(true)
  const [LoaderShow2, setLoaderShow2] = useState(false)

  const [UserAccessPages, setUserAccessPages] = useState([])

  const [activeStep, setActiveStep] = useState(0);

  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [selectedGender, setSelectedGender] = useState([]);
  const [ExceptionError, setExceptionError] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);
  const [buttonClicked, setButtonClicked] = useState('');
  const [disabledBtn, setDisabledBtn] = useState(false);

  const [data, setData] = useState({
    Username: '',
    Password: '',
    cpassword: '',
    Firstname: '',
    Lastname: '',
    Gender: '',
    Mobilenumber1: '',
    Mobilenumber2: '',
    Emailid: '',
    Homeaddress: '',
    Remarks: '',
    Designation: '',
    Department: '',
    Role: true,
    UserAccessData: []

  });
  const [validationErrors, setValidationErrors] = useState({
    Username: '',
    Password: '',
    cpassword: '',
    Firstname: '',
    Lastname: '',
    Gender: '',
    Mobilenumber1: '',
    Mobilenumber2: '',
    Emailid: '',
    Homeaddress: '',
    Remarks: '',
    Designation: '',
    Department: '',
    Role: '',
    UserAccessData: []
  });
  const [UserAddAccess, setUserAddAccess] = useState(false)

  const isEffectExecutedRef = useRef(false)

  useEffect(() => {
    if (!isEffectExecutedRef.current) {
      PageAccess()
      isEffectExecutedRef.current = true

    }
  }, []);

  const PageAccess = async () => {
    try {
      // setLoading(true);
      const res = await User_PageAccess("3");
      if (res.status == 200) {
        if (id ? res.data.AllowUpdate === true : res.data.AllowAdd === true) {
          setUserAddAccess(true)
          get_GetUserPages();

          if (id) {
            api_get();
            get_designation();
            get_department();
          } else {
            get_designation();
            get_department();
          }
          setLoaderShow(false)
        }
        setLoaderShow(false)

      } else if (res.status === 400) {
        if (res.data.ErrorCode) {
          const validationErrorMessage = res.data.ErrorMessage;
          const errorMessagesArray = validationErrorMessage.split(', ');

          errorMessagesArray.forEach(errorMessage => {
            const [, fieldName, errorMessageText] = errorMessage.match(/\"(.*?)\" (.*)/);

            // Format error message and push it to the formattedErrorMessages array
            handleExceptionError(`${fieldName} - ${errorMessageText}`);
          });
        }
        if (res.data.Message) {
          handleExceptionError(res.data.Message);
        }
      } else if (res.status === 401) {

        handleExceptionError('Unauthorized');
        setTimeout(() => {
          localStorage.clear();
          navigate('/')
        }, 1000);
        ///logout();
      }
      else if (res.status === 200) {
        var dataError = res.data.Errors;
        dataError.map((message, index) => {
          handleExceptionError(message.Message);

        })
      }
      else if (res.status === 500) {
        handleExceptionError(res.statusText);
      }
    } catch (error) {
      handleExceptionError(error.message);
    }
    finally {
      // setLoading(false);
    }
  }
  const api_get = async () => {
    setLoaderShow(true)
    try {
      const resp = await User_get_by_id(id);
      if (resp.status === 200) {

        const departmentDataID = resp.data.user.DepartmentID;
        const designationDataID = resp.data.user.DesignationID;

        setSelectedOptions(designationDataID)
        setSelectedDepartment(departmentDataID);

        localStorage.setItem('departmentID', JSON.stringify(departmentDataID));
        localStorage.setItem('designationID', JSON.stringify(designationDataID));

        // console.log(selectedDepartment)
        // const existingDepartment = departmentOption.find(item => item.id === departmentDataID);
        // if (!existingDepartment) {
        //   const userDepartment = departmentOption2.find(item => item.DepartmentID === departmentDataID);
        //   if (userDepartment) {
        //     departmentOption.push({
        //       id: userDepartment.DepartmentID,
        //       name: userDepartment.DepartmentName,
        //     });
        //   }
        //   setDepartmentOption(departmentOption);
        //   setSelectedDepartment(departmentDataID);
        //   console.log(departmentDataID)
        // } else {
        //   setSelectedDepartment(departmentDataID);

        // }
        var sortedS = resp.data.UserPermission.sort((a, b) => a.PageID - b.PageID);

        const updatedUserAccessData = sortedS.map(permission => ({
          ...permission,
          AllowAdd: permission.AllowAdd ? "1" : "0",
          AllowDelete: permission.AllowDelete ? "1" : "0",
          AllowUpdate: permission.AllowUpdate ? "1" : "0",
          AllowView: permission.AllowView ? "1" : "0",
        }));
       
        setSelectedGender(resp.data.user.Gender)
        setData({
          Username: resp.data.user.Username,
          Password: decryption(resp.data.user.Password),
          cpassword: decryption(resp.data.user.Password),
          Firstname: resp.data.user.FirstName,
          Lastname: resp.data.user.LastName,
          Mobilenumber1: resp.data.user.MobileNumber1,
          Mobilenumber2: resp.data.user.MobileNumber2,
          Emailid: decryption(resp.data.user.EmailID),
          Gender: resp.data.user.Gender,
          Homeaddress: resp.data.user.HomeAddress,
          Remarks: resp.data.user.Remarks,
          Department: resp.data.user.DepartmentID,
          Designation: resp.data.user.DesignationID,
          Role: resp.data.user.IsTL,
          UserAccessData: updatedUserAccessData,
        });
        setLoaderShow(false)
      }
      else if (resp.status === 401) {

        handleExceptionError('Unauthorized');
        setTimeout(() => {
          localStorage.clear();
          navigate('/')
        }, 1000);
        ///logout();
      }
    } catch (error) {
      handleExceptionError(error.message);
    }
  };

  function handleExceptionError(res) {
    setExceptionError(ExceptionError => [
      ...ExceptionError,
      { id: Date.now(), message: res },
    ]);
  }
  function handleExceptionSuccessMessages(resp) {
    setSuccessMessages(successMessages => [
      ...successMessages,
      { id: Date.now(), message: resp },
    ]);
  }
  function clearErrors(id) {
    setExceptionError(prevMessages =>
      prevMessages.filter(msg => msg.id !== id)
    );
  }
  function clearSuccess(id) {
    setSuccessMessages(prevMessages =>
      prevMessages.filter(msg => msg.id !== id)
    );
  }

  function handleRadioChange1(newValue) {
    setData({ ...data, Role: newValue });
  }
  
  const handleSelectChange = async (event) => {
    const selectedValues = Array.from(event.target.selectedOptions, (option) => option.value);
    setSelectedOptions(selectedValues);
    const selected = event.target.value;
    setValidationErrors((prevErrors) => ({ ...prevErrors, Designation: '' }));
    setData((prevData) => ({
      ...prevData,
      Designation: selected,
    }));
  };
  const handleSelectChange1 = async (event) => {
    console.log(event.target.value)
    const selectedValues1 = Array.from(event.target.selectedOptions, (option) => option.value);
    setSelectedDepartment(selectedValues1);
    console.log(selectedValues1)
    const selected = event.target.value;
    setValidationErrors((prevErrors) => ({ ...prevErrors, Department: '' }));
    setData((prevData) => ({
      ...prevData,
      Department: selected,
    }));
  };
  const handleGendeerChange = async (event) => {
    const selectedValues1 = Array.from(event.target.selectedOptions, (option) => option.value);
    setSelectedGender(selectedValues1);
    const selected = event.target.value;
    setValidationErrors((prevErrors) => ({ ...prevErrors, Gender: '' }));
    setData((prevData) => ({
      ...prevData,
      Gender: selected,
    }));
  };

  const [options, setOptions] = useState([

  ]);

  const get_designation = async () => {
    const DesignationID = JSON.parse(localStorage.getItem('designationID'));
    try {
      const res = await Designation_get();
      if (res.status === 200) {

        const newOptions = res.data.DropdownData
          .filter((item) => item.IsActive === true || item.DesignationID === DesignationID)
          .map((item) => ({
            id: item.DesignationID,
            name: item.DesignationName,
          }));
        setOptions([...options, ...newOptions]);
      } else if (res.status === 401) {

        handleExceptionError('Unauthorized');
        setTimeout(() => {
          localStorage.clear();
          navigate('/')
        }, 1000);
        ///logout();
      }
    } catch (error) {
      handleExceptionError(error.message);
    }
  };

  const get_department = async () => {
    const DepartmentID = JSON.parse(localStorage.getItem('departmentID'));
    try {
      const res = await Department_get();
      if (res.status === 200) {

        const departmentData = res.data.DropdownData;
        setDepartmentOption2(departmentData)
        let newOptions = departmentData
          .filter(item => item.IsActive === true || item.DepartmentID === DepartmentID)
          .map(item => ({
            id: item.DepartmentID,
            name: item.DepartmentName,
          }));

        // If editing, add the department associated with the user
        setDepartmentOption(newOptions);
      } else if (res.status === 401) {
        handleExceptionError('Unauthorized');
        setTimeout(() => {
          localStorage.clear();
          navigate('/');
        }, 1000);
      }
    } catch (error) {
      handleExceptionError(error.message);
    }
  };

  const handleUsernameChange = async (value) => {
    setData((prevData) => ({ ...prevData, Username: value.slice(0, 50) }));
    if (value.trim() == '') {
      return;
    }
    var ob = {
      "table": "User",
      "name": value.trim(),
      "id": id ? id : "0",
    }
    const resp = await CheckBoxDuplicateRecord(ob);
    if (resp.status === 200) {
      setDisabledBtn(false)
      // setValidationErrors((prevErrors) => ({ ...prevErrors, Username: '' }));
      if (value.trim().length > 50) {
        setValidationErrors((prevErrors) => ({ ...prevErrors, Username: 'Username length should be 50 digits.' }));
      } else {
        setValidationErrors((prevErrors) => ({ ...prevErrors, Username: '' }));
      }
    } else if (resp.status === 400) {
      setDisabledBtn(true)
      setValidationErrors((prevErrors) => ({ ...prevErrors, Username: resp.data.Errors[0].Message }));
    } else if (resp.status === 401) {

      handleExceptionError('Unauthorized');
      setTimeout(() => {
        localStorage.clear();
        navigate('/')
      }, 1000);
      ///logout();
    }
    else if (resp.status === 500) {
      setDisabledBtn(false)
      handleExceptionError(resp.statusText);
    }
  };
  // const handlePasswordChange = (value) => {
  //   setData((prevData) => ({ ...prevData, Password: value.slice(0, 8) }));
  //   setValidationErrors((prevErrors) => ({ ...prevErrors, Password: '' }));
  // };
  const handlePasswordChange = (value) => {
    if (value.trim().length > 8) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, Password: 'Password length should be 8 digits.' }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, Password: '' }));
    }

    setData((prevData) => ({ ...prevData, Password: value.slice(0, 8) }));
  };


  const handleConfirmPasswordChange = (value) => {
    // setData((prevData) => ({ ...prevData, cpassword: value.slice(0, 8) }));
    // setValidationErrors((prevErrors) => ({ ...prevErrors, cpassword: '' }));

    if (value.trim().length > 8) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, cpassword: 'Confirm Password length should be 8 digits.' }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, cpassword: '' }));
    }
    setData((prevData) => ({ ...prevData, cpassword: value.slice(0, 8) }));
  };
  const handleFirstNameChange = (value) => {
    // setValidationErrors((prevErrors) => ({ ...prevErrors, Firstname: '' }));
    if (value.trim().length > 50) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, Firstname: 'First Name length should be 50 digits.' }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, Firstname: '' }));
    }
    setData((prevData) => ({ ...prevData, Firstname: value.slice(0, 50) }));
  };
  const handleLastNameChange = (value) => {
    if (value.trim().length > 50) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, Lastname: 'Last Name length should be 50 digits.' }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, Lastname: '' }));
    }
    setData((prevData) => ({ ...prevData, Lastname: value.slice(0, 50) }));
    // setValidationErrors((prevErrors) => ({ ...prevErrors, Lastname: '' }));
  };
  const handleMobileNumber1Change = (value) => {
    if (/^\d*$/.test(value)) {
      if (value.trim().length > 10) {
        setValidationErrors((prevErrors) => ({ ...prevErrors, Mobilenumber1: 'Mobile Number length should be 10 digits.' }));
      } else {
        setValidationErrors((prevErrors) => ({ ...prevErrors, Mobilenumber1: '' }));
      }
      setData((prevData) => ({ ...prevData, Mobilenumber1: value.slice(0, 10) }));
      // setValidationErrors((prevErrors) => ({ ...prevErrors, Mobilenumber1: '' }));
    }
  };

  const handleMobileNumber2Change = (value) => {
    if (/^\d*$/.test(value)) {
      if (value.trim().length > 10) {
        setValidationErrors((prevErrors) => ({ ...prevErrors, Mobilenumber2: 'Mobile Number length should be 10 digits.' }));
      } else {
        setValidationErrors((prevErrors) => ({ ...prevErrors, Mobilenumber2: '' }));
      }
      setData((prevData) => ({ ...prevData, Mobilenumber2: value.slice(0, 10) }));
      // setValidationErrors((prevErrors) => ({ ...prevErrors, Mobilenumber2: '' }));
    }
  };
  const handleEmailIDChange = async (value) => {
    setData((prevData) => ({ ...prevData, Emailid: value }));
    setValidationErrors((prevErrors) => ({ ...prevErrors, Emailid: '' }));
    var ob = {
      "useremail": value.trim(),
      "id": id ? id : "0",
    }
    const resp = await CheckMailDuplicateRecord(ob);
    if (resp.status === 200) {
      setDisabledBtn(false)
      setValidationErrors((prevErrors) => ({ ...prevErrors, Emailid: '' }));
    } else if (resp.status === 400) {
      setDisabledBtn(true)
      setValidationErrors((prevErrors) => ({ ...prevErrors, Emailid: resp.data.Message }));
    } else if (resp.status === 401) {

      handleExceptionError('Unauthorized');
      setTimeout(() => {
        localStorage.clear();
        navigate('/')
      }, 1000);
      ///logout();
    }
    else if (resp.status === 500) {
      setDisabledBtn(false)
      handleExceptionError(resp.statusText);
    }
  };

  const handleHomeAddressChange = (value) => {
    if (value.trim().length > 500) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, Homeaddress: 'Home Address length should be 500 digits.' }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, Homeaddress: '' }));
    }
    setData((prevData) => ({ ...prevData, Homeaddress: value.slice(0, 500) }));
    // setValidationErrors((prevErrors) => ({ ...prevErrors, Homeaddress: '' }));
  };
  const handleRemorkChange = (value) => {
    if (value.trim().length > 500) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, Remarks: 'Remark length should be 500 digits.' }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, Remarks: '' }));
    }
    setData((prevData) => ({ ...prevData, Remarks: value.slice(0, 500) }));
    // setValidationErrors((prevErrors) => ({ ...prevErrors, Remarks: '' }));
  };

  const handleSubmit = async (event) => {
    

    try {
      event.preventDefault();
      const NumberValidation = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;

      if (activeStep === 0) {
        const errors = {};

        if (data.Username.trim() === '') {
          errors.Username = 'Username is required.';
        }
        if (data.Password.trim() === '' && id === undefined) {
          errors.Password = 'Password is required.';
        }
        if (data.cpassword.trim() === '' && id === undefined) {
          errors.cpassword = 'Confirm Password is required.';
        }
        if (data.cpassword) {
          if (data.cpassword !== data.Password) {
            errors.cpassword = 'Password not matched.';
          }
        }
        if (id) {
          if (data.Password !== '') {
            if (data.cpassword !== data.Password) {
              errors.cpassword = 'Password not matched.';
            }
          }
        }
        if (data.Firstname.trim() === '') {
          errors.Firstname = 'First Name is required.';
        }
        if (data.Lastname.trim() === '') {
          errors.Lastname = 'Last Name is required.';
        }
        if (data.Mobilenumber1.trim() === '') {
          errors.Mobilenumber1 = 'Mobile Number is required';
        }
        if (data.Mobilenumber1) {
          if (!NumberValidation.test(data.Mobilenumber1)) {
            errors.Mobilenumber1 = 'Invalid Mobile Number';
          }
        }
        if (data.Mobilenumber2) {
          if (!NumberValidation.test(data.Mobilenumber2)) {
            errors.Mobilenumber2 = 'Invalid Mobile Number';
          }
          if(data.Mobilenumber2==data.Mobilenumber1){
            errors.Mobilenumber2 = 'Mobile Number is same please change';
          }
        }
        if (data.Emailid.trim() === '') {
          errors.Emailid = 'Email ID is required.';
        }
        if (data.Emailid) {
          const email_pattern = /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
          if (!email_pattern.test(data.Emailid)) {
            errors.Emailid = "Invalid Email format";
          }
        }
        if (data.Homeaddress.trim() === '') {
          errors.Homeaddress = 'Home Address is required.';
        }
        if (data.Designation.length === 0) {
          errors.Designation = 'Designation is required.';
        }
        if (data.Department.length === 0) {
          errors.Department = 'Department is required.';
        }
        if (data.Gender.length === 0) {
          errors.Gender = 'Gender is required.';
        }

        if (Object.keys(errors).length > 0) {
          setValidationErrors(errors);
          return;
        }
        setValidationErrors({});
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }

      if (activeStep === 1) {

        if (id) {
          setLoaderShow2(true)
          const res = await User_edit(id, data);
          if (res.status == 200) {
            handleExceptionSuccessMessages(res.data.message);
            handleFormReset();
            setLoaderShow2(false)
            setTimeout(function () {
              navigate('/all-users')
            }, 2000);
            localStorage.removeItem('departmentID');
            localStorage.removeItem('designationID');

          } else if (res.status === 201) {
            var dataError = res.data.Errors;
            dataError.map((message, index) => {
              handleExceptionError(message.Message);
            })
          }
          else if (res.status === 400) {
            if (res.data.ErrorCode) {
              const validationErrorMessage = res.data.ErrorMessage;
              const errorMessagesArray = validationErrorMessage.split(', ');

              errorMessagesArray.forEach(errorMessage => {
                const [, fieldName, errorMessageText] = errorMessage.match(/\"(.*?)\" (.*)/);
                handleExceptionError(`${fieldName} - ${errorMessageText}`);
              });
            }
            if (res.data.Message) {
              handleExceptionError(res.data.Message);
            }
          } else if (res.status === 401) {

            handleExceptionError('Unauthorized');
            setTimeout(() => {
              localStorage.clear();
              navigate('/')
            }, 1000);
            ///logout();
          }
          else if (res.status === 500) {
            handleExceptionError(res.statusText);
          }
        } else {
          setLoaderShow2(true)
          const res = await User_entry_api(data);
          if (res.status == 200) {
            // var message = 'Users Added Successful'
            handleExceptionSuccessMessages(res.data.message);
            // setActiveStep((prevActiveStep) => prevActiveStep + 1);
            handleFormReset();
            setLoaderShow2(false)
            if (buttonClicked == 'submit') {
              setTimeout(function () {
                navigate('/all-users')
              }, 2000);
            }
          } else if (res.status === 201) {
            var dataError = res.data.Errors;
            dataError.map((message, index) => {
              handleExceptionError(message.Message);
            })
          }
          else if (res.status === 400) {
            if (res.data.ErrorCode) {
              const validationErrorMessage = res.data.ErrorMessage;
              const errorMessagesArray = validationErrorMessage.split(', ');

              errorMessagesArray.forEach(errorMessage => {
                const [, fieldName, errorMessageText] = errorMessage.match(/\"(.*?)\" (.*)/);
                handleExceptionError(`${fieldName} - ${errorMessageText}`);
              });
            }
            if (res.data.Message) {
              handleExceptionError(res.data.Message);
            }
          } else if (res.status === 401) {

            handleExceptionError('Unauthorized');
            setTimeout(() => {
              localStorage.clear();
              navigate('/')
            }, 1000);
            ///logout();
          }
          else if (res.status === 500) {
            handleExceptionError(res.statusText);
          }
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const get_GetUserPages = async () => {
    try {
      const res = await UserPages_get();
      if (res.status == 200) {
         var  userPage = res.data.data
         const userPages = userPage.sort((a, b) => a.PageID - b.PageID);
       
        setUserAccessPages(userPages)
        if (id) {
          setData(prevData => ({
            ...prevData,
            UserAccessData: userPages.map(page => {
              const existingPage = prevData.UserAccessData.find(item => item.PageID === page.PageID);
              return existingPage ? existingPage : {
                AllowAdd: "0",
                AllowView: "0",
                AllowUpdate: "0",
                AllowDelete: "0",
                PageID: page.PageID
              };
            })
          }));
        } else {
          setData(prevData => ({
            ...prevData,
            UserAccessData: userPages.map(page => ({
              AllowAdd: "0",
              AllowView: "0",
              AllowUpdate: "0",
              AllowDelete: "0",
              PageID: page.PageID
            }))
          }));
        }
      } else if (res.status === 401) {
        handleExceptionError('Unauthorized');
        setTimeout(() => {
          localStorage.clear();
          navigate('/')
        }, 1000);
        ///logout();
      }
    } catch (error) {
      handleExceptionError(error.message);
    }
  };

  const areAllChecked = (columnName) => {
    const pages = data.UserAccessData.filter(page => page[columnName] === "1");
    return pages.length === data.UserAccessData.length;
  };

  const handleHeaderCheckboxChange = (columnName, checked) => {
    const updatedData = { ...data };
    updatedData.UserAccessData = Array.isArray(updatedData.UserAccessData) ? updatedData.UserAccessData : [];
    updatedData.UserAccessData.forEach(page => {
      switch (columnName) {
        case 'AllowAdd':
          page.AllowAdd = checked ? "1" : "0";
          break;
        case 'AllowUpdate':
          if (checked) {
            page.AllowView = "1";
          }
          page.AllowUpdate = checked ? "1" : "0";
          break;
        case 'AllowDelete':
          page.AllowDelete = checked ? "1" : "0";
          if (checked) {
            page.AllowView = "1";
          }
          break;
        case 'AllowView':
          page.AllowView = checked ? "1" : "0";
          if (page.AllowUpdate === "1" || page.AllowDelete === "1" && !checked) {
            page.AllowView = "1";
          }
          break;
        default:
          break;
      }
    });
    setData(updatedData);
  };

  const handleColumnCheckboxChange = (columnName, pageID, checked) => {

    const pageIndex = UserAccessPages.findIndex(page => page.PageID === pageID);
    console.log(pageIndex)
    if (pageIndex !== -1) {
      const updatedData = { ...data };
      updatedData.UserAccessData = updatedData.UserAccessData.sort((a, b) => a.PageID - b.PageID);
     
      updatedData.UserAccessData = Array.isArray(updatedData.UserAccessData) ? updatedData.UserAccessData : [];

      switch (columnName) {
        case 'AllowAdd':
          updatedData.UserAccessData[pageIndex].AllowAdd = checked ? "1" : "0";
          break;
        case 'AllowUpdate':
          updatedData.UserAccessData[pageIndex].AllowUpdate = checked ? "1" : "0";
          if (checked) {
            updatedData.UserAccessData[pageIndex].AllowView = "1";
          }
          break;
        case 'AllowDelete':
          updatedData.UserAccessData[pageIndex].AllowDelete = checked ? "1" : "0";
          if (checked) {
            updatedData.UserAccessData[pageIndex].AllowView = "1";
          }
          break;
        case 'AllowView':
          updatedData.UserAccessData[pageIndex].AllowView = checked ? "1" : "0";
          if (updatedData.UserAccessData[pageIndex].AllowUpdate === "1" || updatedData.UserAccessData[pageIndex].AllowDelete === "1" && !checked) {
            updatedData.UserAccessData[pageIndex].AllowView = "1";
          }
          break;
        default:
          break;
      }
      ///console.log(updatedData.UserAccessData)
      setData(updatedData);
    } else {
      console.log(`Page with ID ${pageID} not found.`);
    }
  };

  const handleFormReset = () => {

    let resetFields = {};
    let resetError = {};
    switch (activeStep) {
      case 0:
        resetFields = {
          Username: '',
          Password: '',
          cpassword: '',
          Firstname: '',
          Lastname: '',
          Gender: '',
          Mobilenumber1: '',
          Mobilenumber2: '',
          Emailid: '',
          Homeaddress: '',
          Remarks: '',
          Designation: '',
          Department: '',
          Role: true,
        };
        resetError = {
          Username: '',
          Password: '',
          cpassword: '',
          Firstname: '',
          Lastname: '',
          Gender: '',
          Mobilenumber1: '',
          Mobilenumber2: '',
          Emailid: '',
          Homeaddress: '',
          Remarks: '',
          Designation: '',
          Department: '',
          Role: '',
        };
        break;
    }
    setData(prevData => ({
      ...prevData,
      ...resetFields,
    }));
    setValidationErrors(prevData => ({
      ...prevData,
      ...resetError,
    }));
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const CtogglePasswordVisibility = () => {
    setShowCPassword(!showCPassword);
  };

  return (
    <Dashboard title={id === undefined ? 'New User' : 'Edit User'}>
      <Helmet>
        <title>Add User | J mehta</title>
      </Helmet>
      <ErrorSnackbar errorMessages={ExceptionError} onClearErrors={clearErrors} />
      <SuccessSnackbar successMessages={successMessages} onclearSuccess={clearSuccess} />
      {LoaderShow ? (
        <LoaderComponent />
      ) : (
        <>
          {UserAddAccess ? (
            <div>
              <div className="new_client_title">
                <Link to='/all-users'><button>
                  <AiFillCloseCircle /> Close
                </button></Link>
              </div>
              <div className="new_client_menu"></div>
              <Box sx={{ width: '100%' }}>
                <Stepper sx={{ width: '50%', margin: 'auto' }} activeStep={activeStep}>
                  {steps.map((label, index) => {
                    return (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                <div>
                  <>
                    <Typography sx={{ mt: 5, mb: 1 }}></Typography>
                    <div className="new_client_content_wrapper">
                      <form onSubmit={handleSubmit} onReset={handleFormReset} >
                        <div>
                          {activeStep === 0 && (
                            <>
                              <div className="row new_client_form">
                                <div className="col new_client_form">
                                  <div className="new_client_part_1 w-100">
                                    <Input2 placeholder="Enter Here" label="First Name" required
                                      value={data.Firstname}
                                      onChange={(e) => handleFirstNameChange(e)} />
                                    {validationErrors.Firstname && <div className="error">{validationErrors.Firstname}</div>}
                                  </div>
                                </div>
                                <div className="col ">
                                  <div className="new_client_part_1 w-100">
                                    <Input2 placeholder="Enter last name" label="Last Name" required
                                      value={data.Lastname}
                                      onChange={(e) => handleLastNameChange(e)} />
                                    {validationErrors.Lastname && <div className="error">{validationErrors.Lastname}</div>}
                                  </div>
                                </div>
                              </div>

                              <div className="row new_client_form">
                                <div className="col new_client_form">
                                  <div className="new_client_part_1 w-100">
                                    <Input2 placeholder="Enter Here" label="Username" required
                                      value={data.Username}
                                      onChange={(e) => handleUsernameChange(e)} />
                                    {validationErrors.Username && <div className="error">{validationErrors.Username}</div>}
                                  </div>
                                </div>
                                <div className="col ">
                                  <div className="new_client_part_1 w-100">
                                    <Input2 placeholder="Enter Here" label="Email ID " required
                                      value={data.Emailid}
                                      onChange={(e) => handleEmailIDChange(e)} />
                                    {validationErrors.Emailid && <div className="error">{validationErrors.Emailid}</div>}
                                  </div>
                                </div>
                              </div>

                              <div className="row new_client_form">
                                <div className="col new_client_form">
                                  <div className="new_client_part_1 w-100">
                                    <Input2
                                      type="password" // Initially set as password type
                                      placeholder="Enter Here"
                                      label="Password"
                                      required
                                      value={data.Password}
                                      onChange={(e) => handlePasswordChange(e)}
                                      showPassword={showPassword} // Pass showPassword state to Input2
                                      togglePasswordVisibility={togglePasswordVisibility} // Pass togglePasswordVisibility function to Input2
                                    />
                                    {validationErrors.Password && <div className="error">{validationErrors.Password}</div>}
                                  </div>
                                </div>
                                <div className="col ">
                                  <div className="new_client_part_1 w-100 position-relative">
                                    <Input2 type="password" placeholder="Enter Here" label="Confirm Password" required
                                      value={data.cpassword}
                                      onChange={(e) => handleConfirmPasswordChange(e)}
                                      showPassword={showCPassword}
                                      togglePasswordVisibility={CtogglePasswordVisibility} />

                                    {validationErrors.cpassword && <div className="error">{validationErrors.cpassword}</div>}
                                  </div>
                                </div>
                              </div>

                              <div className="row new_client_form">
                                <div className="col new_client_form">
                                  <div className="new_client_part_1 w-100">
                                    <Input2 placeholder="Enter mobile number " label="Mobile Number 1" required
                                      value={data.Mobilenumber1}
                                      onChange={(e) => handleMobileNumber1Change(e)} />
                                    {validationErrors.Mobilenumber1 && <div className="error">{validationErrors.Mobilenumber1}</div>}
                                  </div>
                                </div>
                                <div className="col ">
                                  <div className="new_client_part_1 w-100">
                                    <Input2 placeholder="Enter mobile number" label="Mobile Number 2"
                                      value={data.Mobilenumber2}
                                      onChange={(e) => handleMobileNumber2Change(e)} />
                                    {validationErrors.Mobilenumber2 && <div className="error">{validationErrors.Mobilenumber2}</div>}
                                  </div>
                                </div>
                              </div>

                              <div className="row new_client_form">
                                <div className="col new_client_form">
                                  <div className="new_client_part_1 w-100">
                                    <Select label="Gender" required
                                      options={[{ 'id': 1, 'name': 'Male' }, { 'id': 2, 'name': 'Female' }, { 'id': 3, 'name': 'Other' }]}
                                      selectedOptions={selectedGender}
                                      handleChange={handleGendeerChange}
                                    />
                                    {validationErrors.Gender && <div className="error">{validationErrors.Gender}</div>}
                                  </div>
                                </div>
                                <div className="col ">
                                  <div className="new_client_part_1 w-100">
                                    <Select label="Department" options={departmentOption} required
                                      selectedOptions={selectedDepartment} handleChange={handleSelectChange1}
                                    />
                                    {validationErrors.Department && <div className="error">{validationErrors.Department}</div>}
                                  </div>
                                </div>
                              </div>

                              <div className="row new_client_form">
                                <div className="col new_client_form">
                                  <div className="new_client_part_1 w-100">
                                    <Select label="Designation" options={options} required
                                      selectedOptions={selectedOptions} handleChange={handleSelectChange}
                                    />
                                    {validationErrors.Designation && <div className="error">{validationErrors.Designation}</div>}
                                  </div>
                                </div>
                                <div className="col ">
                                  <div className="new_client_part_1 w-100">
                                    <div className="New_client_panel">
                                      <div className="radio_button_label">
                                        <h2>Select Role</h2>
                                      </div>
                                      <RadioButton2 label="Team Leader" value="1" checked={data.Role === false}
                                        onChange={() => handleRadioChange1(false)}
                                      />
                                      <RadioButton2 label="Executive" value="2" checked={data.Role === true}
                                        onChange={() => handleRadioChange1(true)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row new_client_form">
                                <div className="col new_client_form">
                                  <div className="new_client_part_1 w-100">
                                    <div className="dashboard_select_field">
                                      <Textarea label="Home Address" placeholder="Enter home address..." rows={4} cols={50} required
                                        value={data.Homeaddress}
                                        onChange={(e) => handleHomeAddressChange(e)}
                                      />
                                      {validationErrors.Homeaddress && <div className="error mt-1">{validationErrors.Homeaddress}</div>}
                                    </div>
                                  </div>
                                </div>
                                <div className="col ">
                                  <div className="new_client_part_1 w-100">
                                    <div className="dashboard_select_field">
                                      <Textarea label=" Remarks" placeholder="Enter Here..." rows={4} cols={50}
                                        value={data.Remarks}
                                        onChange={(e) => handleRemorkChange(e)}
                                      />
                                      {validationErrors.Remarks && <div className="error mt-1">{validationErrors.Remarks}</div>}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {activeStep === 1 && (
                            <div className="client_panel_list d-flex align-items-start">
                              <table className="client_panel_list_table user-checkbox" cellPadding="0" cellSpacing="0">
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th></th>
                                    <th>
                                      <input type="checkbox"
                                        checked={areAllChecked("AllowAdd")}
                                        onChange={(e) => handleHeaderCheckboxChange("AllowAdd", e.target.checked)}
                                      />
                                    </th>
                                    <th>
                                      <input type="checkbox"
                                        checked={areAllChecked("AllowUpdate")}
                                        onChange={(e) => handleHeaderCheckboxChange("AllowUpdate", e.target.checked)}
                                      />
                                    </th>
                                    <th>
                                      <input
                                        type="checkbox"
                                        checked={areAllChecked("AllowDelete")}
                                        onChange={(e) => handleHeaderCheckboxChange("AllowDelete", e.target.checked)}
                                      />
                                    </th>
                                    <th>
                                      <input type="checkbox"
                                        checked={areAllChecked("AllowView")}
                                        onChange={(e) => handleHeaderCheckboxChange("AllowView", e.target.checked)}
                                      />
                                    </th>
                                  </tr>
                                  <tr>
                                    <th>Pages</th>
                                    <th>Page ID</th>
                                    <th style={{textAlign:"center"}}>Create</th>
                                    <th style={{textAlign:"center"}}>Update</th>
                                    <th style={{textAlign:"center"}}>Delete</th>
                                    <th style={{textAlign:"center"}}>View</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {  
                                       UserAccessPages.map((col, index) => (
                                    <>
                                      <tr key={index}>
                                        <td>{col.PageName}</td>
                                        <td>{col.PageID}</td>
                                        <td>
                                          <input type="checkbox"
                                            checked={data.UserAccessData[index]?.AllowAdd === "1"}
                                            data={JSON.stringify(data.UserAccessData[index])}
                                            PageID={col.PageID}
                                            onChange={(e) => handleColumnCheckboxChange("AllowAdd", col.PageID, e.target.checked)}
                                          />
                                        </td>
                                        <td>
                                          <input type="checkbox"
                                            checked={data.UserAccessData[index]?.AllowUpdate === "1"}
                                            onChange={(e) => handleColumnCheckboxChange("AllowUpdate", col.PageID, e.target.checked)}
                                          />
                                        </td>
                                        <td>
                                          <input type="checkbox"
                                            checked={data.UserAccessData[index]?.AllowDelete === "1"}
                                            onChange={(e) => handleColumnCheckboxChange("AllowDelete", col.PageID, e.target.checked)}
                                          />
                                        </td>
                                        <td>
                                          <input type="checkbox"
                                            checked={data.UserAccessData[index]?.AllowView === "1"}
                                            onChange={(e) => handleColumnCheckboxChange("AllowView", col.PageID, e.target.checked)}
                                          />
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>

                        <div className="btn_save d-flex justify-content-end mt-5">
                          {activeStep !== 1 && (
                            <button type='reset' className="tab1 save_button me-4" onClick={() => setButtonClicked('reset')}>
                              <img src="../img/clockwise.svg" />
                              Reset
                            </button>
                          )}
                          {activeStep !== 0 && (
                            <button className="tab1 save_button back" onClick={handleBack}>  <img src="../img/Back.svg" /> Back </button>
                          )}
                          <button type='submit' className="tab1 save_button me-4" disabled={disabledBtn} onClick={() => setButtonClicked('submit')}>
                            {activeStep === steps.length - 1 ? (
                              <>
                                <img src="../img/Save.svg" alt="Save" />
                                <span>{id === undefined ? 'Save' : 'Update'}</span>
                                {LoaderShow2 && (
                                  <LoaderComponent2 />
                                )}
                              </>
                            ) : (
                              'Next'
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </>
                </div>
              </Box>
            </div>
          ) : (
            <NoPermission />
          )}
        </>
      )}
    </Dashboard>
  );
};

export default User_entry;
