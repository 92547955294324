// Sidebar.js
import React, { useContext, useEffect, useState } from 'react';
import { BsChevronCompactDown } from 'react-icons/bs';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { BiSolidUser } from 'react-icons/bi';
import { HiOutlineMenu } from 'react-icons/hi'; // Import the mobile icon
import { Link, useNavigate } from 'react-router-dom';
import SidebarContext from '../../SidebarProvider';
import { MdLogout } from "react-icons/md";

// import { HiOutlineChevronDown } from 'react-icons/hi';

const Sidebar = () => {
  const navigate = useNavigate();
  const { isSidebarOpen, toggleSidebar, isSidebarOpen2, toggleSidebar2, isSidebarOpen3, toggleSidebar3, isSidebarOpen4, toggleSidebar4 } = useContext(SidebarContext);
  const [activeMenu, setActiveMenu] = useState(null)

  const toggleDropdown1 = () => {
    setActiveMenu(activeMenu === 0);

    const menuElement = document.getElementById('menu1'); // Get the menu element by index
    if (menuElement) {
      menuElement.classList.toggle('active'); // Toggle the 'active' class
    }
  };
  const toggleDropdown2 = () => {
    setActiveMenu(activeMenu === 1);
    const menuElement = document.getElementById('menu2'); // Get the menu element by index
    if (menuElement) {
      menuElement.classList.toggle('active'); // Toggle the 'active' class
    }
  };
  const toggleDropdown3 = () => {
    setActiveMenu(activeMenu === 1);
    const menuElement = document.getElementById('menu2'); // Get the menu element by index
    if (menuElement) {
      menuElement.classList.toggle('active'); // Toggle the 'active' class
    }
  };

  const logout = async () => {
    await new Promise(resolve => setTimeout(resolve, 500));
    toggleSidebar4(false);
    toggleSidebar3(false);
    toggleSidebar2(false);
    toggleSidebar(false);
    localStorage.clear();
    navigate('/');

  };
  return (
    <>
      <aside className='p-3'>
        <h2>My activity</h2>
        <ul className='ps-0'>
          <li id='menu1'
            className={`menu_item ${isSidebarOpen ? 'active' : ''}`}
          >
            <div className='menu_text' onClick={toggleSidebar}>
              <div className='main_part_menu m1'>
                <img src={`../img/${isSidebarOpen ? 'user_white' : 'user_side'}.svg`} alt="User" />
                Users
              </div>
              <HiOutlineChevronDown />
            </div>
            <ul className="submenu p-0">
              <Link to='/all-department'><li>Department </li></Link>
              <Link to='/all-designation'><li> Designation </li></Link>
              <Link to='/all-users'><li> Users </li></Link>
            </ul>
          </li>

          <li id='menu2' className={`menu_item ${isSidebarOpen2 ? 'active' : ''}`}>
            <div className='menu_text' onClick={toggleSidebar2}>
              <div className='main_part_menu m2'>
                <img src={`../img/${isSidebarOpen2 ? 'people_white' : 'people'}.svg`} alt="People" />
                Clients
              </div>
              <HiOutlineChevronDown />
            </div>
            <ul className="submenu p-0">
              <Link to='/client_panel'><li>New clients </li></Link>
            </ul>
          </li>
          {/* <li id='menu2' className={`menu_item ${isSidebarOpen3 ? 'active' : ''}`}>
            <div className='menu_text' onClick={toggleSidebar3}>
              <div className='main_part_menu m2'>
                <img src={`../img/${isSidebarOpen3 ? 'people_white' : 'people'}.svg`} alt="People" />
                Salary
              </div>
              <HiOutlineChevronDown />
            </div>
            <ul className="submenu p-0">
              <Link to='/salary-entry'><li>New salary </li></Link>
            </ul>
          </li> */}
          <li id='menu2' className={`menu_item ${isSidebarOpen4 ? 'active' : ''}`}>
            <div className='menu_text' onClick={toggleSidebar4}>
              <div className='main_part_menu m2'>
                <img src={`../img/${isSidebarOpen4 ? 'people_white' : 'people'}.svg`} alt="People" />

                <Link to='/professionalTax-data'> Professional <br /> Tax</Link>
              </div>
              {/* <HiOutlineChevronDown /> */}
            </div>
            {/* <ul className="submenu p-0"> */}

            {/* </ul> */}
          </li>
        </ul>
        <div className="logout-btn">
          <button className="logout-btn" onClick={logout}>
            <div className="logout-svg">
              <MdLogout />
            </div>
            Logout
          </button>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;

// {/* <li
//             className={`menu_item ${isDropdownActive(2)}`}
//             onClick={() => toggleDropdown(2)}
//           >
//             <div className='menu_text'>
//               <div className='main_part_menu m3'>
//                 <img src={`../img/${isDropdownActive(2) ? 'money_white' : 'money'}.svg`} alt="People" />
//                 Payroll
//               </div>
//               <HiOutlineChevronDown />
//             </div>
//             {activeMenu === 2 && (
//               <ul className="submenu">
//                 <li><Link to='/designation_list'>Option 1</Link></li>
//                 <li><Link to='/department_list'>Option 2</Link></li>
//                 <li><Link to=''>Option 3</Link></li>
//               </ul>
//             )}
//           </li> */}
// {/* <li
//             className={`menu_item ${isDropdownActive(3)}`}
//             onClick={() => toggleDropdown(3)}
//           >
//             <div className='menu_text'>
//               <div className='main_part_menu m4'>
//                 <img src={`../img/${isDropdownActive(3) ? 'report_white' : 'report'}.svg`} alt="People" />
//                 Reports
//               </div>
//               <HiOutlineChevronDown />
//             </div>
//             {activeMenu === 3 && (
//               <ul className="submenu">
//                 <li><Link to=''>Option 1</Link></li>
//                 <li><Link to=''>Option 2</Link></li>
//                 <li><Link to=''>Option 3</Link></li>
//               </ul>
//             )}
//           </li> */}
// {/* Repeat the same pattern for the other menu items */ }




// import React, { useState } from 'react';
// import { HiOutlineChevronDown } from 'react-icons/hi';
// import { Link, useNavigate } from 'react-router-dom';

// const Sidebar = () => {
//   const [activeMenu, setActiveMenu] = useState(null);
//   const navigate = useNavigate();

//   const toggleDropdown = (index) => {
//     if (activeMenu === index) {
//       // If the clicked submenu is already open, close it
//       setActiveMenu(null);
//     } else {
//       // If a different submenu is clicked, close the currently open submenu (if any) and open the clicked submenu
//       setActiveMenu(index);
//     }
//   };

//   const isDropdownActive = (index) => {
//     return activeMenu === index ? 'active' : '';
//   };

//   function logout() {
//     localStorage.clear();
//     navigate('/');
//   }

//   return (
//     <aside>
//       <h2>My activity</h2>
//       <ul>
//         <li className={`menu_item ${isDropdownActive(0)}`} onClick={() => toggleDropdown(0)}>
//           <div className='menu_text'>
//             <div className='main_part_menu m1'>
//               Users
//               <HiOutlineChevronDown />
//             </div>
//           </div>
//           {activeMenu === 0 && (
//             <ul className="submenu">
//               <Link to='/all-department'><li>Department</li></Link>
//               <Link to='/all-designation'><li>Designation</li></Link>
//               <Link to='/all-users'><li>Users</li></Link>
//             </ul>
//           )}
//         </li>
//         <li className={`menu_item ${isDropdownActive(1)}`} onClick={() => toggleDropdown(1)}>
//           <div className='menu_text'>
//             <div className='main_part_menu m2'>
//               Clients
//               <HiOutlineChevronDown />
//             </div>
//           </div>
//           {activeMenu === 1 && (
//             <ul className="submenu">
//               <Link to='/client_panel'><li>New clients</li></Link>
//             </ul>
//           )}
//         </li>
//       </ul>
//       <div className='text-center logout-btn'>
//         <button className='btn-sm btn-primary btn-p' onClick={logout}>Logout</button>
//       </div>
//     </aside>
//   );
// };

// export default Sidebar;

