// App.js
import React, { useState, useEffect, useRef } from 'react';
import Dashboard from '../components/dashboard.js';
import { AiFillPlusCircle } from 'react-icons/ai';
import { Helmet } from 'react-helmet';
import {
    SalaryComponent_entry_get,
    User_PageAccess,
    SalaryComponentConfigurationSave,
    DeleteSalaryComponent_by_id
} from '../service/api.js';
import { Link, useNavigate } from 'react-router-dom';
import CustomPagination from '../components/CustomPagination.js';
import ErrorSnackbar from './../components/ErrorSnackbar.js';
import SuccessSnackbar from './../components/SuccessSnackbar.js';
import EditActionBtn from '../components/EditActionBtn.js';
import DeleteActionBtn from '../components/DeleteActionBtn.js';
import Swal from 'sweetalert2';
import NoPermission from '../components/NoPermission.js';
import LoaderComponent from '../components/LoaderComponent.js';

const SalaryData = () => {
    const [LoaderShow, setLoaderShow] = useState(true)
    var searchData = '';
    var currentPageItem = 1;
    var recordPerPageItem = 10;
    const [columns, setColumns] = useState([]);
    const [desi_list, setDesiList] = useState([]);
    const [recordPerPage, setRecordPerPage] = useState(10);
    const [search, setSearch] = useState('');
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [draggedColumn, setDraggedColumn] = useState(null);
    const [isColumnChooserDropdownOpen, setIsColumnChooserDropdownOpen] = useState(false);
    const [ExceptionError, setExceptionError] = useState([]);
    const [successMessages, setSuccessMessages] = useState([]);
    const navigate = useNavigate();
    const [ViewAccesss, setViewAccess] = useState(true);
    const [AllAccess, setAllAccessData] = useState({})

    const isAllowUpdate = () => {
        return AllAccess.AllowUpdate === "1" && AllAccess.AllowView === "1";
    };

    const isAllowDelete = () => {
        return AllAccess.AllowDelete === "1" && AllAccess.AllowView === "1";
    };

    const isAllowAdd = () => {
        return AllAccess.AllowAdd === "1";
    };

    const shouldRenderTh = AllAccess.AllowView === "1" && AllAccess.AllowDelete === "0" && AllAccess.AllowUpdate === "0";

    const isEffectExecutedRef = useRef(false);

    useEffect(() => {
        if (!isEffectExecutedRef.current) {
            PageAccess()
            isEffectExecutedRef.current = true;
        }
    }, []);

    const PageAccess = async () => {
        try {
            const res = await User_PageAccess("7");
            if (res.status == 200) {
                if (res.data.AllowView === true) {
                    fetchData()
                } else {
                    setLoaderShow(false)
                }
                const accessData = {
                    AllowView: res.data.AllowView ? "1" : "0",
                    AllowAdd: res.data.AllowAdd ? "1" : "0",
                    AllowDelete: res.data.AllowDelete ? "1" : "0",
                    AllowUpdate: res.data.AllowUpdate ? "1" : "0"
                };
                setViewAccess(res.data.AllowView)
                setAllAccessData(accessData)

            } else if (res.status === 400) {
                if (res.data.ErrorCode) {
                    const validationErrorMessage = res.data.ErrorMessage;
                    const errorMessagesArray = validationErrorMessage.split(', ');

                    errorMessagesArray.forEach(errorMessage => {
                        const [, fieldName, errorMessageText] = errorMessage.match(/\"(.*?)\" (.*)/);
                        handleExceptionError(`${fieldName} - ${errorMessageText}`);
                    });
                }
                if (res.data.Message) {
                    handleExceptionError(res.data.Message);
                }
            } else if (res.status === 401) {

                handleExceptionError('Unauthorized');
                setTimeout(() => {
                    localStorage.clear();
                    navigate('/')
                }, 1000);
                ///logout();
            }
            else if (res.status === 200) {
                var dataError = res.data.Errors;
                dataError.map((message, index) => {
                    handleExceptionError(message.Message);

                })
            }
            else if (res.status === 500) {
                handleExceptionError(res.statusText);
            }
        } catch (error) {
            handleExceptionError(error.message);
        }
        finally {
            // setLoaderShow(false);
        }
    }

    function handleExceptionError(res) {
        setExceptionError(ExceptionError => [
            ...ExceptionError,
            { id: Date.now(), message: res },
        ]);
    }
    function handleExceptionSuccessMessages(resp) {
        setSuccessMessages(successMessages => [
            ...successMessages,
            { id: Date.now(), message: resp },
        ]);
    }
    function clearErrors(id) {
        setExceptionError(prevMessages =>
            prevMessages.filter(msg => msg.id !== id)
        );
    }
    function clearSuccess(id) {
        setSuccessMessages(prevMessages =>
            prevMessages.filter(msg => msg.id !== id)
        );
    }
    const handleDragStart = (e, index) => {
        setDraggedColumn(index);
    };

    const handleDragOver = (e, index) => {
        e.preventDefault();
    };

    const handleDrop = (e, index) => {
        e.preventDefault();
        const newColumns = [...columns];
        const draggedItem = newColumns[draggedColumn];
        newColumns.splice(draggedColumn, 1);
        newColumns.splice(index, 0, draggedItem);
        setColumns(newColumns);
        setDraggedColumn(null);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        currentPageItem = page;
        fetchData();
    };

    const fetchData = async () => {
        try {
            var dataObject = {
                'filter': searchData,
                'page': currentPageItem,
                'limit': recordPerPageItem,
            }
            const res = await SalaryComponent_entry_get(dataObject);
            if (res) {
                if (res.status === 200) {
                    var logConfig = res.data.SalaryComponentsLogConfig;
                    var logColumns = res.data.SalaryComponentsLogColumns;
                    var newColumnArray = [];
                    if (logConfig && logConfig.length > 0) {
                        logColumns.forEach(column => {
                            if (column.Status === "Config") {
                                let configMatch = logConfig.find(config => config.ColumnName === column.ColumnName);
                                if (configMatch) {
                                    column.IsActive = configMatch.IsActive;
                                    column.Priority = configMatch.Priority;
                                    if (configMatch.Alias) {
                                        column.Alias = configMatch.Alias;
                                    }
                                }
                                newColumnArray.push({ ...column });
                                newColumnArray = sortByPriority(newColumnArray);
                            }
                        });
                    } else {
                        logColumns.forEach(data => {
                            if (data.Status === "Config") {
                                data.IsActive = true
                                newColumnArray.push(data);
                            }
                        });
                    }
                    setDesiList(res.data.data);
                    setTotalPage(res.data.totalAllData);
                    setColumns(newColumnArray);
                    setLoaderShow(false)
                } else if (res.status === 400) {

                    handleExceptionError(res.data.ErrorMessage);
                } else if (res.status === 401) {

                    handleExceptionError('Unauthorized');
                    setTimeout(() => {
                        localStorage.clear();
                        navigate('/')
                    }, 1000);
                    ///logout();
                } else if (res.status === 500) {
                    res = "Request failed with status code 500";
                    handleExceptionError(res);
                }
            } else {
                res = "Request failed with status code 500";
                handleExceptionError(res);
            }
        } catch (error) {
            handleExceptionError(error.message);
        }
    };

    const sortByPriority = (dataArray) => {
        return dataArray.sort((a, b) => {
            return parseInt(a.Priority) - parseInt(b.Priority);
        });
    };
    const handleSalaryChooserChange = (index) => {
        const updatedColumns = [...columns];
        updatedColumns[index].IsActive = !updatedColumns[index].IsActive;
        setColumns(updatedColumns); // Check if this line is causing an infinite loop
    };

    const handleSearchBox = (event) => {
        setSearch(event.target.value);
        searchData = event.target.value;
        fetchData();
    }

    const handleConfigurationSave = async () => {
        var newData = [];
        columns.map((columnData, index) => {
            var oj = {
                ColumnName: columnData.ColumnName,
                Priority: (index + 1),
                IsActive: columnData.IsActive,
            }
            newData.push(oj);
        });
        try {
            const res = await SalaryComponentConfigurationSave(newData);
            if (res.status == 200) {
                handleExceptionSuccessMessages(res.data.message);
                setIsColumnChooserDropdownOpen(false);

            } else if (res.status === 401) {
                handleExceptionError('Unauthorized');
                setTimeout(() => {
                    localStorage.clear();
                    navigate('/')
                }, 1000);
                ///logout();
            } else {
                handleExceptionError(res);
            }
        } catch (error) {
            handleExceptionError(error.message);
        }
    }
    const handleItemsPerPageChange = (e) => {
        const newItemsPerPage = parseInt(e.target.value);
        setRecordPerPage(newItemsPerPage);
        recordPerPageItem = newItemsPerPage
        fetchData();
    };

    const DeleteSalaryComponent = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data!',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await DeleteSalaryComponent_by_id(id);
                    if (res) {
                        if (res.status === 200) {
                            handleExceptionSuccessMessages(res.data.message);
                            fetchData();
                            // PageAccess()
                        } else if (res.status === 400) {
                            if (res.data.ErrorCode) {
                                handleExceptionError(res.data.ErrorMessage);
                            }
                            if (res.data.Message) {
                                handleExceptionError(res.data.Message);
                            }
                        } else if (res.status === 401) {

                            handleExceptionError('Unauthorized');
                            setTimeout(() => {
                                localStorage.clear();
                                navigate('/')
                            }, 1000);
                            ///logout();
                        } else if (res.status === 500) {
                            handleExceptionError(res.statusText);
                        }
                    }
                } catch (error) {
                    handleExceptionError(error.message);
                }
            }
        });
    }

    return (
        <Dashboard title={"Salary Components"}>
            <ErrorSnackbar errorMessages={ExceptionError} onClearErrors={clearErrors} />
            <SuccessSnackbar successMessages={successMessages} onclearSuccess={clearSuccess} />
            <Helmet>
                <title>Salary Components List | J mehta</title>
            </Helmet>
            {LoaderShow ? (
                <LoaderComponent />
            ) : (
                <>
                    <div className='client_panel_menu new_client_title'>
                        {ViewAccesss && (
                            <div className='d-flex align-items-center me-auto'>
                                <div className='client_panel_search client_panel_option'>
                                    <input type="text" value={search} onChange={handleSearchBox} placeholder='Search' />
                                </div>
                                <div className='d-flex'>
                                    <div className='client_panel_column_chooser'>
                                        <button onClick={() => setIsColumnChooserDropdownOpen(!isColumnChooserDropdownOpen)}>
                                            <img src="./img/right.svg" alt="Column Chooser" />
                                            Column Chooser
                                        </button>

                                        <ul className={`dropdown-menu ${isColumnChooserDropdownOpen ? 'open' : ''}`}>
                                            {columns.map((col, index) => (
                                                <li key={`${index}-${col.Alias}`}>
                                                    <label className='label_main'>{col.Alias}
                                                        <input
                                                            type="checkbox"
                                                            checked={col.IsActive}
                                                            onChange={() => handleSalaryChooserChange(index)}
                                                        />
                                                        <span className="geekmark"> </span>
                                                    </label>
                                                </li>
                                            ))}
                                            <li>
                                                <button type='button' onClick={handleConfigurationSave} className='btn btn-theme-primary'>Save</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )}
                        {isAllowAdd() ? (
                            <div className='client_panel_new ms-auto'>
                                <Link to='/salary-entry'><button className='border-0'><AiFillPlusCircle />New</button></Link>
                            </div>
                        ) : (
                            <div className='d-none'>
                            </div>
                        )}
                    </div>

                    {ViewAccesss ? (
                        <div className='overflow-hidden'>
                            <div className='client_panel_list d-flex align-items-start scroll-container'>
                                <table className='client_panel_list_table mb-4' cellPadding="0" cellSpacing="0">
                                    <tr>
                                        <th>Sr. No.</th>
                                        <>
                                            <th style={{ display: shouldRenderTh ? 'none' : 'table-cell' }}>
                                                Action
                                            </th>
                                        </>
                                        {columns.map((col, index) => (
                                            col.IsActive && (
                                                <th
                                                    key={col.Alias}
                                                    draggable
                                                    onDragStart={(e) => handleDragStart(e, index)}
                                                    onDragOver={(e) => handleDragOver(e, index)}
                                                    onDrop={(e) => handleDrop(e, index)}
                                                >
                                                    {col.Alias}
                                                </th>
                                            )
                                        ))}
                                    </tr>
                                    {desi_list.map((row, rowIndex) => (
                                        <tr key={row.id}>
                                            <td>{(currentPage - 1) * recordPerPage + rowIndex + 1}</td>
                                            <td key={rowIndex} style={{ display: shouldRenderTh ? 'none' : 'table-cell' }}>
                                                <>
                                                    <div className='d-flex'>
                                                        {isAllowUpdate() && <EditActionBtn to={`/salary-entry/${row['ID']}`} />}
                                                        {isAllowDelete() && <DeleteActionBtn onClickHandle={() => DeleteSalaryComponent(row['ID'])} id={row['ID']} />}
                                                    </div>
                                                </>
                                            </td>
                                            {columns.map((col) => {
                                                let cellContent = null;
                                                switch (col.ColumnName) {
                                                    case 'SalaryComponentsID':
                                                        cellContent = row['ID'];
                                                        break;
                                                    case 'ComponentName':
                                                        cellContent = row['ComponentName'];
                                                        break;
                                                    case 'ComponentShortName':
                                                        cellContent = row['ComponentShortName'];
                                                        break;
                                                    case 'AddedOn':
                                                        const AddedOnDate = new Date(row['AddedOn']);
                                                        const AddedOnDay = String(AddedOnDate.getDate()).padStart(2, '0');
                                                        const AddedOnMonth = String(AddedOnDate.getMonth() + 1).padStart(2, '0');
                                                        const AddedOnYear = AddedOnDate.getFullYear();
                                                        cellContent = `${AddedOnDay}-${AddedOnMonth}-${AddedOnYear}`;
                                                        break;
                                                    case 'AddedBy':
                                                        cellContent = row['AddedBy'];
                                                        break;
                                                    case 'LastModifiedOn':
                                                        const LastModifiedOnDate = new Date(row['LastModifiedOn']);
                                                        const LastModifiedOnDay = String(LastModifiedOnDate.getDate()).padStart(2, '0');
                                                        const LastModifiedOnMonth = String(LastModifiedOnDate.getMonth() + 1).padStart(2, '0');
                                                        const LastModifiedOnYear = LastModifiedOnDate.getFullYear();
                                                        cellContent = `${LastModifiedOnDay}-${LastModifiedOnMonth}-${LastModifiedOnYear}`;
                                                        break;
                                                    case 'LastModifiedBy':
                                                        cellContent = row['LastModifiedBy'];
                                                        break;
                                                    default:
                                                        break;
                                                }
                                                return col.IsActive && (
                                                    <td key={`${row.id}-${col.Alias}`}>{cellContent}</td>
                                                );
                                            })}
                                        </tr>
                                    ))}
                                </table>
                            </div>
                            <>
                                <CustomPagination totalItems={totalPage} itemsPerPage={recordPerPage} currentPage={currentPage}
                                    onPageChange={handlePageChange} onPageSelect={handleItemsPerPageChange} />
                            </>
                        </div>
                    ) : (
                        <NoPermission />
                    )}
                </>
            )}
        </Dashboard>
    );
};

export default SalaryData;