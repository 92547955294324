import React, { useState } from 'react';

// Sample data for demonstration
const sampleData = [
  { id: 1, name: 'John', age: 30, email: 'john@example.com' },
  { id: 2, name: 'Doe', age: 25, email: 'doe@example.com' },
  // More sample data...
];

// Main App component
const App = () => {
  const [columns, setColumns] = useState([
    { key: 'name', label: 'Name' },
    { key: 'age', label: 'Age' },
    { key: 'email', label: 'Email' },
  ]);

  const [draggedColumn, setDraggedColumn] = useState(null);

  const handleDragStart = (e, index) => {
    setDraggedColumn(index);
    console.log(index)
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    const newColumns = [...columns];
    const draggedItem = newColumns[draggedColumn];
    newColumns.splice(draggedColumn, 1);
    newColumns.splice(index, 0, draggedItem);
    setColumns(newColumns);
    setDraggedColumn(null);
  };

  return (
    <div>
      <h2>Draggable Column Table</h2>
      <table>
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th
                key={col.key}
                draggable
                onDragStart={(e) => handleDragStart(e, index)}
                onDragOver={(e) => handleDragOver(e, index)}
                onDrop={(e) => handleDrop(e, index)}
              >
                {col.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sampleData.map((row, rowIndex) => (
            <tr key={row.id}>
              {columns.map((col) => (
                <td key={col.key}>{row[col.key]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default App;
