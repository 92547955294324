import React from 'react';
import { TailSpin } from 'react-loader-spinner';

const LoaderComponent2 = () => {
    return (
        <TailSpin
            visible={true}
            height={24}
            width={24}
            color="#fff"
            ariaLabel="tail-spin-loading"
            radius={1}
            wrapperStyle={{
                position: 'relative',
                justifyContent: 'center',
                alignItems: 'center'
            }}
            wrapperClass=""
        />
    );
}

export default LoaderComponent2;
