// App.js
import React, { useState, useEffect } from 'react';
import Dashboard from '../components/dashboard.js';
import { AiFillCloseCircle } from 'react-icons/ai';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import Input2 from '../components/parts/input2.js';
import Select from '../components/parts/select.js';
import ImageUpload from '../components/parts/imagefile.js';
import { Helmet } from 'react-helmet';


const Employee_entry = () => {
  const [activeTab, setActiveTab] = useState('tab1'); // State to keep track of active tab

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const [isEPFApplicable, setIsEPFApplicable] = useState(false);
  const [isESICApplicable, setIsESICApplicable] = useState(false);
  const [isPTApplicable, setIsPTApplicable] = useState(false);
  const [selectedLeader, setSelectedLeader] = useState('');

  const [selectedExecutive, setSelectedExecutive] = useState('');

  const handleLeaderChange = event => {
    setSelectedLeader(event.target.value);
  };

  const handleExecutiveChange = event => {
    setSelectedExecutive(event.target.value);
  };


  /***********mouse sroll******************** */
  useEffect(() => {
    const container = document.getElementById('scrollable-container');

    const handleWheel = (event) => {
      container.scrollLeft += event.deltaY;
      event.preventDefault();
    };

    container.addEventListener('wheel', handleWheel);

    return () => {
      container.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const [data, setData] = useState({
    Name: '',
    DOB: '',
    Maritial: '',
    DateofJoining: '',
    DateofAnniversary: '',
    MobileNumber1: '',
    MobileNumber2: '',
    EmailID: '',
  });
  const [validationErrors, setValidationErrors] = useState({
    Name: '',
    DOB: '',
    Maritial: '',
    DateofJoining: '',
    DateofAnniversary: '',
    MobileNumber1: '',
    MobileNumber2: '',
    EmailID: '',
  });
  const [data3, setData3] = useState({
    Name: '',
    Relation: '',
    MobileNumber: '',
  });
  const [validationErrors3, setValidationErrors3] = useState({
    Name: '',
    Relation: '',
    MobileNumber: '',
  });
  const [data4, setData4] = useState({
    Aadhar: '',
    PAN: '',
    UAN: '',
    ESIC: '',
    ProfessionalTax: '',
    EmployeeProvidendFund: '',
  });
  const [validationErrors4, setValidationErrors4] = useState({
    Aadhar: '',
    PAN: '',
    UAN: '',
    ESIC: '',
    ProfessionalTax: '',
    EmployeeProvidendFund: '',
  });
  const [options, setOptions] = useState([
    "option 1",
    "option 2",
    "option 3",
    // Add more options as needed
  ]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = {};
    if (data.Name.trim() === '') {
      errors.Name = 'Name is required.';
    }
    if (data.DOB.trim() === '') {
      errors.DOB = 'DOB is required.';
    }
    if (data.Maritial.trim() === '') {
      errors.Maritial = 'Maritial is required.';
    }
    if (data.DateofJoining.trim() === '') {
      errors.DateofJoining = 'DateofJoining is required.';
    }
    if (data.DateofAnniversary.trim() === '') {
      errors.DateofAnniversary = 'DateofAnniversary is required.';
    }
    if (data.MobileNumber1.trim() === '') {
      errors.MobileNumber1 = 'MobileNumber1 is required.';
    }
    if (data.MobileNumber2.trim() === '') {
      errors.MobileNumber2 = 'MobileNumber2 is required.';
    }
    if (data.EmailID.trim() === '') {
      errors.EmailID = 'EmailID is required.';
    }
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    setValidationErrors({});

  };
  const handleSubmit3 = async (event) => {
    event.preventDefault();
    const errors = {};
    if (data3.Name.trim() === '') {
      errors.Name = 'Name is required.';
    }
    if (data3.Relation.trim() === '') {
      errors.Relation = 'Relation is required.';
    }
    if (data3.MobileNumber.trim() === '') {
      errors.MobileNumber = 'MobileNumber is required.';
    }
    if (Object.keys(errors).length > 0) {
      setValidationErrors3(errors);
      return;
    }
    setValidationErrors3({});
  };
  const handleSubmit4 = async (event) => {
    event.preventDefault();
    const errors = {};
    if (data4.Aadhar.trim() === '') {
      errors.Aadhar = 'Aadhar Card Number is required.';
    }
    if (data4.PAN.trim() === '') {
      errors.PAN = 'PAN is required.';
    }
    if (data4.UAN.trim() === '') {
      errors.UAN = 'UAN is required.';
    }
    if (data4.ESIC.trim() === '') {
      errors.ESIC = 'ESIC is required.';
    }
    if (data4.EmployeeProvidendFund.trim() === '') {
      errors.EmployeeProvidendFund = 'Employee Providend Fund is required.';
    }
    if (data4.ProfessionalTax.trim() === '') {
      errors.ProfessionalTax = 'Professional Tax is required.';
    }
    if (Object.keys(errors).length > 0) {
      setValidationErrors4(errors);
      return;
    }
    setValidationErrors4({});
  };
  const handleSubmit5 = async (event) => {
    event.preventDefault();
    const errors = {};
    if (data3.Name.trim() === '') {
      errors.Name = 'Name is required.';
    }
    if (data3.Relation.trim() === '') {
      errors.Relation = 'Relation is required.';
    }
    if (data3.MobileNumber.trim() === '') {
      errors.MobileNumber = 'MobileNumber is required.';
    }
    if (Object.keys(errors).length > 0) {
      setValidationErrors3(errors);
      return;
    }
    setValidationErrors3({});
  };
  const handleSubmit6 = async (event) => {
    event.preventDefault();
    const errors = {};
    if (data3.Name.trim() === '') {
      errors.Name = 'Name is required.';
    }
    if (data3.Relation.trim() === '') {
      errors.Relation = 'Relation is required.';
    }
    if (data3.MobileNumber.trim() === '') {
      errors.MobileNumber = 'MobileNumber is required.';
    }
    if (Object.keys(errors).length > 0) {
      setValidationErrors3(errors);
      return;
    }
    setValidationErrors3({});
  };
  const handleSubmit7 = async (event) => {
    event.preventDefault();
    const errors = {};
    if (data3.Name.trim() === '') {
      errors.Name = 'Name is required.';
    }
    if (data3.Relation.trim() === '') {
      errors.Relation = 'Relation is required.';
    }
    if (data3.MobileNumber.trim() === '') {
      errors.MobileNumber = 'MobileNumber is required.';
    }
    if (Object.keys(errors).length > 0) {
      setValidationErrors3(errors);
      return;
    }
    setValidationErrors3({});
  };
  /*******************************************/

  return (
    <Dashboard>
      <Helmet>
        <title>Employee Entry | J mehta</title>
      </Helmet>
      {/* Your main page content goes here */}
      <div className="new_client_title">
        <h2>New Employee</h2>
        <button>
          <AiFillCloseCircle /> Close
        </button>
      </div>
      <div className="new_client_content_wrapper w-100">
        <div className="new_client_menu">
          <div className="scrollable-container" id="scrollable-container">
            <ul>
              <li
                className={activeTab === "tab1" ? "active" : ""}
                onClick={() => handleTabClick("tab1")}
              >
                Personal Details
              </li>
              <li
                className={activeTab === "tab2" ? "active" : ""}
                onClick={() => handleTabClick("tab2")}
              >
                Family Details
              </li> 
              <li
                className={activeTab === "tab3" ? "active" : ""}
                onClick = { () => handleTabClick("tab3") }
              >
                Emergency Contact Details
              </li>
              <li
                className={activeTab === "tab4" ? "active" : ""}
                onClick={() => handleTabClick("tab4")}
              >
                Government Related Details
              </li>
              <li
                className={activeTab === "tab5" ? "active" : ""}
                onClick={() => handleTabClick("tab5")}
              >
                Salary Details
              </li>
              <li
                className={activeTab === "tab6" ? "active" : ""}
                onClick={() => handleTabClick("tab6")}
              >
                Document Details
              </li>
              <li
                className={activeTab === "tab7" ? "active" : ""}
                onClick={() => handleTabClick("tab7")}
              >
                Banking Details
              </li>
            </ul>
          </div>
        </div>
        {/* <form> */}
        {activeTab === "tab1" && (
          <>
            <form onSubmit={handleSubmit}>

              <div className="row">
                <div className="col-xl-12">
                  <div className="new_client_form" id="tab1">
                    <div className="new_client_part_1">
                      <Input2
                        placeholder="Enter Here"
                        label="Name (Exact As per AADHAR CARD)"
                        value={data.Name}
                        onChange={(value) => {
                          setData((prevData) => ({ ...prevData, Name: value }));
                          setValidationErrors((prevErrors) => ({ ...prevErrors, Name: '' }));
                        }}
                      />
                      {validationErrors.Name && <div className="error">{validationErrors.Name}</div>}
                      <Input2
                        placeholder="Enter Here"
                        label="Maritial Status"
                        value={data.Maritial}
                        onChange={(value) => {
                          setData((prevData) => ({ ...prevData, Maritial: value }));
                          setValidationErrors((prevErrors) => ({ ...prevErrors, Maritial: '' }));
                        }}
                      />
                      {validationErrors.Maritial && <div className="error">{validationErrors.Maritial}</div>}

                      <Input2
                        placeholder="Enter Here"
                        label="Date of Anniversary"
                        type="date"
                        value={data.DateofAnniversary}
                        onChange={(value) => {
                          setData((prevData) => ({ ...prevData, DateofAnniversary: value }));
                          setValidationErrors((prevErrors) => ({ ...prevErrors, DateofAnniversary: '' }));
                        }}
                      />
                      {validationErrors.DateofAnniversary && <div className="error">{validationErrors.DateofAnniversary}</div>}

                      <Input2
                        placeholder="Enter Here"
                        label="Mobile Number 1"
                        value={data.MobileNumber1}
                        onChange={(value) => {
                          setData((prevData) => ({ ...prevData, MobileNumber1: value }));
                          setValidationErrors((prevErrors) => ({ ...prevErrors, MobileNumber1: '' }));
                        }}
                      />
                      {validationErrors.MobileNumber1 && <div className="error">{validationErrors.MobileNumber1}</div>}

                      <Input2
                        placeholder="Enter Here"
                        label="Email ID"
                        value={data.EmailID}
                        onChange={(value) => {
                          setData((prevData) => ({ ...prevData, EmailID: value }));
                          setValidationErrors((prevErrors) => ({ ...prevErrors, EmailID: '' }));
                        }}
                      />
                      {validationErrors.EmailID && <div className="error">{validationErrors.EmailID}</div>}

                    </div>
                    <div className="new_client_part_1">
                      <Select
                        label="Team Leader"
                        options={options}
                        value={selectedLeader} // Use an appropriate state to manage the selected value
                        onChange={handleLeaderChange} // Implement the change handler function
                      />
                      <Input2
                        placeholder="Enter Here"
                        label="Date of Birth"
                        type="date"
                        value={data.DOB}
                        onChange={(value) => {
                          setData((prevData) => ({ ...prevData, DOB: value }));
                          setValidationErrors((prevErrors) => ({ ...prevErrors, DOB: '' }));
                        }}
                      />
                      {validationErrors.DOB && <div className="error">{validationErrors.DOB}</div>}

                      <Input2
                        placeholder="Select Your State"
                        type="date"
                        label="Date of Joining"
                        value={data.DateofJoining}
                        onChange={(value) => {
                          setData((prevData) => ({ ...prevData, DateofJoining: value }));
                          setValidationErrors((prevErrors) => ({ ...prevErrors, DateofJoining: '' }));
                        }}
                      />
                      {validationErrors.DateofJoining && <div className="error">{validationErrors.DateofJoining}</div>}

                      <Input2
                        placeholder="Enter Here"
                        label="Mobile Number 2"
                        value={data.MobileNumber2}
                        onChange={(value) => {
                          setData((prevData) => ({ ...prevData, MobileNumber2: value }));
                          setValidationErrors((prevErrors) => ({ ...prevErrors, MobileNumber2: '' }));
                        }}
                      />
                      {validationErrors.MobileNumber2 && <div className="error">{validationErrors.MobileNumber2}</div>}


                      <ImageUpload label="Upload Profile Image" />
                    </div>
                  </div>
                  <div className="btn_save btn-right">
                    <button className="tab1 save_button" type="submit">
                      <img src="./img/Save.svg" />
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
        {activeTab === "tab2" && (

          <div className='client_panel_list'>
            <table className='client_panel_list_table emp_entry_tbl' cellPadding="0" cellSpacing="0">

              <tr>
                <th>Select Relation</th>
                <th>Name</th>
                <th>Mobile No</th>
                <th>Email ID</th>
                <th>Date of Birth</th>
                <th>Aadhar Card Number</th>
              </tr>
              <tr>
                <td><input type="text" placeholder="Enter here" className="tbl_enter" /></td>
                <td><input type="text" placeholder="Enter here" className="tbl_enter" /></td>
                <td><input type="text" placeholder="Enter here" className="tbl_enter" /></td>
                <td><input type="text" placeholder="Enter here" className="tbl_enter" /></td>
                <td><input type="text" placeholder="Enter here" className="tbl_enter" /></td>
                <td><input type="tel" pattern="[0-9]" placeholder="Enter here" className="tbl_enter" /></td>
              </tr>


            </table>
            <div className="btn_save mt-4">
              <button className="tab1 button_transparent ">
                Add Another
                <AiOutlinePlusCircle />
              </button>
            </div>
            <div className="btn_save btn-right">
              <button className="tab1 save_button" type="submit">
                <img src="./img/Save.svg" />
                Save
              </button>
            </div>
          </div>
        )}
        {activeTab === "tab3" && (
          <>
            <form onSubmit={handleSubmit3}>
              <div className="new_client_form" id="tab3">
                <div className="new_client_part_1">
                  <Input2 placeholder="Enter Here" label="Name"
                    value={data3.Name}
                    onChange={(value) => {
                      setData3((prevData) => ({ ...prevData, Name: value }));
                      setValidationErrors((prevErrors) => ({ ...prevErrors, Name: '' }));
                    }} />
                  {validationErrors3.Name && <div className="error">{validationErrors3.Name}</div>}


                  <Input2 placeholder="Enter Here" label="Mobile Number"
                    value={data3.MobileNumber}
                    onChange={(value) => {
                      setData3((prevData) => ({ ...prevData, MobileNumber: value }));
                      setValidationErrors((prevErrors) => ({ ...prevErrors, Relation: '' }));
                    }} />
                  {validationErrors3.MobileNumber && <div className="error">{validationErrors3.MobileNumber}</div>}

                </div>
                <div className="new_client_part_1">
                  <Input2 placeholder="Enter Here" label="Relation"
                    value={data3.Relation}
                    onChange={(value) => {
                      setData3((prevData) => ({ ...prevData, Relation: value }));
                      setValidationErrors((prevErrors) => ({ ...prevErrors, MobileNumber: '' }));
                    }} />
                  {validationErrors3.Relation && <div className="error">{validationErrors3.Relation}</div>}

                </div>
              </div>
              <div className="btn_save btn-right">
                <button className="tab1 save_button" type='submit'>
                  <img src="./img/Save.svg" />
                  Save
                </button>
              </div>
            </form>
          </>
        )}
        {activeTab === "tab4" && (
          <>
            <form onSubmit={handleSubmit4}>
              <div className="new_client_form" id="tab4">
                <div className="new_client_part_1">
                  <Input2
                    placeholder="Enter Here"
                    label="Aadhar Card Number"
                    value={data4.Aadhar}
                    onChange={(value) => {
                      setData4((prevData) => ({ ...prevData, Aadhar: value }));
                      setValidationErrors4((prevErrors) => ({ ...prevErrors, Aadhar: '' }));
                    }}
                  />
                  {validationErrors4.Aadhar && <div className="error">{validationErrors4.Aadhar}</div>}

                </div>

                <div className="new_client_part_1">
                  <Input2
                    placeholder="Enter Here"
                    label="Personal Account Number (PAN)"
                    value={data4.PAN}
                    onChange={(value) => {
                      setData4((prevData) => ({ ...prevData, PAN: value }));
                      setValidationErrors4((prevErrors) => ({ ...prevErrors, PAN: '' }));
                    }}
                  />
                  {validationErrors4.PAN && <div className="error">{validationErrors4.PAN}</div>}
                </div>
              </div>
              <div className="new_client_form" id="tab2">
                <div className="new_client_part_1">
                  <Input2 placeholder="Enter Here" label="UAN" value={data4.UAN}
                    onChange={(value) => {
                      setData4((prevData) => ({ ...prevData, UAN: value }));
                      setValidationErrors4((prevErrors) => ({ ...prevErrors, UAN: '' }));
                    }} />
                  {validationErrors4.UAN && <div className="error">{validationErrors4.UAN}</div>}
                </div>
                <div className="new_client_part_1">
                  <div className="toggle_switch_btn gst">
                    <h3>Is Professional Tax Applicable?</h3>
                    <label class="switch">
                      <input type="checkbox" checked={isPTApplicable}
                        onChange={() => setIsPTApplicable(!isPTApplicable)}/>
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <Input2 placeholder="Enter Your GST Number" value={data4.ProfessionalTax}
                    onChange={(value) => {
                      setData4((prevData) => ({ ...prevData, ProfessionalTax: value }));
                      setValidationErrors4((prevErrors) => ({ ...prevErrors, ProfessionalTax: '' }));
                    }} 
                    disabled={!isPTApplicable}
                    style={{ opacity: isPTApplicable ? 1 : 0.5 }}/>
                  {validationErrors4.ProfessionalTax && <div className="error">{validationErrors4.ProfessionalTax}</div>}
                </div>
              </div>
              <div className="new_client_form" id="tab2">
                <div className="new_client_part_1">
                  <div className="toggle_switch_btn gst">
                    <h3>Is ESIC Applicable?</h3>
                    <label class="switch">
                      <input type="checkbox" checked={isESICApplicable}
                        onChange={() => setIsESICApplicable(!isESICApplicable)}/>
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <Input2 placeholder="Enter Your GST Number" value={data4.ESIC}
                    onChange={(value) => {
                      setData4((prevData) => ({ ...prevData, ESIC: value }));
                      setValidationErrors4((prevErrors) => ({ ...prevErrors, ESIC: '' }));
                    }} disabled={!isESICApplicable}
                    style={{ opacity: isESICApplicable ? 1 : 0.5 }}/>
                  {validationErrors4.ESIC && <div className="error">{validationErrors4.ESIC}</div>}
                </div>
                <div className="new_client_part_1">
                  <div className="toggle_switch_btn gst">
                    <h3>Is Employee Providend Fund Applicable?</h3>
                    <label class="switch">
                      <input type="checkbox" checked={isEPFApplicable}
                        onChange={() => setIsEPFApplicable(!isEPFApplicable)}/>
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <Input2 placeholder="Enter Your GST Number" value={data4.EmployeeProvidendFund}
                    onChange={(value) => {
                      setData4((prevData) => ({ ...prevData, EmployeeProvidendFund: value }));
                      setValidationErrors4((prevErrors) => ({ ...prevErrors, EmployeeProvidendFund: '' }));
                    }} disabled={!isEPFApplicable}
                    style={{ opacity: isEPFApplicable ? 1 : 0.5 }}/>
                  {validationErrors4.EmployeeProvidendFund && <div className="error">{validationErrors4.EmployeeProvidendFund}</div>}                  </div>
              </div>

              <div className="btn_save btn-right">
                <button className="tab1 save_button" type="submit">
                  <img src="./img/Save.svg" />
                  Save
                </button>
              </div>
            </form>
          </>
        )}
        {activeTab === "tab5" && (
          <>
            <form onSubmit={handleSubmit5}>
              <div className="client_tab_title">
                <h2>Assign Team Member</h2>
              </div>
              <div className="new_client_form margin-space" id="tab4">
                <div className="new_client_part_1">
                  <Select
                    label="Team Leader"
                    options={options}
                    value={selectedLeader} // Use an appropriate state to manage the selected value
                    onChange={handleLeaderChange} // Implement the change handler function
                  />
                </div>
                <div className="new_client_part_1">
                  <Select
                    label="Executive"
                    options={options}
                    value={selectedExecutive} // Use an appropriate state to manage the selected value
                    onChange={handleExecutiveChange} // Implement the change handler function
                  />
                </div>
              </div>
              <div className="btn_save">
                <button className="tab1 save_button">
                  <img src="./img/Save.svg" />
                  Save
                </button>
              </div>
            </form>
          </>
        )}
        {activeTab === "tab6" && (
          <>
            <form onSubmit={handleSubmit6}>
              <div className="new_client_form margin-space d-block" id="tab6">
                <div className="new_client_form align-items-center" id="tab4">
                  <div className="new_client_part_1">
                    <Input2 placeholder="Enter Here" label="Document Name" />
                  </div>
                  <div className="new_client_part_1">
                    <button className="tab1 save_button upload-btn mt-3">
                      <img src="./img/upload-svg.svg" />
                      Upload
                    </button>
                  </div>
                </div>
                <div className="row ">
                  <div className="col new_client_form">
                    <div className="new_client_part_1">
                      <div class="dashboard_input_feild">
                        <h3>Uploaded Documents</h3>
                      </div>
                      <div className="row ">
                        <div className="col-xl-7">
                          <div className="uploaded_files">
                            <ul>
                              <li>
                                <div className="d-flex justify-content-between">
                                  <span className="file_name">
                                    Aadhar Card
                                  </span>
                                  <span>
                                    <img src="./img/check-svg.svg" />
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div className="d-flex justify-content-between">
                                  <span className="file_name">PAN Card</span>
                                  <span>
                                    <img src="./img/check-svg.svg" />
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div className="d-flex justify-content-between">
                                  <span className="file_name">L.C</span>
                                  <span>
                                    <img src="./img/check-svg.svg" />
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="btn_save btn-right">
                <button className="tab1 save_button" type="submit">
                  <img src="./img/Save.svg" />
                  Save
                </button>
              </div>
            </form>
          </>
        )}
        {activeTab === "tab7" && (
          <>
            <form onSubmit={handleSubmit7}>
              <div className="new_client_form margin-space d-block" id="tab7">
                <div className="row ">
                  <div className="col new_client_form">
                    <div className="new_client_part_1">
                      <Input2 placeholder="Enter Here" label="Bank Name" />
                    </div>
                  </div>
                  <div className="col new_client_form">
                    <div className="new_client_part_1">
                      <Input2 placeholder="Enter Here" label="IFS Code" />
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col new_client_form">
                    <div className="new_client_part_1">
                      <Input2 placeholder="Enter Here" label="Branch Name" />
                    </div>
                  </div>
                  <div className="col new_client_form">
                    <div className="new_client_part_1">
                      <Input2 placeholder="Enter Here" label="Account Name" />
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col new_client_form">
                    <div className="new_client_part_1">
                      <Input2
                        placeholder="Enter Here"
                        label="Account Number"
                      />
                    </div>
                  </div>
                  <div className="col new_client_form">
                    <div className="new_client_part_1">
                      <Input2 placeholder="Enter Here" label="Account Name" />
                    </div>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col new_client_form">
                    <div className="new_client_part_1">
                      <div className="toggle_switch_btn gst">
                        <h3>Active</h3>
                        <label class="switch">
                          <input type="checkbox" />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-xl-3">
                    <div className="uploaded_files">
                      <ul>
                        <li>
                          <div className="d-flex justify-content-between">
                            <span className="file_name">Bank Of Baroda</span>
                            <span>
                              <img src="./img/check-svg.svg" />
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex justify-content-between">
                            <span className="file_name">Central Bank of India</span>
                            <span>
                              <img src="./img/check-svg.svg" />
                            </span>
                          </div>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn_save btn-right">
                <button className="tab1 save_button" type="submit">
                  <img src="./img/Save.svg" />
                  Save
                </button>
              </div>
            </form>
          </>
        )}
        {/* </form> */}
      </div>
    </Dashboard>
  );
};
export default Employee_entry;