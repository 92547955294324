// Client.js
import React, { useState, useEffect, useRef } from 'react';
import Dashboard from '../components/dashboard.js';
import { AiFillPlusCircle } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ErrorSnackbar from '../components/ErrorSnackbar.js';
import SuccessSnackbar from '../components/SuccessSnackbar.js';
import { decryption, encryption } from '../components/utils/utils.js';
import { CiSquarePlus } from "react-icons/ci";
import { IoEyeOutline } from "react-icons/io5";
import Modal from 'react-modal';

import {
    Client_get,
    Client_configiration_update,
    Delete_Client_by_id,
    Client_Location_get,
    Delete_ClientLocation_by_id,
    User_PageAccess,
} from '../service/api.js';
import EditActionBtn from '../components/EditActionBtn.js';
import DeleteActionBtn from '../components/DeleteActionBtn.js';
import Swal from 'sweetalert2';
import CustomPagination from '../components/CustomPagination.js';
import NoPermission from '../components/NoPermission.js';
import LoaderComponent from '../components/LoaderComponent.js';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        minWidth: '70%',
    },
    overlay: {
        marginLeft: '17.8vw'
    }
};


const Client_panel = () => {
    const [Client_Location_list, setClientLocationList] = useState([]);

    const [data2, setData2] = useState([]);
    const [filter2, setFilter2] = useState([]);

    // const [recordPerPage0, setRecordPerPage0] = useState(5);
    const [currentPage0, setCurrentPage0] = useState(1)
    // const recordsPerPage = 5;
    const [recordsPerPage, setRecordsPerPage] = useState(5);
    const lastIndex = currentPage0 * recordsPerPage
    const firstIndex = lastIndex - recordsPerPage
    const records = filter2.slice(firstIndex, lastIndex)
    const npage = Math.ceil(filter2.length / recordsPerPage)
    const numbers = [...Array(npage + 1).keys()].slice(1)


    const requestSearch = (searchedVal) => {
        const filteredRows = data2.filter((row) => {
            return row.LocationName.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
        });
        if (searchedVal.length < 1) {
            setFilter2(data2)
        }
        else {
            setFilter2(filteredRows)
        }
    };

    const [LoaderShow, setLoaderShow] = useState(true)

    var searchData = '';
    var currentPageItem = 1;
    var recordPerPageItem = 10;

    const [recordPerPage, setRecordPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);

    const [ExceptionError, setExceptionError] = useState([]);
    const [successMessages, setSuccessMessages] = useState([]);
    const [columns, setColumns] = useState([]);
    const navigate = useNavigate();
    const [draggedColumn, setDraggedColumn] = useState(null);
    const [Client_list, setClientList] = useState([]);

    const [isOptionsDropdownOpen, setIsOptionsDropdownOpen] = useState(false);
    const [isColumnChooserDropdownOpen, setIsColumnChooserDropdownOpen] = useState(false);
    const [setsrno, setSetsrno] = useState(true);
    const [ClientName, setClientName] = useState(true);
    const [Scheduledateofdata, setScheduledateofdata] = useState(true);
    const [received, setreceived] = useState(true);
    const [Delay, setDelay] = useState(true);

    const [search, setSearch] = useState('');
    const [modalIsOpen, setIsOpen] = React.useState(false);

    const [ViewAccessClient, setViewAccessClient] = useState(true);
    const [AllAccessClient, setAllAccessDataClient] = useState({})

    const [AllAccessClientLocation, setAllAccessDataClientLocation] = useState({})

    const isAllowUpdate = () => {
        return AllAccessClient.AllowUpdate === "1" && AllAccessClient.AllowView === "1";
    };
    const isAllowUpdateLocation = () => {
        return AllAccessClientLocation.AllowUpdate === "1" && AllAccessClientLocation.AllowView === "1";
    };

    const isAllowDelete = () => {
        return AllAccessClient.AllowDelete === "1" && AllAccessClient.AllowView === "1";
    };
    const isAllowDeleteLocation = () => {
        return AllAccessClientLocation.AllowDelete === "1" && AllAccessClientLocation.AllowView === "1";
    };

    const isAllowAdd = () => {
        return AllAccessClient.AllowAdd === "1";
    };
    const isAllowAddLocation = () => {
        return AllAccessClientLocation.AllowAdd === "1";
    }

    const isAllowViewLocation = () => {
        return AllAccessClientLocation.AllowView === "1";
    };

    const shouldRenderTh = AllAccessClient.AllowView === "1" && AllAccessClient.AllowDelete === "0" && AllAccessClient.AllowUpdate === "0";

    const isEffectExecutedRef = useRef(false)
    useEffect(() => {
        // // fetchData()
        if (!isEffectExecutedRef.current) {
            PageAccess();
            PageAccessLocation()
            isEffectExecutedRef.current = true
        }
        // fetchDataModal()
    }, []);

    const PageAccess = async () => {
        try {
            const res = await User_PageAccess("4");
            if (res.status == 200) {
                if (res.data.AllowView === true) {
                    fetchData()
                } else {
                    setLoaderShow(false)
                }
                const accessData = {
                    AllowView: res.data.AllowView ? "1" : "0",
                    AllowAdd: res.data.AllowAdd ? "1" : "0",
                    AllowDelete: res.data.AllowDelete ? "1" : "0",
                    AllowUpdate: res.data.AllowUpdate ? "1" : "0"
                };
                setViewAccessClient(res.data.AllowView)
                setAllAccessDataClient(accessData)

            } else if (res.status === 400) {
                if (res.data.ErrorCode) {
                    const validationErrorMessage = res.data.ErrorMessage;
                    const errorMessagesArray = validationErrorMessage.split(', ');
                    errorMessagesArray.forEach(errorMessage => {
                        const [, fieldName, errorMessageText] = errorMessage.match(/\"(.*?)\" (.*)/);
                        handleExceptionError(`${fieldName} - ${errorMessageText}`);
                    });
                }
                if (res.data.Message) {
                    handleExceptionError(res.data.Message);
                }
            } else if (res.status === 401) {

                handleExceptionError('Unauthorized');
                setTimeout(() => {
                    localStorage.clear();
                    navigate('/')
                }, 1000);
                ///logout();
            }
            else if (res.status === 200) {
                var dataError = res.data.Errors;
                dataError.map((message, index) => {
                    handleExceptionError(message.Message);
                })
            }
            else if (res.status === 500) {
                handleExceptionError(res.statusText);
            }
        } catch (error) {
            handleExceptionError(error.message);
        }
        finally {
            // setLoading(false);
        }
    }

    const handleCheckboxChange = (event) => {
        setSetsrno(event.target.checked);
    };

    function handleExceptionError(res) {
        setExceptionError(ExceptionError => [
            ...ExceptionError,
            { id: Date.now(), message: res },
        ]);
    }
    function handleExceptionSuccessMessages(resp) {
        setSuccessMessages(successMessages => [
            ...successMessages,
            { id: Date.now(), message: resp },
        ]);
    }

    function clearErrors(id) {
        setExceptionError(prevMessages =>
            prevMessages.filter(msg => msg.id !== id)
        );
    }
    function clearSuccess(id) {
        setSuccessMessages(prevMessages =>
            prevMessages.filter(msg => msg.id !== id)
        );
    }

    const handleCheckBoxChange = (index) => {
        const updatedColumns = [...columns];
        updatedColumns[index].IsActive = !updatedColumns[index].IsActive;
        setColumns(updatedColumns);
    };
    const handleDragStart = (e, index) => {
        setDraggedColumn(index);
    };
    const handleDragOver = (e, index) => {
        e.preventDefault();
    };

    const handleDrop = (e, index) => {
        e.preventDefault();
        const newColumns = [...columns];
        const draggedItem = newColumns[draggedColumn];
        newColumns.splice(draggedColumn, 1);
        newColumns.splice(index, 0, draggedItem);
        setColumns(newColumns);
        setDraggedColumn(null);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        currentPageItem = page;
        fetchData();
    };

    const handleItemsPerPageChange = (e) => {
        const newItemsPerPage = parseInt(e.target.value);
        setRecordPerPage(newItemsPerPage);
        recordPerPageItem = newItemsPerPage
        fetchData();
    };
    const sortByPriority = (dataArray) => {
        return dataArray.sort((a, b) => {
            return parseInt(a.Priority) - parseInt(b.Priority);
        });
    };

    const handleConfigurationSave = async () => {
        var newData = [];
        columns.map((columnData, index) => {
            var oj = {
                ColumnName: columnData.ColumnName,
                Priority: (index + 1),
                IsActive: columnData.IsActive,
            }
            newData.push(oj);
        });
        try {
            const res = await Client_configiration_update(newData);
            if (res.status == 200) {
                var message = 'Client Setting updated Successfully'
                handleExceptionSuccessMessages(message);
                setIsColumnChooserDropdownOpen(false);

            } else if (res.status === 401) {

                handleExceptionError('Unauthorized');
                setTimeout(() => {
                    localStorage.clear();
                    navigate('/')
                }, 1000);
                ///logout();
            } else {
                handleExceptionError(res.statusText);
            }
        } catch (error) {
            var res = "Request failed with status code 500";
            handleExceptionError(res);
        }

    }
    const DeleteClient = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data!',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await Delete_Client_by_id(id);
                    if (res) {
                        if (res.status === 200) {
                            fetchData();
                            handleExceptionSuccessMessages(res.data.message);
                        } else if (res.status === 400) {
                            if (res.data.ErrorCode) {
                                handleExceptionError(res.data.ErrorMessage);
                            }
                            if (res.data.Message) {
                                handleExceptionError(res.data.Message);
                            }
                        } else if (res.status === 401) {
                            handleExceptionError('Unauthorized');
                            setTimeout(() => {
                                localStorage.clear();
                                navigate('/')
                            }, 1000);
                            ///logout();
                        } else if (res.status === 500) {
                            console.log(res)
                            handleExceptionError(res.statusText);
                        }
                    }
                } catch (error) {
                    handleExceptionError(error.message);
                }

            }
        });
    }
    const DeleteClientLocation = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data!',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await Delete_ClientLocation_by_id(id);
                    if (res) {
                        if (res.status === 200) {
                            setIsOpen(false);
                            fetchDataModal();
                            handleExceptionSuccessMessages(res.data.message);
                        } else if (res.status === 400) {
                            if (res.data.ErrorCode) {
                                handleExceptionError(res.data.ErrorMessage);
                            }
                            if (res.data.Message) {
                                handleExceptionError(res.data.Message);
                            }
                        } else if (res.status === 401) {
                            handleExceptionError('Unauthorized');
                            setTimeout(() => {
                                localStorage.clear();
                                navigate('/')
                            }, 1000);
                            ///logout();
                        } else if (res.status === 500) {
                            handleExceptionError(res.statusText);
                        }
                    }
                } catch (error) {
                    handleExceptionError(error.message);
                }
            }
        });
    }

    const fetchData = async () => {
        try {
            var dataObject = {
                'filter': searchData,
                'page': currentPageItem,
                'limit': recordPerPageItem,
            }
            const res = await Client_get(dataObject);
            if (res) {
                if (res.status === 200) {
                    var logConfig = res.data.ClientLogConfig;
                    var logColumns = res.data.ClientLogColumns;
                    var newColumnArray = [];
                    if (logConfig && logConfig.length > 0) {
                        logColumns.forEach(column => {
                            if (column.Status === "Config") {
                                let configMatch = logConfig.find(config => config.ColumnName === column.ColumnName);
                                if (configMatch) {
                                    column.IsActive = configMatch.IsActive;
                                    column.Priority = configMatch.Priority;
                                    if (configMatch.Alias) {
                                        column.Alias = configMatch.Alias;
                                    }
                                }
                                newColumnArray.push({ ...column });
                                newColumnArray = sortByPriority(newColumnArray);
                            }
                        });
                        newColumnArray = sortByPriority(newColumnArray);
                    } else {
                        logColumns.forEach(data => {
                            if (data.Status === "Config") {
                                data.IsActive = true
                                newColumnArray.push(data);
                            }
                        });
                    }
                    setClientList(res.data.data);
                    setTotalPage(res.data.totalAllData);
                    setColumns(newColumnArray);
                    setLoaderShow(false)
                    // setReason(false);
                } else if (res.status === 400) {
                    handleExceptionError(res.data.ErrorMessage);
                    setLoaderShow(false)
                } else if (res.status === 401) {

                    handleExceptionError('Unauthorized');
                    setLoaderShow(false)
                    setTimeout(() => {
                        localStorage.clear();
                        navigate('/')
                    }, 1000);
                    ///logout();
                } else if (res.status === 500) {
                    handleExceptionError(res.data.errorMessage);
                    setLoaderShow(false)
                }
            } else {
                res = "Request failed with status code 500";
                handleExceptionError(res);
            }
        } catch (error) {
            handleExceptionError(error.message);
        }
    };

    const handleClientNameChange = (event) => {
        setClientName(event.target.checked);
    };
    const handleScheduleChange = (event) => {
        setScheduledateofdata(event.target.checked);
    };
    const handlereceivedChange = (event) => {
        setreceived(event.target.checked);
    };
    const handleDelayChange = (event) => {
        setDelay(event.target.checked);
    };

    function openModal(id) {
        setIsOpen(true);
        fetchDataModal(id)
        // PageAccessLocation(id)
    }
    const PageAccessLocation = async () => {
        try {
            const res = await User_PageAccess("5");
            if (res.status == 200) {
                // if (res.data.AllowView === true) {
                //     fetchDataModal()
                // }
                const accessData = {
                    AllowView: res.data.AllowView ? "1" : "0",
                    AllowAdd: res.data.AllowAdd ? "1" : "0",
                    AllowDelete: res.data.AllowDelete ? "1" : "0",
                    AllowUpdate: res.data.AllowUpdate ? "1" : "0"
                };
                setAllAccessDataClientLocation(accessData)

            } else if (res.status === 400) {
                if (res.data.ErrorCode) {
                    const validationErrorMessage = res.data.ErrorMessage;
                    const errorMessagesArray = validationErrorMessage.split(', ');
                    errorMessagesArray.forEach(errorMessage => {
                        const [, fieldName, errorMessageText] = errorMessage.match(/\"(.*?)\" (.*)/);
                        handleExceptionError(`${fieldName} - ${errorMessageText}`);
                    });
                }
                if (res.data.Message) {
                    handleExceptionError(res.data.Message);
                }
            } else if (res.status === 401) {

                handleExceptionError('Unauthorized');
                setTimeout(() => {
                    localStorage.clear();
                    navigate('/')
                }, 1000);
                ///logout();
            }
            else if (res.status === 200) {
                var dataError = res.data.Errors;
                dataError.map((message, index) => {
                    handleExceptionError(message.Message);
                })
            }
            else if (res.status === 500) {
                handleExceptionError(res.statusText);
            }
        } catch (error) {
            handleExceptionError(error.message);
        }
        finally {
            // setLoading(false);
        }
    }

    const fetchDataModal = async (id) => {
        setLoaderShow(true)
        try {
            const res = await Client_Location_get(id);
            if (res.status === 200) {
                const LocationData = res.data.ClientLocation;
                setClientLocationList(LocationData);
                setData2(LocationData);
                setFilter2(LocationData)
                setLoaderShow(false)

            } else if (res.status === 400) {
                handleExceptionError(res.data.ErrorMessage);
            } else if (res.status === 401) {
                handleExceptionError('Unauthorized');
                setLoaderShow(false)
                setTimeout(() => {
                    localStorage.clear();
                    navigate('/');
                }, 1000);
            } else if (res.status === 500) {
                handleExceptionError(res.data.errorMessage);
                setLoaderShow(false)
            }
        } catch (error) {
            handleExceptionError(error.message);
        }
    };

    let subtitle;
    function afterOpenModal() {
        subtitle.style.color = '#333';
        subtitle.style.fontFamily = 'Geometria';
        subtitle.style.fontSize = '1.59vw';
        subtitle.style.fontWeight = '500';
    }

    function closeModal() {
        setIsOpen(false);
    }

    // const handleSearchBox = (event) => {
    //     console.log(event.target.value)
    //     setSearch(event.target.value);
    //     searchData = event.target.value;
    //     fetchData();
    // }
    const handleSearchBox = (event) => {
        const value = event.target.value;
        setSearch(value);
        searchData = value;
        fetchData();
    }


    return (
        <>
            <Dashboard title={"Client List"}>
                <Helmet>
                    <title>Client List | J mehta</title>
                </Helmet>
                <ErrorSnackbar errorMessages={ExceptionError} onClearErrors={clearErrors} />
                <SuccessSnackbar successMessages={successMessages} onclearSuccess={clearSuccess} />

                {LoaderShow ? (
                    <LoaderComponent />
                ) : (
                    <>
                        <div className='client_panel_menu new_client_title'>
                            {ViewAccessClient && (
                                <div className='d-flex align-items-center me-auto'>
                                    <div className='client_panel_search client_panel_option'>
                                        <input type="text" value={search} onChange={handleSearchBox} placeholder='Search' />
                                    </div>
                                    <div className='d-flex'>
                                        <div className='client_panel_option'>
                                            <button onClick={() => setIsOptionsDropdownOpen(!isOptionsDropdownOpen)}><img src="./img/Menu.svg" />Options</button>
                                            <ul className={`dropdown-menu ${isOptionsDropdownOpen ? 'open' : ''}`}>
                                                <li>
                                                    <img src="./img/Editing.svg" alt="Edit" />Edit</li>
                                                <li> <img src="./img/Delete.svg" alt="Delete" />Delete</li>
                                                <li> <img src="./img/Location.svg" alt="Location" />Add a New Location</li>
                                                <li> <img src="./img/Power_Button.svg" alt="Activate" />De-activate & Activate</li>
                                            </ul>
                                        </div>

                                        <div className='client_panel_column_chooser'>

                                            <button onClick={() => setIsColumnChooserDropdownOpen(!isColumnChooserDropdownOpen)}>
                                                <img src="./img/right.svg" alt="Column Chooser" />
                                                Column Chooser
                                            </button>
                                            <ul className={`dropdown-menu ${isColumnChooserDropdownOpen ? 'open' : ''}`}>
                                                {columns.map((col, index) => (
                                                    <li key={`${index}-${col.Alias}`}>
                                                        <label className='label_main'>{col.Alias}
                                                            <input
                                                                type="checkbox"
                                                                checked={col.IsActive}
                                                                onChange={() => handleCheckBoxChange(index)}
                                                            />
                                                            <span className="geekmark"> </span>
                                                        </label>
                                                    </li>
                                                ))}

                                                <li>
                                                    <button type='button' onClick={handleConfigurationSave} className='btn btn-theme-primary'>Save</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {isAllowAdd() ? (
                                <div className='client_panel_new'>
                                    <Link to='/new_client_panel'><button className='border-0'><AiFillPlusCircle />New</button></Link>
                                </div>
                            ) : (
                                <div className='d-none'>
                                </div>
                            )}
                        </div>

                        {ViewAccessClient ? (
                            <div className='overflow-hidden'>
                                <div className='client_panel_list scroll-container'>
                                    <table className='client_panel_list_table mb-4' cellPadding="0" cellSpacing="0">
                                        <tr>
                                            <th>
                                                Sr. No.
                                            </th>
                                            {/* <th width={reason === false ? '10%' : ''}>
                            {reason === false ? 'Action' : 'Reason'}
                        </th> */}
                                            {/* <th>
                                                Action
                                            </th> */}
                                            <th style={{ display: shouldRenderTh ? 'none' : 'table-cell' }}>
                                                Action
                                            </th>
                                            {columns.map((col, index) => (
                                                col.IsActive && (
                                                    <th
                                                        key={col.Alias}
                                                        draggable
                                                        onDragStart={(e) => handleDragStart(e, index)}
                                                        onDragOver={(e) => handleDragOver(e, index)}
                                                        onDrop={(e) => handleDrop(e, index)}
                                                    >
                                                        {col.Alias}
                                                    </th>
                                                )
                                            ))}
                                        </tr>
                                        {/* {reason === false && ( */}
                                        {Client_list.map((row, rowIndex) => (
                                            <tr key={row.id}>
                                                <td>{(currentPage - 1) * recordPerPage + rowIndex + 1}</td>
                                                <td key={rowIndex} style={{ display: shouldRenderTh ? 'none' : 'table-cell' }}>
                                                    <>
                                                        <div className='d-flex'>
                                                            {isAllowViewLocation() && <button className='btn' onClick={() => openModal(row['ID'])} id={row['ID']} > <IoEyeOutline /></button>}
                                                            {isAllowAddLocation() && <button className='btn'><Link to={`/client_location_add/${row['ID']}`}><CiSquarePlus /></Link></button>}
                                                            {isAllowUpdate() && <EditActionBtn to={`/edit-client/${row['ID']}`} />}
                                                            {isAllowDelete() && <DeleteActionBtn onClickHandle={() => DeleteClient(row['ID'])} id={row['ID']} />}
                                                        </div>
                                                    </>
                                                </td>
                                                {/* )} */}
                                                {columns.map((col) => {
                                                    let cellContent = null;
                                                    switch (col.ColumnName) {
                                                        case 'ClientID':
                                                            cellContent = row['ID'];
                                                            break;
                                                        case 'LegalFilingStatus':
                                                            const legalFilingStatusOptions = [
                                                                { value: 0, label: 'PROPRIETORSHIP CONCERN' },
                                                                { value: 1, label: 'PARTNERSHIP CONCERN' },
                                                                { value: 2, label: 'LIMITED LIABILITY PARTNERSHIP' },
                                                                { value: 3, label: 'PRIVATE LIMITED COMPANY' },
                                                                { value: 4, label: 'ONE PERSON COMPANY' }
                                                            ];
                                                            const selectedOption = legalFilingStatusOptions.find(option => option.value === row['LegalFilingStatus']);
                                                            cellContent = selectedOption ? selectedOption.label : '';
                                                            break;
                                                        case 'OrgName':
                                                            cellContent = row['OrgName'];
                                                            break;
                                                        case 'ClientCode':
                                                            cellContent = row['ClientCode'];
                                                            break;
                                                        case 'ClientDBName':
                                                            cellContent = row['ClientDBName'];
                                                            break;
                                                        case 'RegisteredEmailID':
                                                            cellContent = decryption(row['RegisteredEmailID']);
                                                            break;
                                                        case 'RegisterdMobileNo':
                                                            cellContent = row['RegisterdMobileNo'];
                                                            break;
                                                        case 'TLUserID':
                                                            cellContent = row['TLUserID'];
                                                            break;
                                                        case 'EXUserID':
                                                            cellContent = row['EXUserID'];
                                                            break;
                                                        case 'AddedOn':
                                                            const AddedOnDate = new Date(row['AddedOn']);
                                                            const AddedOnDay = String(AddedOnDate.getDate()).padStart(2, '0');
                                                            const AddedOnMonth = String(AddedOnDate.getMonth() + 1).padStart(2, '0');
                                                            const AddedOnYear = AddedOnDate.getFullYear();
                                                            cellContent = `${AddedOnDay}-${AddedOnMonth}-${AddedOnYear}`;
                                                            break;
                                                        case 'AddedBy':
                                                            cellContent = row['AddedBy'];
                                                            break;
                                                        case 'LastModifiedOn':
                                                            const LastModifiedOnDate = new Date(row['LastModifiedOn']);
                                                            const LastModifiedOnDay = String(LastModifiedOnDate.getDate()).padStart(2, '0');
                                                            const LastModifiedOnMonth = String(LastModifiedOnDate.getMonth() + 1).padStart(2, '0');
                                                            const LastModifiedOnYear = LastModifiedOnDate.getFullYear();
                                                            cellContent = `${LastModifiedOnDay}-${LastModifiedOnMonth}-${LastModifiedOnYear}`;
                                                            break;
                                                        case 'LastModifiedBy':
                                                            cellContent = row['LastModifiedBy'];
                                                            break;
                                                        default:
                                                            break;
                                                    }
                                                    // Render the table cell only if IsActive is true
                                                    return col.IsActive && (
                                                        <td key={`${row.id}-${col.Alias}`}>{cellContent}</td>
                                                    );
                                                })}
                                            </tr>
                                        ))}

                                    </table>
                                </div>
                                <>
                                    <CustomPagination totalItems={totalPage} itemsPerPage={recordPerPage} currentPage={currentPage}
                                        onPageChange={handlePageChange} onPageSelect={handleItemsPerPageChange} />
                                </>


                            </div>
                        ) : (
                            <NoPermission />
                        )}
                    </>
                )}
            </Dashboard>
            {LoaderShow ? (
                <LoaderComponent />
            ) : (
                <>
                    <Modal
                        isOpen={modalIsOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        {/* <div className='d-flex'>
                            <h4 className='client_panel_option' ref={(_subtitle) => (subtitle = _subtitle)}>Client Location</h4>
                            <div className='client_panel_search ms-auto'>
                                <input type="text" value={search2} onChange={handleSearchBoxLocation} placeholder='Search' />
                            </div>
                        </div> */}

                        {/* {Client_Location_list.length > 0 ? (
                            <>
                                <table className='client_panel_list_table' cellPadding="0" cellSpacing="0">
                                    <tr>
                                        <th>Action</th>
                                        <th>Pan No</th>
                                        <th>Location</th>
                                        <th>Address 1</th>
                                        <th>Address 2</th>
                                        <th>State</th>
                                        <th>City</th>
                                        <th>Pincode</th>
                                    </tr>
                                    {Client_Location_list.map((data, rowIndex) => (
                                        <tr key={data.id}>
                                            <td key={rowIndex}>
                                                <>
                                                    <div className='d-flex'>
                                                        {isAllowUpdateLocation() && < EditActionBtn to={`/client_location_add/${data['LocationID']}`} />}
                                                        {isAllowDeleteLocation() && <DeleteActionBtn onClickHandle={() => DeleteClientLocation(data['LocationID'])} id={data['LocationID']} />}
                                                    </div>
                                                </>
                                            </td>
                                            <td>{data.PAN}</td>
                                            <td>{data.LocationName}</td>
                                            <td>{data.AddLine1}</td>
                                            <td>{data.AddLine2}</td>
                                            <td>{data.StateName}</td>
                                            <td>{data.CityName}</td>
                                            <td>{data.Pincode}</td>
                                        </tr>
                                    ))}
                                </table>
                            </>
                        ) : (
                            <table className='client_panel_list_table' cellPadding="0" cellSpacing="0">
                                <tr>
                                    <td colSpan="8">No records found</td>
                                </tr>
                            </table>
                        )} */}

                        <div className='d-flex'>
                            <h4 className='client_panel_option' ref={(_subtitle) => (subtitle = _subtitle)}>Client Location</h4>
                            <div className='client_panel_search client_panel_option ms-auto'>
                                <input type="text" onChange={(e) => requestSearch(e.target.value)} placeholder='Search' />
                            </div>
                        </div>


                        {Client_Location_list.length > 0 ? (
                            <div>
                                <table className='client_panel_list_table' cellPadding="0" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th>Action</th>
                                            <th>Location Name</th>
                                            <th>PAN</th>
                                            <th>AddLine1</th>
                                            <th>AddLine2</th>
                                            <th>State Name</th>
                                            <th>City Name</th>
                                            <th>Pincode</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {records.map(item => (
                                            <>
                                                <tr key={item.i}>
                                                    <td key={item}>
                                                        <>
                                                            <div className='d-flex'>
                                                                {isAllowUpdateLocation() && < EditActionBtn to={`/client_location_edit/${item['LocationID']}`} />}
                                                                {isAllowDeleteLocation() && <DeleteActionBtn onClickHandle={() => DeleteClientLocation(item['LocationID'])} id={item['LocationID']} />}
                                                            </div>
                                                        </>
                                                    </td>
                                                    <td>{item.LocationName}</td>
                                                    <td>{item.PAN}</td>
                                                    <td>{item.AddLine1}</td>
                                                    <td>{item.AddLine2}</td>
                                                    <td>{item.StateName}</td>
                                                    <td>{item.CityName}</td>
                                                    <td>{item.Pincode}</td>
                                                </tr>
                                            </>
                                        ))}
                                    </tbody>
                                </table>
                                <nav>
                                    <ul className='pagination justify-content-end mt-4'>
                                        <li className='page-btn me-2'>
                                            <Link className={`page-link ${currentPage0 === 1 ? 'disabled' : ''}`} onClick={prePage}>
                                                <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                            </Link>
                                        </li>
                                        {numbers.map((n, i) => (

                                            <li className={`page-item me-2 ${currentPage0 === n ? 'active' : ''}`} key={i}>
                                                <Link className='page-link' onClick={() => changeCPage(n)}>{n}</Link>
                                            </li>
                                        ))
                                        }
                                        <li className='page-item me-2'>
                                            <Link className={`page-link ${currentPage0 === npage ? 'disabled' : ''}`} onClick={nextPage}>
                                                <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                            </Link>
                                        </li>

                                        <div className='page-link select-page'>
                                            <select className='p-0 m-0 ' onChange={handleRecordsPerPageChange} value={recordsPerPage}>
                                                <option value={5}>5</option>
                                                <option value={10}>10</option>
                                                <option value={50}>50</option>
                                            </select>
                                        </div>

                                    </ul>
                                </nav>
                            </div>
                        ) : (
                            <table className='client_panel_list_table' cellPadding="0" cellSpacing="0">
                                <tr>
                                    <td colSpan="8">No records found</td>
                                </tr>
                            </table>
                        )}

                        <div className='mt-3'>
                            <button className="tab1 me-0 save_button back ms-auto" onClick={closeModal} ><img src="../img/Back.svg" /> Close </button>
                        </div>
                    </Modal>
                </>
            )}
        </>
    );
    function handleRecordsPerPageChange(e) {
        const newRecordsPerPage = parseInt(e.target.value);
        setCurrentPage0(1); // Reset to first page when changing records per page
        // Update recordsPerPage using useState setter function
        setRecordsPerPage(newRecordsPerPage);
    }


    function prePage() {
        if (currentPage0 !== 1) {
            setCurrentPage0(currentPage0 - 1)
        }

    }
    function changeCPage(dd) {
        setCurrentPage0(dd)

    }
    function nextPage() {
        if (currentPage0 !== npage) {
            setCurrentPage0(currentPage0 + 1)
        }
    }
};

export default Client_panel;