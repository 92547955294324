// New_Client_Add
import React, { useEffect, useRef, useState } from 'react';
import Dashboard from '../components/dashboard.js';
import { AiFillCloseCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import Input2 from '../components/parts/input2.js';
import { Helmet } from 'react-helmet';
import Select from '../components/parts/select.js';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CheckBoxDuplicateRecord, City_get, Client_entry, State_get, User_PageAccess } from '../service/api.js';
import { Client_edit } from '../service/api.js';
import ErrorSnackbar from '../components/ErrorSnackbar.js';
import SuccessSnackbar from '../components/SuccessSnackbar.js';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Autocomplete, TextField } from '@mui/material';
import { Client_get_by_id } from '../service/api.js';
import { decryption, encryption } from '../components/utils/utils.js';
import saveIcon from '../Save.svg';
import NoPermission from '../components/NoPermission.js';
import DatePicker from "react-datepicker"
import 'react-datepicker/dist/react-datepicker.css';
import LoaderComponent from '../components/LoaderComponent.js';
import LoaderComponent2 from '../components/LoaderComponent2.js';
import { FaEyeSlash, FaRegEye } from 'react-icons/fa';

const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4'];

export default function Client_additional_bus() {
  const [LoaderShow, setLoaderShow] = useState(true)
  const [LoaderShow2, setLoaderShow2] = useState(false)

  const { id } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [buttonClicked, setButtonClicked] = useState('');
  const [disabledBtn, setDisabledBtn] = useState(false);

  const [ExceptionError, setExceptionError] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);
  const [selectedLeader, setSelectedLeader] = useState('');
  const [selectedLegalFilingStatus, setSelectedLegalFilingStatus] = useState([]);
  const navigate = useNavigate();
  const [selectedExecutive, setSelectedExecutive] = useState('');
  const [options, setOptions] = useState([
    "option 1",
    "option 2",
    "option 3",
  ]);

  const [data, setData] = useState({
    OrgName: "",
    RegisteredEmailID: "",
    LocationName: "",
    AddLine2: "",
    CityID: "",
    LegalFilingStatus: "",
    RegisterdMobileNo: "",
    AddLine1: "",
    StateID: "",
    Pincode: "",

    PAN: "",
    IsGSTApplicable: true,
    GSTIN: "",
    IsPFApplicable: true,
    PFNumber: "",
    PFPassword: "",
    PFMobileNo: "",
    PFUsername: "",
    PFEmailID: "",

    IsESICApplicable: true,
    ESICNumber: "",
    ESICPassword: "",
    ESICMobileNo: "",
    ESICUsername: "",
    ESICEmailID: "",

    IsPTApplicable: true,
    PTNumber: "",
    PTEmployerNo: "",
    PTEmployeeNo: "",

    IsFactoryLicenseApplicable: true,
    FactoryLicenseNo: "",
    EndDate: "",
    StartDate: "",

    FirstName: "",
    LastName: "",
    MobileNo: "",
    Address: "",

    TLUserID: 0,
    EXUserID: 0,

    ClientPOCDetData: []

  });

  const [validationErrors, setValidationErrors] = useState({
    OrgName: "",
    RegisteredEmailID: "",
    LocationName: "",
    AddLine2: "",
    CityID: "",
    LegalFilingStatus: "",
    RegisterdMobileNo: "",
    AddLine1: "",
    StateID: "",
    Pincode: "",

    PAN: "",
    IsGSTApplicable: '',
    GSTIN: "",
    IsPFApplicable: '',
    PFNumber: "",
    PFPassword: "",
    PFMobileNo: "",
    PFUsername: "",
    PFEmailID: "",

    IsESICApplicable: '',
    ESICNumber: "",
    ESICPassword: "",
    ESICMobileNo: "",
    ESICUsername: "",
    ESICEmailID: "",

    IsPTApplicable: '',
    PTNumber: "",
    PTEmployerNo: "",
    PTEmployeeNo: "",

    IsFactoryLicenseApplicable: '',
    FactoryLicenseNo: "",
    EndDate: "",
    StartDate: "",

    FirstName: "",
    LastName: "",
    MobileNo: "",
    Address: "",

    TLUserID: '',
    EXUserID: '',

    ClientPOCDetData: []
  });

  const legalFilingStatusOptions = [
    { value: '0', label: 'PROPRIETORSHIP CONCERN' },
    { value: '1', label: 'PARTNERSHIP CONCERN' },
    { value: '2', label: 'LIMITED LIABILITY PARTNERSHIP' },
    { value: '3', label: 'PRIVATE LIMITED COMPANY' },
    { value: '4', label: 'ONE PERSON COMPANY' }
  ];

  const [ClientAccess, setClientAccess] = useState(false)

  const isEffectExecutedRef = useRef(false)
  useEffect(() => {
    if (!isEffectExecutedRef.current) {
      PageAccess()
      fetchStates()
      isEffectExecutedRef.current = true
    }
  }, []);

  const PageAccess = async () => {
    try {
      const res = await User_PageAccess("4");
      if (res.status == 200) {
        if (id ? res.data.AllowUpdate === true : res.data.AllowAdd === true) {
          setClientAccess(true)
          if (id) {
            api_get();
          }
          setLoaderShow(false)
        }
        setLoaderShow(false)


      } else if (res.status === 400) {
        if (res.data.ErrorCode) {
          const validationErrorMessage = res.data.ErrorMessage;
          const errorMessagesArray = validationErrorMessage.split(', ');

          errorMessagesArray.forEach(errorMessage => {
            const [, fieldName, errorMessageText] = errorMessage.match(/\"(.*?)\" (.*)/);
            handleExceptionError(`${fieldName} - ${errorMessageText}`);
            setLoaderShow(false)
          });
        }
        if (res.data.Message) {
          handleExceptionError(res.data.Message);
          setLoaderShow(false)
        }
      } else if (res.status === 401) {

        handleExceptionError('Unauthorized');
        setLoaderShow(false)
        setTimeout(() => {
          localStorage.clear();
          navigate('/')
        }, 1000);
        ///logout();
      }
      else if (res.status === 200) {
        var dataError = res.data.Errors;
        dataError.map((message, index) => {
          handleExceptionError(message.Message);
          setLoaderShow(false)
        })
      }
      else if (res.status === 500) {
        handleExceptionError(res.statusText);
        setLoaderShow(false)
      }
    } catch (error) {
      handleExceptionError(error.message);
      setLoaderShow(false)
    }
    finally {
      // setLoading(false);
    }
  }

  const api_get = async () => {
    setLoaderShow(true)
    try {
      const resp = await Client_get_by_id(id);
      if (resp.status === 200) {
        const clientData = resp.data.data;
        const ClientLocationDet = resp.data.ClientLocationDet;
        const ClientPOCDet = resp.data.ClientPOCDet

        const updatedPOCDetData = ClientPOCDet ? ClientPOCDet.map(contact => {
          return {
            ...contact,
            EmailID: decryption(contact.EmailID)
          };
        }) : [];
        const formattedEndDate = new Date(ClientLocationDet.EndDate)
          .toISOString()
          .split('T')[0];
        const formattedStartDate = new Date(ClientLocationDet.StartDate)
          .toISOString()
          .split('T')[0];

        try {
          const res = await City_get(ClientLocationDet.StateID);
          if (res.status === 200) {
            const data = res.data.data;
            if (Array.isArray(data)) {
              const newOptions1 = data
                .filter((item) => item.IsActive === true)
                .map((item) => ({
                  id: item.CityID,
                  name: item.CityName,
                  stateId: item.StateID,
                }));
              setCityOption(newOptions1);
            } else {
              console.error("City data is not an array:", data);
            }
          } else if (res.status === 401) {
            handleExceptionError('Unauthorized');
            setTimeout(() => {
              localStorage.clear();
              navigate('/');
            }, 1000);
          }
        } catch (error) {
          console.error('Error fetching cities:', error);
        }
        setSelectedLegalFilingStatus(clientData.LegalFilingStatus)
        setData({
          OrgName: clientData.OrgName,
          RegisteredEmailID: decryption(clientData.RegisteredEmailID),
          LocationName: ClientLocationDet.LocationName,
          AddLine2: ClientLocationDet.AddLine2,
          CityID: ClientLocationDet.CityID,
          LegalFilingStatus: clientData.LegalFilingStatus,
          RegisterdMobileNo: clientData.RegisterdMobileNo,
          AddLine1: ClientLocationDet.AddLine1,
          StateID: ClientLocationDet.StateID,
          Pincode: ClientLocationDet.Pincode,

          PAN: ClientLocationDet.PAN,
          IsGSTApplicable: ClientLocationDet.IsGSTApplicable,
          GSTIN: ClientLocationDet.GSTIN,
          IsPFApplicable: ClientLocationDet.IsPFApplicable,
          PFNumber: ClientLocationDet.PFNumber,
          PFPassword: ClientLocationDet.PFPassword,
          PFMobileNo: ClientLocationDet.PFMobileNo,
          PFUsername: ClientLocationDet.PFUsername,
          PFEmailID: decryption(ClientLocationDet.PFEmailID),

          IsESICApplicable: ClientLocationDet.IsESICApplicable,
          ESICNumber: ClientLocationDet.ESICNumber,
          ESICPassword: ClientLocationDet.ESICPassword,
          ESICMobileNo: ClientLocationDet.ESICMobileNo,
          ESICUsername: ClientLocationDet.ESICUsername,
          ESICEmailID: decryption(ClientLocationDet.ESICEmailID),

          IsPTApplicable: ClientLocationDet.IsPTApplicable,
          PTNumber: ClientLocationDet.PTNumber,
          PTEmployerNo: ClientLocationDet.PTEmployerNo,
          PTEmployeeNo: ClientLocationDet.PTEmployeeNo,

          IsFactoryLicenseApplicable: ClientLocationDet.IsFactoryLicenseApplicable,
          FactoryLicenseNo: ClientLocationDet.FactoryLicenseNo,
          EndDate: formattedEndDate,
          StartDate: formattedStartDate,

          ClientPOCDetData: updatedPOCDetData,
        });
        setLoaderShow(false)
      }
      else if (resp.status === 401) {

        handleExceptionError('Unauthorized');
        setLoaderShow(false)
        setTimeout(() => {
          localStorage.clear();
          navigate('/')
        }, 1000);
        ///logout();
      }
    } catch (error) {
      handleExceptionError(error.message);
      setLoaderShow(false)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleLeaderChange = event => {
    setSelectedLeader(event.target.value);
  };

  const handleExecutiveChange = event => {
    setSelectedExecutive(event.target.value);
  };

  const handleOrgNameChange = async (value) => {
    setData((prevData) => ({ ...prevData, OrgName: value.slice(0, 100) }));
    if (value.trim() == '') {
      return;
    }
    var ob = {
      "table": "Client",
      "name": value.trim(),
      "id": id ? id : "0",
    }
    const resp = await CheckBoxDuplicateRecord(ob);
    if (resp.status === 200) {
      setDisabledBtn(false)
      // setValidationErrors((prevErrors) => ({ ...prevErrors, Username: '' }));
      if (value.trim().length > 100) {
        setValidationErrors((prevErrors) => ({ ...prevErrors, OrgName: 'Org Name length should be 50 digits.' }));
      } else {
        setValidationErrors((prevErrors) => ({ ...prevErrors, OrgName: '' }));
      }
    } else if (resp.status === 400) {
      setDisabledBtn(true)
      setValidationErrors((prevErrors) => ({ ...prevErrors, OrgName: resp.data.Errors[0].Message }));
    } else if (resp.status === 401) {

      handleExceptionError('Unauthorized');
      setTimeout(() => {
        localStorage.clear();
        navigate('/')
      }, 1000);
      ///logout();
    }
    else if (resp.status === 500) {
      setDisabledBtn(false)
      handleExceptionError(resp.statusText);
    }
  };


  const handleRegisteredEmailIDChange = (value) => {
    if (value.trim().length > 50) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, RegisteredEmailID: 'Registered Email ID length should be 50 digits.' }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, RegisteredEmailID: '' }));
    }
    setData((prevData) => ({ ...prevData, RegisteredEmailID: value.slice(0, 50) }));
    // setValidationErrors((prevErrors) => ({ ...prevErrors, RegisteredEmailID: '' }));
  };
  const handleLocationNameChange = (value) => {
    if (value.trim().length > 200) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, LocationName: 'Location Name length should be 200 digits.' }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, LocationName: '' }));
    }
    setData((prevData) => ({ ...prevData, LocationName: value.slice(0, 200) }));
    // setValidationErrors((prevErrors) => ({ ...prevErrors, LocationName: '' }));
  };
  const handleAddLine2Change = (value) => {
    if (value.trim().length > 200) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, AddLine2: 'AddLine 2 length should be 200 digits.' }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, AddLine2: '' }));
    }
    setData((prevData) => ({ ...prevData, AddLine2: value.slice(0, 200) }));
    // setValidationErrors((prevErrors) => ({ ...prevErrors, AddLine2: '' }));
  };

  const handleLegalFilingStatusChange = async (event) => {
    const selected = event.target.value;
    const selectedValues1 = Array.from(event.target.selectedOptions, (option) => option.value);
    setSelectedLegalFilingStatus(selectedValues1);
    setValidationErrors((prevErrors) => ({ ...prevErrors, LegalFilingStatus: '' }));
    setData((prevData) => ({
      ...prevData,
      LegalFilingStatus: selected,
    }));
  };
  const handleRegisterdMobileNoChange = (value) => {
    if (/^\d*$/.test(value)) {
      if (value.trim().length > 10) {
        setValidationErrors((prevErrors) => ({ ...prevErrors, RegisterdMobileNo: 'Registerd Mobile No length should be 10 digits.' }));
      } else {
        setValidationErrors((prevErrors) => ({ ...prevErrors, RegisterdMobileNo: '' }));
      }
      setData((prevData) => ({ ...prevData, RegisterdMobileNo: value.slice(0, 10) }));
      // setValidationErrors((prevErrors) => ({ ...prevErrors, RegisterdMobileNo: '' }));
    }
  };
  const handleAddLine1Change = (value) => {
    if (value.trim().length > 200) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, AddLine1: 'AddLine 1 length should be 200 digits.' }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, AddLine1: '' }));
    }
    setData((prevData) => ({ ...prevData, AddLine1: value.slice(0, 200) }));
    // setValidationErrors((prevErrors) => ({ ...prevErrors, AddLine1: '' }));
  };
  const handlePincodeChange = (value) => {
    if (/^\d*$/.test(value)) {
      if (value.trim().length > 6) {
        setValidationErrors((prevErrors) => ({ ...prevErrors, Pincode: 'Pincode length should be 6 digits.' }));
      } else {
        setValidationErrors((prevErrors) => ({ ...prevErrors, Pincode: '' }));
      }
      setData((prevData) => ({ ...prevData, Pincode: value.slice(0, 6) }));
      // setValidationErrors((prevErrors) => ({ ...prevErrors, Pincode: '' }));
    }
  };

  const handlePANChange = (value) => {
    if (value.trim().length > 10) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, PAN: 'PAN length should be 10 digits.' }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, PAN: '' }));
    }
    setData((prevData) => ({ ...prevData, PAN: value.slice(0, 10) }));
    // setValidationErrors((prevErrors) => ({ ...prevErrors, PAN: '' }));
  };
  const handleGSTINChange = (value) => {
    if (value.trim().length > 15) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, GSTIN: 'GSTIN length should be 15 digits.' }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, GSTIN: '' }));
    }
    setData((prevData) => ({ ...prevData, GSTIN: value.slice(0, 15) }));
    // setValidationErrors((prevErrors) => ({ ...prevErrors, GSTIN: '' }));
  };

  const handlePFNumberChange = (value) => {
    if (value.trim().length > 15) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, PFNumber: 'PF Number length should be 15 digits.' }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, PFNumber: '' }));
    }
    setData((prevData) => ({ ...prevData, PFNumber: value.slice(0, 15) }));
    // setValidationErrors((prevErrors) => ({ ...prevErrors, PFNumber: '' }));
  };
  const handlePFPasswordChange = (value) => {
    if (value.trim().length > 50) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, PFPassword: 'PF Password length should be 50 digits.' }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, PFPassword: '' }));
    }
    setData((prevData) => ({ ...prevData, PFPassword: value.slice(0, 50) }));
    // setValidationErrors((prevErrors) => ({ ...prevErrors, PFPassword: '' }));
  };
  const handlePFMobileNoChange = (value) => {
    if (/^\d*$/.test(value)) {
      if (value.trim().length > 10) {
        setValidationErrors((prevErrors) => ({ ...prevErrors, PFMobileNo: 'PF Mobile No length should be 10 digits.' }));
      } else {
        setValidationErrors((prevErrors) => ({ ...prevErrors, PFMobileNo: '' }));
      }
      setData((prevData) => ({ ...prevData, PFMobileNo: value.slice(0, 10) }));
      // setValidationErrors((prevErrors) => ({ ...prevErrors, PFMobileNo: '' }));
    }
  };
  const handlePFUsernameChange = (value) => {
    if (value.trim().length > 50) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, PFUsername: 'PF User Name length should be 50 digits.' }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, PFUsername: '' }));
    }
    setData((prevData) => ({ ...prevData, PFUsername: value.slice(0, 50) }));
    // setValidationErrors((prevErrors) => ({ ...prevErrors, PFUsername: '' }));
  };
  const handlePFEmailIDChange = (value) => {
    if (value.trim().length > 50) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, PFEmailID: 'PF Email ID length should be 50 digits.' }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, PFEmailID: '' }));
    }
    setData((prevData) => ({ ...prevData, PFEmailID: value.slice(0, 50) }));
    // setValidationErrors((prevErrors) => ({ ...prevErrors, PFEmailID: '' }));
  };

  const handleESICNumberChange = (value) => {
    if (value.trim().length > 15) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, ESICNumber: 'ESIC No length should be 15 digits.' }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, ESICNumber: '' }));
    }
    setData((prevData) => ({ ...prevData, ESICNumber: value.slice(0, 15) }));
    // setValidationErrors((prevErrors) => ({ ...prevErrors, ESICNumber: '' }));
  };
  const handleESICPasswordChange = (value) => {
    if (value.trim().length > 50) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, ESICPassword: 'ESIC Password length should be 50 digits.' }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, ESICPassword: '' }));
    }
    setData((prevData) => ({ ...prevData, ESICPassword: value.slice(0, 50) }));
    // setValidationErrors((prevErrors) => ({ ...prevErrors, ESICPassword: '' }));
  };
  const handleESICMobileNoChange = (value) => {
    if (/^\d*$/.test(value)) {
      if (value.trim().length > 10) {
        setValidationErrors((prevErrors) => ({ ...prevErrors, ESICMobileNo: 'ESIC Mobile Number length should be 10 digits.' }));
      } else {
        setValidationErrors((prevErrors) => ({ ...prevErrors, ESICMobileNo: '' }));
      }
      setData((prevData) => ({ ...prevData, ESICMobileNo: value.slice(0, 10) }));
      // setValidationErrors((prevErrors) => ({ ...prevErrors, ESICMobileNo: '' }));
    }
  };
  const handleESICUsernameChange = (value) => {
    if (value.trim().length > 50) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, ESICUsername: 'ESIC User Name length should be 50 digits.' }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, ESICUsername: '' }));
    }
    setData((prevData) => ({ ...prevData, ESICUsername: value.slice(0, 50) }));
    // setValidationErrors((prevErrors) => ({ ...prevErrors, ESICUsername: '' }));
  };
  const handleESICEmailIDChange = (value) => {
    if (value.trim().length > 50) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, ESICEmailID: 'ESIC Email ID length should be 50 digits.' }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, ESICEmailID: '' }));
    }
    setData((prevData) => ({ ...prevData, ESICEmailID: value.slice(0, 50) }));
    // setValidationErrors((prevErrors) => ({ ...prevErrors, ESICEmailID: '' }));
  };

  const handlePTNumberChange = (value) => {
    if (value.trim().length > 15) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, PTNumber: 'Professional Tax Registration Number length should be 15 digits.' }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, PTNumber: '' }));
    }
    setData((prevData) => ({ ...prevData, PTNumber: value.slice(0, 15) }));
    // setValidationErrors((prevErrors) => ({ ...prevErrors, PTNumber: '' }));
  };
  const handlePTEmployerNoChange = (value) => {
    if (value.trim().length > 15) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, PTEmployerNo: 'PT Employer No length should be 15 digits.' }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, PTEmployerNo: '' }));
    }
    setData((prevData) => ({ ...prevData, PTEmployerNo: value.slice(0, 15) }));
    // setValidationErrors((prevErrors) => ({ ...prevErrors, PTEmployerNo: '' }));
  };
  const handlePTEmployeeNoChange = (value) => {
    if (value.trim().length > 15) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, PTEmployeeNo: 'PT Employee No length should be 15 digits.' }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, PTEmployeeNo: '' }));
    }
    setData((prevData) => ({ ...prevData, PTEmployeeNo: value.slice(0, 15) }));
    // setValidationErrors((prevErrors) => ({ ...prevErrors, PTEmployeeNo: '' }));
  };

  const handleFactoryLicenseNoChange = (value) => {
    if (value.trim().length > 15) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, FactoryLicenseNo: 'Factory License No length should be 15 digits.' }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, FactoryLicenseNo: '' }));
    }
    setData((prevData) => ({ ...prevData, FactoryLicenseNo: value.slice(0, 15) }));
    // setValidationErrors((prevErrors) => ({ ...prevErrors, FactoryLicenseNo: '' }));
  };

  const handleStartDate = date => {
    if (!date) return;
    const formattedDate = date.toISOString().split('T')[0];
    if (formattedDate.length < data.EndDate.length) {
      setValidationErrors(prevErrors => ({
        ...prevErrors,
        StartDate: "Start date cannot be after end date",
      }));
    } else {
      setValidationErrors(prevErrors => ({
        ...prevErrors,
        StartDate: "", // Clear the error message
      }));
    }
    setData(prevData => ({
      ...prevData,
      StartDate: formattedDate,
    }));
  };

  const handleEndDate = date => {
    if (!date) return;
    const formattedDate = date.toISOString().split('T')[0];
    if (formattedDate.length > data.StartDate.length) {
      setValidationErrors(prevErrors => ({
        ...prevErrors,
        EndDate: "End date cannot be before start date",
      }));
    } else {
      setValidationErrors(prevErrors => ({
        ...prevErrors,
        EndDate: "", // Clear the error message
      }));
    }
    setData(prevData => ({
      ...prevData,
      EndDate: formattedDate,
    }));
  };


  const handleClientPOCDetDataChange = (index, field, value) => {
    if (data.ClientPOCDetData.length === 0) {
      setData(prevData => ({
        ...prevData,
        ClientPOCDetData: [{ [field]: value }]
      }));
    } else {
      setData(prevData => {
        const updatedClientPOCDetData = [...prevData.ClientPOCDetData];
        if (!updatedClientPOCDetData[index]) {
          updatedClientPOCDetData[index] = {};
        }
        updatedClientPOCDetData[index][field] = value;
        return { ...prevData, ClientPOCDetData: updatedClientPOCDetData };
      });
    }
  };

  const addAnotherRow = () => {
    setData(prevData => ({
      ...prevData,
      ClientPOCDetData: [...prevData.ClientPOCDetData, {}], // Add an empty object for a new row
    }));
  };

  function clearErrors(id) {
    setExceptionError(prevMessages =>
      prevMessages.filter(msg => msg.id !== id)
    );
  }
  function clearSuccess(id) {
    setSuccessMessages(prevMessages =>
      prevMessages.filter(msg => msg.id !== id)
    );
  }

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const NumberValidation = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;
      const PincodeValidation = /^[0-9]{6}$/;

      if (activeStep === 0) {
        const errors = {};
        if (data.OrgName.trim() === '') {
          errors.OrgName = 'Organization Name is required.';
        }
        if (data.RegisteredEmailID) {
          const email_pattern = /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
          if (!email_pattern.test(data.RegisteredEmailID)) {
            errors.RegisteredEmailID = "Invalid email format";
          }
        }
        if (data.RegisteredEmailID.trim() === '') {
          errors.RegisteredEmailID = 'Register Email ID is required.';
        }
        if (data.LocationName.trim() === '') {
          errors.LocationName = 'Office/Location Name is required.';
        }
        if (data.AddLine2.trim() === '') {
          errors.AddLine2 = 'Registered Address Line 2 is required.';
        }
        if (data.CityID === 0 || data.CityID === "") {
          errors.CityID = 'City password is required.';
        }

        if (data.LegalFilingStatus.length === 0) {
          errors.LegalFilingStatus = 'Legal Filing Status is required.';
        }
        if (data.RegisterdMobileNo.trim() === '') {
          errors.RegisterdMobileNo = 'Register Mobile number is required.';
        }
        if (data.RegisterdMobileNo) {
          if (!NumberValidation.test(data.RegisterdMobileNo)) {
            errors.RegisterdMobileNo = 'Invalid mobile number.';
          }
        }
        if (data.AddLine1.trim() === '') {
          errors.AddLine1 = 'Registered Address Line 1 is required.';
        }
        if (data.StateID === 0 || data.StateID === "") {
          errors.StateID = 'State is required.';
        }

        if (data.Pincode.trim() === '') {
          errors.Pincode = 'Invalid pincode number.';
        }
        if (data.Pincode) {
          if (!PincodeValidation.test(data.Pincode)) {
            errors.Pincode = 'Invalid pincode number.';
          }
        }
        if (Object.keys(errors).length > 0) {
          setValidationErrors(errors);
          return;
        }
        setValidationErrors({});
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }

      if (activeStep === 1) {
        const errors = {};
        if (data.PAN.trim() === '') {
          errors.PAN = 'PAN Number is required.';
        }
        if (data.IsGSTApplicable) {
          if (data.GSTIN.trim() === '') {
            errors.GSTIN = 'GSTIN is required.';
          }
        }
        if (data.IsPFApplicable) {
          if (data.PFNumber.trim() === '') {
            errors.PFNumber = 'PF Number is required.';
          }
          if (data.PFPassword.trim() === '') {
            errors.PFPassword = 'PF Password is required.';
          }
          if (data.PFMobileNo.trim() === '') {
            errors.PFMobileNo = 'PF Mobile Number is required.';
          }
          if (data.PFMobileNo) {
            if (!NumberValidation.test(data.PFMobileNo)) {
              errors.PFMobileNo = 'Invalid PF Mobile Number.';
            }
          }
          if (data.PFUsername.trim() === '') {
            errors.PFUsername = 'PF Username is required.';
          }
          if (data.PFEmailID) {
            const email_pattern = /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
            if (!email_pattern.test(data.PFEmailID)) {
              errors.PFEmailID = "Invalid email format";
            }
          }
          if (data.PFEmailID.trim() === '') {
            errors.PFEmailID = 'PF Email ID is required.';
          }
        }
        if (data.IsESICApplicable) {
          if (data.ESICNumber.trim() === '') {
            errors.ESICNumber = 'ESIC Number is required.';
          }
          if (data.ESICPassword.trim() === '') {
            errors.ESICPassword = 'ESIC Password is required.';
          }

          if (data.ESICMobileNo.trim() === '') {
            errors.ESICMobileNo = 'ESIC Mobile Number is required.';
          }
          if (data.ESICMobileNo) {
            if (!NumberValidation.test(data.ESICMobileNo)) {
              errors.ESICMobileNo = 'Invalid ESIC Mobile number.';
            }
          }
          if (data.ESICUsername.trim() === '') {
            errors.ESICUsername = 'ESIC User Name is required.';
          }
          if (data.ESICEmailID) {
            const email_pattern = /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
            if (!email_pattern.test(data.ESICEmailID)) {
              errors.ESICEmailID = "Invalid email format";
            }
          }
          if (data.ESICEmailID.trim() === '') {
            errors.ESICEmailID = 'ESIC Email ID is required.';
          }
        }
        if (data.IsPTApplicable) {
          if (data.PTNumber.trim() === '') {
            errors.PTNumber = 'Professional Tax Registration Number is required.';
          }
          if (data.PTEmployerNo.trim() === '') {
            errors.PTEmployerNo = 'PT Employer No is required.';
          }
          if (data.PTEmployeeNo.trim() === '') {
            errors.PTEmployeeNo = 'PT Employee No is required.';
          }
        }
        if (data.IsFactoryLicenseApplicable) {
          if (data.FactoryLicenseNo.trim() === '') {
            errors.FactoryLicenseNo = 'Factory License No is required.';
          }
          if (data.EndDate.trim() === '') {
            errors.EndDate = 'End Date is required.';
          }
          if (data.StartDate.trim() === '') {
            errors.StartDate = 'Start Date is required.';
          }
        }
        if (Object.keys(errors).length > 0) {
          setValidationErrors(errors);
          return;
        }
        setValidationErrors({});
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }

      if (activeStep === 2) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }

      if (activeStep === 3) {
        if (id) {
          setLoaderShow2(true)
          const res = await Client_edit(id, data);
          if (res.status == 200) {
            handleExceptionSuccessMessages(res.data.message);
            handleFormReset();
            setLoaderShow2(false)
            setTimeout(function () {
              navigate('/client_panel')
            }, 2000);
          } else if (res.status === 201) {
            var dataError = res.data.Errors;
            dataError.map((message, index) => {
              handleExceptionError(message.Message);
              setLoaderShow2(false)
            })
          }
          else if (res.status === 400) {
            if (res.data.ErrorCode) {
              const validationErrorMessage = res.data.ErrorMessage;
              const errorMessagesArray = validationErrorMessage.split(', ');
              errorMessagesArray.forEach(errorMessage => {
                const [, fieldName, errorMessageText] = errorMessage.match(/\"(.*?)\" (.*)/);
                handleExceptionError(`${fieldName} - ${errorMessageText}`);
                setLoaderShow2(false)
              });
            }
            if (res.data.Message) {
              handleExceptionError(res.data.Message);
            }
          } else if (res.status === 401) {
            handleExceptionError('Unauthorized');
            setLoaderShow2(false)
            setTimeout(() => {
              localStorage.clear();
              navigate('/')
            }, 1000);
            ///logout();
          }
          else if (res.status === 500) {
            handleExceptionError(res.statusText);
            setLoaderShow2(false)
          }
        } else {
          setLoaderShow2(true)
          const res = await Client_entry(data);
          if (res.status == 200) {
            handleExceptionSuccessMessages(res.data.message);
            handleFormReset();
            setLoaderShow2(false)
            if (buttonClicked == 'submit') {
              setTimeout(function () {
                navigate('/client_panel')
              }, 2000);
            }
          } else if (res.status === 201) {
            var dataError = res.data.Errors;
            dataError.map((message, index) => {
              handleExceptionError(message.Message);
              setLoaderShow2(false)
            })
          }
          else if (res.status === 400) {
            if (res.data.ErrorCode) {
              const validationErrorMessage = res.data.ErrorMessage;
              const errorMessagesArray = validationErrorMessage.split(', ');
              errorMessagesArray.forEach(errorMessage => {
                const [, fieldName, errorMessageText] = errorMessage.match(/\"(.*?)\" (.*)/);
                handleExceptionError(`${fieldName} - ${errorMessageText}`);
                setLoaderShow2(false)
              });
            }
            if (res.data.Message) {
              handleExceptionError(res.data.Message);
              setLoaderShow2(false)
            }
          } else if (res.status === 401) {
            handleExceptionError('Unauthorized');
            setLoaderShow2(false)
            setTimeout(() => {
              localStorage.clear();
              navigate('/')
            }, 1000);
            ///logout();
          }
          else if (res.status === 500) {
            handleExceptionError(res.statusText);
            setLoaderShow2(false)
          }
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  function handleExceptionSuccessMessages(resp) {
    setSuccessMessages(successMessages => [
      ...successMessages,
      { id: Date.now(), message: resp },
    ]);
  }
  function handleExceptionError(res) {
    setExceptionError(ExceptionError => [
      ...ExceptionError,
      { id: Date.now(), message: res },
    ]);
  }

  const handleFormReset = () => {
    setData({
      OrgName: "",
      RegisteredEmailID: "",
      LocationName: "",
      AddLine2: "",
      CityID: "",
      LegalFilingStatus: "",
      RegisterdMobileNo: "",
      AddLine1: "",
      StateID: "",
      Pincode: "",

      PAN: "",
      IsGSTApplicable: '',
      GSTIN: "",
      IsPFApplicable: '',
      PFNumber: "",
      PFPassword: "",
      PFMobileNo: "",
      PFUsername: "",
      PFEmailID: "",

      IsESICApplicable: '',
      ESICNumber: "",
      ESICPassword: "",
      ESICMobileNo: "",
      ESICUsername: "",
      ESICEmailID: "",

      IsPTApplicable: '',
      PTNumber: "",
      PTEmployerNo: "",
      PTEmployeeNo: "",

      IsFactoryLicenseApplicable: '',
      FactoryLicenseNo: "",
      EndDate: "",
      StartDate: "",

      FirstName: "",
      LastName: "",
      MobileNo: "",
      Address: "",

      TLUserID: '',
      EXUserID: '',

      ClientPOCDetData: []
    });
  };

  const [stateOption, setstateoption] = useState([]);
  const [cityOption, setCityOption] = useState([]);

  // useEffect(() => {
  const fetchStates = async () => {
    try {
      const res = await State_get();
      if (res.status === 200) {
        const data = res.data.data;
        if (Array.isArray(data)) {
          const newOptions1 = data
            .filter((item) => item.IsActive === true)
            .map((item) => ({
              id: item.StateID,
              name: item.StateName,
              countryId: item.CountryID,
            }));
          setstateoption(newOptions1);
        } else {
          console.error("State data is not an array:", data);
        }
      } else if (res.status === 401) {
        handleExceptionError('Unauthorized');
        setTimeout(() => {
          localStorage.clear();
          navigate('/');
        }, 1000);
      }
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  };

  // fetchStates();
  // }, []);

  const handleStateIDChange = async (stateId, value) => {
    console.log(stateId)
    try {
      const res = await City_get(value.id);
      if (res.status === 200) {
        const data = res.data.data;
        if (Array.isArray(data)) {
          const newOptions1 = data
            .filter((item) => item.IsActive === true)
            .map((item) => ({
              id: item.CityID,
              name: item.CityName,
              stateId: item.StateID,
            }));
          setCityOption(newOptions1);
        } else {
          console.error("City data is not an array:", data);
        }
      } else if (res.status === 401) {
        handleExceptionError('Unauthorized');
        setTimeout(() => {
          localStorage.clear();
          navigate('/');
        }, 1000);
      }
    } catch (error) {
      console.error('Error fetching cities:', error);
    }

    setValidationErrors((prevErrors) => ({ ...prevErrors, StateID: '' }));
    setData((prevData) => ({
      ...prevData,
      StateID: value ? value.id : '',
    }));
  };

  const handleSelectChange2 = (cityId, value) => {
    console.log(value);
    setValidationErrors((prevErrors) => ({ ...prevErrors, CityID: '' }));
    setData((prevData) => ({
      ...prevData,
      CityID: value ? value.id : ''
    }));
  }
  const [showPasswordPF, setShowPasswordPF] = useState(false);
  const [passwordFieldTypePF, setPasswordFieldTypePF] = useState("password");

  const togglePasswordPF = () => {
    setShowPasswordPF(!showPasswordPF);
    setPasswordFieldTypePF(showPasswordPF ? "password" : "text");
  };
  const [showPasswordESIC, setShowPasswordESIC] = useState(false);
  const [passwordFieldTypeESIC, setPasswordFieldTypeESIC] = useState("password");

  const togglePasswordESIC = () => {
    setShowPasswordESIC(!showPasswordESIC);
    setPasswordFieldTypeESIC(showPasswordESIC ? "password" : "text");
  };



  return (
    <Dashboard title={"New Client"}>
      <Helmet>
        <title>Add Client | J mehta</title>
      </Helmet>
      <ErrorSnackbar errorMessages={ExceptionError} onClearErrors={clearErrors} />
      <SuccessSnackbar successMessages={successMessages} onclearSuccess={clearSuccess} />
      {LoaderShow ? (
        <LoaderComponent />
      ) : (
        <>
          {ClientAccess ? (
            <div>
              <div className="new_client_title mb-3">
                <Link to='/client_panel'><button>
                  <AiFillCloseCircle /> Close
                </button></Link>
              </div>
              <hr className='mb-4'></hr>
              <Box sx={{ width: '100%' }}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    return (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                <div>
                  <div>
                    <Typography sx={{ mt: 5, mb: 1 }}></Typography>
                    <form onSubmit={handleSubmit} onReset={handleFormReset}>
                      <div>
                        {activeStep === 0 && (
                          <>
                            <div className="row new_client_form">
                              <div className="col new_client_form">
                                <div className="new_client_part_1 w-100">
                                  <Input2 placeholder="Enter Here" label="Organization Name" required
                                    value={data.OrgName}
                                    onChange={(e) => handleOrgNameChange(e)} />
                                  {validationErrors.OrgName && <div className="error">{validationErrors.OrgName}</div>}
                                </div>
                              </div>
                              <div className="col ">
                                <div className="new_client_part_1 w-100">
                                  <div className="dashboard_input_feild mb-0">
                                    <h3 className='mb-0'>Legal Filing Status <span style={{ color: 'red' }}>*</span></h3>
                                  </div>
                                  <select className='py-0 me_height'
                                    value={selectedLegalFilingStatus}
                                    selectedOptions={selectedLegalFilingStatus}
                                    onChange={handleLegalFilingStatusChange}>
                                    <option value="">Select Legal Filing Status</option>
                                    {legalFilingStatusOptions.map(option => (
                                      <option key={option.value} value={option.value}>{option.label}</option>
                                    ))}
                                  </select>
                                  {validationErrors.LegalFilingStatus && <div className="error">{validationErrors.LegalFilingStatus}</div>}
                                </div>
                              </div>
                            </div>

                            <div className="row new_client_form">
                              <div className="col new_client_form">
                                <div className="new_client_part_1 w-100">
                                  <Input2
                                    placeholder="Enter Here" label="Office/Location Name" required
                                    value={data.LocationName}
                                    onChange={(e) => handleLocationNameChange(e)} />
                                  {validationErrors.LocationName && <div className="error">{validationErrors.LocationName}</div>}
                                </div>
                              </div>
                              <div className="col ">
                                <div className="new_client_part_1 w-100">
                                  <Input2
                                    placeholder="Enter Here" label="Register Email" required
                                    value={data.RegisteredEmailID}
                                    onChange={(e) => handleRegisteredEmailIDChange(e)} />
                                  {validationErrors.RegisteredEmailID && <div className="error">{validationErrors.RegisteredEmailID}</div>}
                                </div>
                              </div>
                            </div>

                            <div className="row new_client_form">
                              <div className="col new_client_form">
                                <div className="new_client_part_1 w-100">
                                  <Input2 placeholder="Enter Here" label="Registered Address Line 1" required
                                    value={data.AddLine1}
                                    onChange={(e) => handleAddLine1Change(e)} />
                                  {validationErrors.AddLine1 && <div className="error">{validationErrors.AddLine1}</div>}
                                </div>
                              </div>
                              <div className="col ">
                                <div className="new_client_part_1 w-100">
                                  <Input2
                                    placeholder="Enter Here" label="Registered Address Line 2" required
                                    value={data.AddLine2}
                                    onChange={(e) => handleAddLine2Change(e)} />
                                  {validationErrors.AddLine2 && <div className="error">{validationErrors.AddLine2}</div>}
                                </div>
                              </div>
                            </div>

                            <div className="row new_client_form">
                              <div className="col new_client_form">
                                <div className="new_client_part_1 w-100">
                                  <Input2 placeholder="Enter Here" label="Register Mobile No" required
                                    value={data.RegisterdMobileNo}
                                    onChange={(e) => handleRegisterdMobileNoChange(e)} />
                                  {validationErrors.RegisterdMobileNo && <div className="error">{validationErrors.RegisterdMobileNo}</div>}
                                </div>
                              </div>
                              <div className="col ">
                                <div className="new_client_part_1 w-100">
                                  <div class="dashboard_input_feild">
                                    <h3 className='mb-2'>State <span style={{ color: 'red' }}>*</span> </h3>
                                    <Autocomplete
                                      className='mt-0 border-0'
                                      value={data.StateID ? stateOption.find(option => option.id === data.StateID) || null : null}
                                      onChange={handleStateIDChange}
                                      options={stateOption}
                                      getOptionLabel={(option) => option.name} renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder='Select State'
                                          variant="outlined"
                                          InputLabelProps={{ className: "custom-label", }}
                                        />
                                      )}
                                    />
                                    {validationErrors.StateID && <div className="error mt-1">{validationErrors.StateID}</div>}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row new_client_form">
                              <div className="col new_client_form">
                                <div className="new_client_part_1 w-100">
                                  <div class="dashboard_input_feild">
                                    <h3 className='mb-2'>City <span style={{ color: 'red' }}>*</span> </h3>
                                    <Autocomplete
                                      className='mt-0 border-0'
                                      options={cityOption}
                                      value={data.CityID ? cityOption.find(option => option.id === data.CityID) || null : null}
                                      onChange={handleSelectChange2}
                                      getOptionLabel={(option) => option.name}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder='Select City'
                                          variant="outlined"
                                          InputLabelProps={{ className: "custom-label", }}
                                        />
                                      )}
                                    />
                                    {validationErrors.CityID && <div className="error mt-1">{validationErrors.CityID}</div>}
                                  </div>
                                </div>
                              </div>
                              <div className="col ">
                                <div className="new_client_part_1 w-100">
                                  <Input2 placeholder="Enter Here" label="Pin-code" required
                                    value={data.Pincode}
                                    onChange={(e) => handlePincodeChange(e)} />
                                  {validationErrors.Pincode && <div className="error">{validationErrors.Pincode}</div>}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        {activeStep === 1 && (
                          <>
                            <div className="new_client_form">
                              <div className="new_client_part_1">
                                <Input2 placeholder="Enter Your PAN Number" label="PAN" required
                                  value={data.PAN}
                                  onChange={(e) => handlePANChange(e)} />
                                {validationErrors.PAN && <div className="error">{validationErrors.PAN}</div>}
                              </div>
                              <div className="new_client_part_1">
                                <div className="toggle_switch_btn gst">
                                  <h3>Is GST Applicable? {data.IsGSTApplicable && <span style={{ color: 'red' }}>*</span>} </h3>
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={data.IsGSTApplicable}
                                      onChange={() => {
                                        const newValue = !data.IsGSTApplicable;
                                        setData(prevData => ({ ...prevData, IsGSTApplicable: newValue }));
                                        setValidationErrors(prevErrors => ({
                                          ...prevErrors,
                                          GSTIN: ''
                                        }))
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                                <Input2
                                  placeholder="Enter Your GST Number"
                                  disabled={!data.IsGSTApplicable}
                                  style={{ opacity: data.IsGSTApplicable ? 1 : 0.5 }}
                                  value={data.IsGSTApplicable ? data.GSTIN : ''}
                                  onChange={(e) => handleGSTINChange(e)}
                                />
                                {validationErrors.GSTIN && <div className="error">{validationErrors.GSTIN}</div>}
                              </div>
                            </div>

                            <hr />
                            <div className="toggle_switch_btn">
                              <h3>Is PF Applicable?</h3>
                              <label class="switch">
                                <input type="checkbox" checked={data.IsPFApplicable}
                                  onChange={() => {
                                    const newValue = !data.IsPFApplicable;
                                    setData(prevData => ({ ...prevData, IsPFApplicable: newValue }));
                                    setValidationErrors(prevErrors => ({
                                      ...prevErrors,
                                      PFNumber: '', PFPassword: '', PFMobileNo: "", PFUsername: "", PFEmailID: ""
                                    }))
                                  }}
                                />
                                <span class="slider round"></span>
                              </label>
                            </div>
                            <div className="row new_client_form">
                              <div className="col new_client_form">
                                <div className="new_client_part_1 w-100">
                                  <Input2 placeholder="Enter Here" label="PF Number" disabled={!data.IsPFApplicable} required={data.IsPFApplicable}
                                    style={{ opacity: data.IsPFApplicable ? 1 : 0.5 }}
                                    value={data.IsPFApplicable ? data.PFNumber : ''}
                                    onChange={(e) => handlePFNumberChange(e)} />
                                  {validationErrors.PFNumber && <div className="error">{validationErrors.PFNumber}</div>}
                                </div>
                              </div>
                              <div className="col ">
                                <div className="new_client_part_1 w-100">
                                  <Input2 placeholder="Enter Here" label="PF Username" disabled={!data.IsPFApplicable} required={data.IsPFApplicable}
                                    style={{ opacity: data.IsPFApplicable ? 1 : 0.5 }}
                                    value={data.IsPFApplicable ? data.PFUsername : ''}
                                    onChange={(e) => handlePFUsernameChange(e)} />
                                  {validationErrors.PFUsername && <div className="error">{validationErrors.PFUsername}</div>}
                                </div>
                              </div>
                            </div>

                            <div className="row new_client_form">
                              <div className="col new_client_form">
                                <div className="new_client_part_1 w-100 position-relative">
                                  <Input2 placeholder="Enter Here" label="PF Password" disabled={!data.IsPFApplicable} required={data.IsPFApplicable}
                                    type="password"
                                    style={{ opacity: data.IsPFApplicable ? 1 : 0.5 }}
                                    value={data.IsPFApplicable ? data.PFPassword : ''}
                                    onChange={(e) => handlePFPasswordChange(e)}
                                    showPassword={data.IsPFApplicable ? showPasswordPF : 'd-none'} // Pass showPassword state to Input2
                                    togglePasswordVisibility={togglePasswordPF}
                                  />
                                  {/* {data.IsPFApplicable ?
                                    <div
                                      className="password-toggleUser top-40 right-6"
                                      onClick={togglePasswordPF}
                                    >
                                      {showPasswordPF ? (
                                        <FaEyeSlash />
                                      ) : (
                                        <FaRegEye />
                                      )}
                                    </div>
                                    : ''} */}
                                  {validationErrors.PFPassword && <div className="error">{validationErrors.PFPassword}</div>}
                                </div>
                              </div>
                              <div className="col ">
                                <div className="new_client_part_1 w-100">
                                  <Input2 placeholder="Enter Here" label="PF Email ID" disabled={!data.IsPFApplicable}
                                    style={{ opacity: data.IsPFApplicable ? 1 : 0.5 }} required={data.IsPFApplicable}
                                    value={data.IsPFApplicable ? data.PFEmailID : ''}
                                    onChange={(e) => handlePFEmailIDChange(e)} />
                                  {validationErrors.PFEmailID && <div className="error">{validationErrors.PFEmailID}</div>}
                                </div>
                              </div>
                            </div>
                            <div className="row new_client_form">
                              <div className="col new_client_form">
                                <div className="new_client_part_1 w-100">
                                  <Input2 placeholder="Enter Here" label="PF Mobile No." disabled={!data.IsPFApplicable} required={data.IsPFApplicable}
                                    style={{ opacity: data.IsPFApplicable ? 1 : 0.5 }}
                                    value={data.IsPFApplicable ? data.PFMobileNo : ''}
                                    onChange={(e) => handlePFMobileNoChange(e)} />
                                  {validationErrors.PFMobileNo && <div className="error">{validationErrors.PFMobileNo}</div>}
                                </div>
                              </div>
                              <div className="col new_client_form">
                                <div className="new_client_part_1 w-100">
                                </div>
                              </div>
                            </div>

                            <hr />
                            <div className="toggle_switch_btn">
                              <h3>Is ESIC Applicable?</h3>
                              <label class="switch">
                                <input
                                  type="checkbox"
                                  checked={data.IsESICApplicable}
                                  onChange={() => {
                                    const newValue = !data.IsESICApplicable;
                                    setData(prevData => ({ ...prevData, IsESICApplicable: newValue }));
                                    setValidationErrors(prevErrors => ({
                                      ...prevErrors,
                                      ESICNumber: '', ESICPassword: '', ESICMobileNo: "", ESICUsername: "", ESICEmailID: ""
                                    }))
                                  }}
                                />
                                <span class="slider round"></span>
                              </label>
                            </div>

                            <div className="row new_client_form">
                              <div className="col new_client_form">
                                <div className="new_client_part_1 w-100">
                                  <Input2 placeholder="Enter Here" label="ESIC Number" disabled={!data.IsESICApplicable}
                                    style={{ opacity: data.IsESICApplicable ? 1 : 0.5 }} required={data.IsESICApplicable}
                                    value={data.IsESICApplicable ? data.ESICNumber : ''}
                                    onChange={(e) => handleESICNumberChange(e)} />
                                  {validationErrors.ESICNumber && <div className="error">{validationErrors.ESICNumber}</div>}
                                </div>
                              </div>
                              <div className="col ">
                                <div className="new_client_part_1 w-100">
                                  <Input2 placeholder="Enter Here" label="ESIC Username" disabled={!data.IsESICApplicable}
                                    style={{ opacity: data.IsESICApplicable ? 1 : 0.5 }} required={data.IsESICApplicable}
                                    value={data.IsESICApplicable ? data.ESICUsername : ''}
                                    onChange={(e) => handleESICUsernameChange(e)} />
                                  {validationErrors.ESICUsername && <div className="error">{validationErrors.ESICUsername}</div>}
                                </div>
                              </div>
                            </div>
                            <div className="row new_client_form">
                              <div className="col new_client_form">
                                <div className="new_client_part_1 w-100 position-relative">
                                  <Input2 placeholder="Enter Here" label="ESIC Password" disabled={!data.IsESICApplicable}
                                    style={{ opacity: data.IsESICApplicable ? 1 : 0.5 }} required={data.IsESICApplicable}
                                    type="password"
                                    value={data.IsESICApplicable ? data.ESICPassword : ''}
                                    onChange={(e) => handleESICPasswordChange(e)}
                                    showPassword={data.IsESICApplicable ? showPasswordESIC : 'd-none'} // Pass showPassword state to Input2
                                    togglePasswordVisibility={togglePasswordESIC}
                                  />
                                  {/* {data.IsESICApplicable ?
                                    <div
                                      className="password-toggleUser top-40"
                                      onClick={togglePasswordESIC}
                                    >
                                      {showPasswordESIC ? (
                                        <FaEyeSlash />
                                      ) : (
                                        <FaRegEye />
                                      )}
                                    </div>
                                    : ''} */}
                                  {validationErrors.ESICPassword && <div className="error">{validationErrors.ESICPassword}</div>}
                                </div>
                              </div>
                              <div className="col ">
                                <div className="new_client_part_1 w-100">
                                  <Input2 placeholder="Enter Here" label="ESIC Email ID" disabled={!data.IsESICApplicable}
                                    style={{ opacity: data.IsESICApplicable ? 1 : 0.5 }} required={data.IsESICApplicable}
                                    value={data.IsESICApplicable ? data.ESICEmailID : ''}
                                    onChange={(e) => handleESICEmailIDChange(e)} />
                                  {validationErrors.ESICEmailID && <div className="error">{validationErrors.ESICEmailID}</div>}
                                </div>
                              </div>
                            </div>

                            <div className="row new_client_form">
                              <div className="col new_client_form">
                                <div className="new_client_part_1 w-100">
                                  <Input2 placeholder="Enter Here" label="ESIC Mobile No" disabled={!data.IsESICApplicable}
                                    style={{ opacity: data.IsESICApplicable ? 1 : 0.5 }} required={data.IsESICApplicable}
                                    value={data.IsESICApplicable ? data.ESICMobileNo : ''}
                                    onChange={(e) => handleESICMobileNoChange(e)} />
                                  {validationErrors.ESICMobileNo && <div className="error">{validationErrors.ESICMobileNo}</div>}
                                </div>
                              </div>
                              <div className="col ">
                                <div className="new_client_part_1 w-100">
                                </div>
                              </div>
                            </div>

                            <hr />
                            <div className="toggle_switch_btn">
                              <h3>Is PT Applicable?</h3>
                              <label class="switch">
                                <input type="checkbox" checked={data.IsPTApplicable}
                                  onChange={() => {
                                    const newValue = !data.IsPTApplicable;
                                    setData(prevData => ({ ...prevData, IsPTApplicable: newValue }));
                                    setValidationErrors(prevErrors => ({
                                      ...prevErrors,
                                      PTNumber: '', PTEmployerNo: '', PTEmployeeNo: "" // Clear the error message
                                    }))
                                  }} />
                                <span class="slider round"></span>
                              </label>
                            </div>

                            <div className="row new_client_form">
                              <div className="col new_client_form">
                                <div className="new_client_part_1 w-100">
                                  <Input2 placeholder="Enter Here" label="Professional Tax Registration Number"
                                    disabled={!data.IsPTApplicable} required={data.IsPTApplicable}
                                    style={{ opacity: data.IsPTApplicable ? 1 : 0.5 }}
                                    value={data.IsPTApplicable ? data.PTNumber : ''}
                                    onChange={(e) => handlePTNumberChange(e)} />
                                  {validationErrors.PTNumber && <div className="error">{validationErrors.PTNumber}</div>}
                                </div>
                              </div>
                              <div className="col ">
                                <div className="new_client_part_1 w-100">
                                  <Input2 placeholder="Enter Here" label="PT Employer No" disabled={!data.IsPTApplicable}
                                    style={{ opacity: data.IsPTApplicable ? 1 : 0.5 }} required={data.IsPTApplicable}
                                    value={data.IsPTApplicable ? data.PTEmployerNo : ''}
                                    onChange={(e) => handlePTEmployerNoChange(e)} />
                                  {validationErrors.PTEmployerNo && <div className="error">{validationErrors.PTEmployerNo}</div>}
                                </div>
                              </div>
                            </div>

                            <div className="row new_client_form">
                              <div className="col new_client_form">
                                <div className="new_client_part_1 w-100">
                                  <Input2 placeholder="Enter Here" label="PT Employee No" disabled={!data.IsPTApplicable}
                                    style={{ opacity: data.IsPTApplicable ? 1 : 0.5 }} required={data.IsPTApplicable}
                                    value={data.IsPTApplicable ? data.PTEmployeeNo : ''}
                                    onChange={(e) => handlePTEmployeeNoChange(e)} />
                                  {validationErrors.PTEmployeeNo && <div className="error">{validationErrors.PTEmployeeNo}</div>}
                                </div>
                              </div>
                              <div className="col ">
                                <div className="new_client_part_1 w-100">
                                </div>
                              </div>
                            </div>

                            <hr />
                            <div className="toggle_switch_btn">
                              <h3>Is Factory License Applicable?</h3>
                              <label class="switch">
                                <input
                                  type="checkbox"
                                  checked={data.IsFactoryLicenseApplicable}
                                  onChange={() => {
                                    const newValue = !data.IsFactoryLicenseApplicable;
                                    setData(prevData => ({ ...prevData, IsFactoryLicenseApplicable: newValue }));
                                    setValidationErrors(prevErrors => ({
                                      ...prevErrors,
                                      EndDate: '', StartDate: '', FactoryLicenseNo: ""
                                    }))
                                  }}
                                />
                                <span class="slider round"></span>
                              </label>
                            </div>

                            <div className="row new_client_form">
                              <div className="col new_client_form">
                                <div className="new_client_part_1 w-100">
                                  <Input2 required={data.IsFactoryLicenseApplicable}
                                    placeholder="Enter Here"
                                    label="Factory License No"
                                    disabled={!data.IsFactoryLicenseApplicable}
                                    style={{ opacity: data.IsFactoryLicenseApplicable ? 1 : 0.5 }}
                                    value={data.IsFactoryLicenseApplicable ? data.FactoryLicenseNo : ''}
                                    onChange={(e) => handleFactoryLicenseNoChange(e)} />
                                  {validationErrors.FactoryLicenseNo && <div className="error">{validationErrors.FactoryLicenseNo}</div>}
                                </div>
                              </div>
                              <div className="col ">
                                <div className="new_client_part_1 w-100">
                                  <div className='dashboard_input_feild client_locationDate'>
                                    <div>
                                      <h3 htmlFor="startDate">Start Date {data.IsFactoryLicenseApplicable && <span style={{ color: 'red' }}>*</span>} </h3>
                                    </div>
                                    <DatePicker
                                      id="startDate"
                                      placeholderText="Enter Here"
                                      selected={data.IsFactoryLicenseApplicable ? data.StartDate : ''} // Pass Date object directly
                                      onChange={handleStartDate}
                                      dateFormat="dd/MM/yyyy"
                                      disabled={!data.IsFactoryLicenseApplicable}
                                      style={{ opacity: data.IsFactoryLicenseApplicable ? 1 : 0.5 }}
                                    />
                                  </div>
                                  {validationErrors.StartDate && <div className="error">{validationErrors.StartDate}</div>}
                                </div>
                              </div>
                            </div>

                            <div className="row new_client_form">
                              <div className="col new_client_form">
                                <div className="new_client_part_1 w-100">
                                  <div className='dashboard_input_feild client_locationDate'>
                                    <div>
                                      <h3 htmlFor="endDate">End Date {data.IsFactoryLicenseApplicable && <span style={{ color: 'red' }}>*</span>} </h3>
                                    </div>
                                    <DatePicker
                                      id="endDate"
                                      placeholderText="Enter Here"
                                      selected={data.IsFactoryLicenseApplicable ? data.EndDate : ''} // Pass Date object directly
                                      onChange={handleEndDate}
                                      minDate={data.StartDate} // Use Date object directly
                                      dateFormat="dd/MM/yyyy"
                                      disabled={!data.IsFactoryLicenseApplicable}
                                      style={{ opacity: data.IsFactoryLicenseApplicable ? 1 : 0.5 }}
                                    />
                                  </div>
                                  {validationErrors.EndDate && <div className="error">{validationErrors.EndDate}</div>}
                                </div>
                              </div>
                              <div className="col ">
                                <div className="new_client_part_1 w-100">
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        {activeStep === 2 && (
                          <>
                            <div className='client_panel_list'>
                              <table className='client_panel_list_table' cellPadding="0" cellSpacing="0">
                                <thead>
                                  <tr>
                                    <th>Primary Point of Contact</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Email</th>
                                    <th>Mobile No</th>
                                    <th>Address</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data.ClientPOCDetData.length === 0 ? (
                                    <tr key={0}>
                                      <td>
                                        <input
                                          type="text"
                                          placeholder="Enter here"
                                          className="tbl_enter"
                                          value={''}
                                          onChange={(e) => handleClientPOCDetDataChange(0, 'PrimaryPointofContact', e.target.value)}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          placeholder="Enter here"
                                          className="tbl_enter"
                                          value={''}
                                          onChange={(e) => handleClientPOCDetDataChange(0, 'FirstName', e.target.value)}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          placeholder="Enter here"
                                          className="tbl_enter"
                                          value={''}
                                          onChange={(e) => handleClientPOCDetDataChange(0, 'LastName', e.target.value)}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="email"
                                          placeholder="Enter here"
                                          className="tbl_enter"
                                          value={''}
                                          onChange={(e) => handleClientPOCDetDataChange(0, 'EmailID', e.target.value)}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="tel"
                                          placeholder="Enter here"
                                          className="tbl_enter"
                                          value={''}
                                          onChange={(e) => handleClientPOCDetDataChange(0, 'MobileNo', e.target.value)}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          placeholder="Enter here"
                                          className="tbl_enter"
                                          value={''}
                                          onChange={(e) => handleClientPOCDetDataChange(0, 'Address', e.target.value)}
                                        />
                                      </td>
                                    </tr>
                                  ) : (
                                    data.ClientPOCDetData.map((row, index) => (
                                      <tr key={index}>
                                        <td>
                                          <input
                                            type="text"
                                            placeholder="Enter here"
                                            className="tbl_enter"
                                            value={row.PrimaryPointofContact || ''}
                                            onChange={(e) => handleClientPOCDetDataChange(index, 'PrimaryPointofContact', e.target.value)}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            placeholder="Enter here"
                                            className="tbl_enter"
                                            value={row.FirstName || ''}
                                            onChange={(e) => handleClientPOCDetDataChange(index, 'FirstName', e.target.value)}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            placeholder="Enter here"
                                            className="tbl_enter"
                                            value={row.LastName || ''}
                                            onChange={(e) => handleClientPOCDetDataChange(index, 'LastName', e.target.value)}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="email"
                                            placeholder="Enter here"
                                            className="tbl_enter"
                                            value={row.EmailID || ''}
                                            onChange={(e) => handleClientPOCDetDataChange(index, 'EmailID', e.target.value)}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="tel"
                                            placeholder="Enter here"
                                            className="tbl_enter"
                                            value={row.MobileNo || ''}
                                            onChange={(e) => handleClientPOCDetDataChange(index, 'MobileNo', e.target.value)}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            placeholder="Enter here"
                                            className="tbl_enter"
                                            value={row.Address || ''}
                                            onChange={(e) => handleClientPOCDetDataChange(index, 'Address', e.target.value)}
                                          />
                                        </td>
                                      </tr>
                                    ))
                                  )}
                                </tbody>
                              </table>
                              <div className="btn_save mt-4">
                                <button className="tab1 button_transparent" type="button" onClick={addAnotherRow}>
                                  Add Another
                                  <AiOutlinePlusCircle />
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                        {activeStep === 3 && (
                          <>
                            <div className="client_tab_title">
                              <h2>Assign Team Member</h2>
                            </div>
                            <div className="new_client_form margin-space" id="tab4">
                              <div className="new_client_part_1">
                                <Select
                                  label="Team Leader"
                                  options={options}
                                  value={0} // Use an appropriate state to manage the selected value
                                  onChange={handleLeaderChange} // Implement the change handler function
                                />
                              </div>
                              <div className="new_client_part_1">
                                <Select
                                  label="Executive"
                                  options={options}
                                  value={0} // Use an appropriate state to manage the selected value
                                  onChange={handleExecutiveChange} // Implement the change handler function
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="btn_save btn-right me-0 d-flex justify-content-end">
                        {activeStep !== 0 && (
                          <Button className="tab1 save_button back" onClick={handleBack}><img src="../img/Back.svg" /> Back </Button>
                        )}
                        <button type='submit' className="tab1 save_button me-4" disabled={disabledBtn} onClick={() => setButtonClicked('submit')}>
                          {activeStep === steps.length - 1 ? (
                            <>
                              <img src="../img/Save.svg" alt="Save" />
                              <span>{id === undefined ? 'Save' : 'Update'}</span>
                              {LoaderShow2 && (
                                <LoaderComponent2 />
                              )}
                            </>
                          ) : (
                            'Next'
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Box>
            </div >
          ) : (
            <NoPermission />
          )
          }
        </>
      )}
    </Dashboard >
  );
}
