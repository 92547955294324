import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

function SuccessSnackbar({ successMessages, onclearSuccess }) {
    const [isMessageShown, setIsMessageShown] = useState(false);

    useEffect(() => {
        if (successMessages.length > 0 && !isMessageShown) {
            const successMessage = successMessages[0]; // Assuming only one message is shown at a time
            Swal.fire({
                title: successMessage.message,
                icon: 'success',
                showConfirmButton: true,
                confirmButtonText: 'OK',
                confirmButtonColor: '#3085d6',
                timer: 2000, // Auto close after 3 seconds
            }).then((result) => {
                if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
                    onclearSuccess(successMessage.id);
                    setIsMessageShown(false); // Reset the state to allow showing new messages
                }
            });
            setIsMessageShown(true); // Set the state to indicate that the message has been shown
        }
    }, [successMessages, onclearSuccess, isMessageShown]);

    return null; // Return null since we're not rendering anything directly
}

export default SuccessSnackbar;
