import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Routes1 } from './Routes.js';
import { SidebarProvider } from './SidebarProvider.js';

function App() {
  return (
    <>
      {/* <Router> */}
      <SidebarProvider>
        <Routes1 />
      </SidebarProvider>
      {/* </Router> */}

    </>

  );
}

export default App;
