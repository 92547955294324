// Client_Location_Add
import React, { useEffect, useRef, useState } from 'react';
import Dashboard from '../components/dashboard.js';
import { AiFillCloseCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import Input2 from '../components/parts/input2.js';
import Select2 from '../components/parts/select2.js';
import { Helmet } from 'react-helmet';
import { Add_Client_Location, CheckBoxDuplicateRecord, City_get, ClientLocation_edit, ClientLocation_get_by_id, Client_Location_get, Client_location, State_get, User_PageAccess } from '../service/api.js';
import ErrorSnackbar from '../components/ErrorSnackbar.js';
import SuccessSnackbar from '../components/SuccessSnackbar.js';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { decryption, encryption } from '../components/utils/utils.js';
import saveIcon from '../Save.svg';
import DatePicker from "react-datepicker"
import 'react-datepicker/dist/react-datepicker.css';
import NoPermission from '../components/NoPermission.js';
import LoaderComponent from '../components/LoaderComponent.js';
import LoaderComponent2 from '../components/LoaderComponent2.js';
import { FaEyeSlash, FaRegEye } from 'react-icons/fa';
import RadioButton2 from '../components/parts/radiobutton.js';
export default function ClientLocationAdd() {

    // const { id } = useParams();

    const { id: id } = useParams();
    const { LocationID } = useParams();

    const [ExceptionError, setExceptionError] = useState([]);
    const [successMessages, setSuccessMessages] = useState([]);
    const navigate = useNavigate();
    const [buttonClicked, setButtonClicked] = useState('');
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [ClientAccess, setClientAccess] = useState(false)
    const [LoaderShow, setLoaderShow] = useState(true)
    const [LoaderShow2, setLoaderShow2] = useState(false)

    const [data, setData] = useState({
        LocationName: "",
        AddLine2: "",
        CityID: "",
        AddLine1: "",
        StateID: "",
        Pincode: "",

        PAN: "",
        IsGSTApplicable: true,
        GSTIN: "",
        IsPFApplicable: true,

        IsPFDuplicate: false,
        IsESICDuplicate: false,
        IsPTDuplicate: false,
        IsFactoryDuplicate: false,

        PFNumber: "",
        PFPassword: "",
        PFMobileNo: "",
        PFUsername: "",
        PFEmailID: "",

        IsESICApplicable: true,
        ESICNumber: "",
        ESICPassword: "",
        ESICMobileNo: "",
        ESICUsername: "",
        ESICEmailID: "",

        IsPTApplicable: true,
        PTNumber: "",
        PTEmployerNo: "",
        PTEmployeeNo: "",

        IsFactoryLicenseApplicable: true,
        FactoryLicenseNo: "",
        EndDate: "",
        StartDate: "",

    });
    const [validationErrors, setValidationErrors] = useState({
        LocationName: "",
        AddLine2: "",
        CityID: "",
        AddLine1: "",
        StateID: "",
        Pincode: "",

        PAN: "",
        IsGSTApplicable: '',
        GSTIN: "",
        IsPFApplicable: '',
        PFNumber: "",
        PFPassword: "",
        PFMobileNo: "",
        PFUsername: "",
        PFEmailID: "",

        IsESICApplicable: '',
        ESICNumber: "",
        ESICPassword: "",
        ESICMobileNo: "",
        ESICUsername: "",
        ESICEmailID: "",

        IsPTApplicable: '',
        PTNumber: "",
        PTEmployerNo: "",
        PTEmployeeNo: "",

        IsFactoryLicenseApplicable: '',
        FactoryLicenseNo: "",
        EndDate: "",
        StartDate: "",
    });

    const [ClientID, setClientID] = useState()
    const api_get = async () => {
        setLoaderShow(true)
        try {
            const resp = await ClientLocation_get_by_id(LocationID);
            if (resp.status === 200) {
                const ClientLocationDet = resp.data.ClientLocation;
                localStorage.setItem('ClientID', JSON.stringify(ClientLocationDet.ClientID));
                setClientID(ClientLocationDet.ClientID)
                const formattedEndDate = new Date(ClientLocationDet.EndDate)
                    .toISOString()
                    .split('T')[0];
                const formattedStartDate = new Date(ClientLocationDet.StartDate)
                    .toISOString()
                    .split('T')[0];

                try {
                    const res = await City_get(ClientLocationDet.StateID);
                    if (res.status === 200) {
                        const data = res.data.data;
                        if (Array.isArray(data)) {
                            const newOptions1 = data
                                .filter((item) => item.IsActive === true)
                                .map((item) => ({
                                    id: item.CityID,
                                    name: item.CityName,
                                    stateId: item.StateID,
                                }));
                            setCityOption(newOptions1);
                        } else {
                            console.error("City data is not an array:", data);
                        }
                    } else if (res.status === 401) {
                        handleExceptionError('Unauthorized');
                        setTimeout(() => {
                            localStorage.clear();
                            navigate('/');
                        }, 1000);
                    }
                } catch (error) {
                    console.error('Error fetching cities:', error);
                }

                setData({
                    LocationName: ClientLocationDet.LocationName,
                    AddLine2: ClientLocationDet.AddLine2,
                    CityID: ClientLocationDet.CityID,
                    AddLine1: ClientLocationDet.AddLine1,
                    StateID: ClientLocationDet.StateID,
                    Pincode: ClientLocationDet.Pincode,

                    PAN: ClientLocationDet.PAN,
                    IsGSTApplicable: ClientLocationDet.IsGSTApplicable,
                    GSTIN: ClientLocationDet.GSTIN,
                    IsPFApplicable: ClientLocationDet.IsPFApplicable,
                    IsPFDuplicate: ClientLocationDet.IsPFDuplicate,
                    PFNumber: ClientLocationDet.PFNumber,
                    PFPassword: decryption(ClientLocationDet.PFPassword),
                    PFMobileNo: ClientLocationDet.PFMobileNo,
                    PFUsername: ClientLocationDet.PFUsername,
                    PFEmailID: decryption(ClientLocationDet.PFEmailID),

                    IsESICApplicable: ClientLocationDet.IsESICApplicable,
                    ESICNumber: ClientLocationDet.ESICNumber,
                    ESICPassword: decryption(ClientLocationDet.ESICPassword),
                    ESICMobileNo: ClientLocationDet.ESICMobileNo,
                    ESICUsername: ClientLocationDet.ESICUsername,
                    ESICEmailID: decryption(ClientLocationDet.ESICEmailID),

                    IsPTApplicable: ClientLocationDet.IsPTApplicable,
                    PTNumber: ClientLocationDet.PTNumber,
                    PTEmployerNo: ClientLocationDet.PTEmployerNo,
                    PTEmployeeNo: ClientLocationDet.PTEmployeeNo,

                    IsFactoryLicenseApplicable: ClientLocationDet.IsFactoryLicenseApplicable,
                    FactoryLicenseNo: ClientLocationDet.FactoryLicenseNo,
                    StartDate: formattedStartDate,
                    EndDate: formattedEndDate,
                    IsPFDuplicate: false,
                    IsESICDuplicate: false,
                    IsPTDuplicate: false,
                    IsFactoryDuplicate: false,

                });
                setLoaderShow(false)
            }
            else if (resp.status === 401) {
                handleExceptionError('Unauthorized');
                setTimeout(() => {
                    localStorage.clear();
                    navigate('/')
                }, 1000);
                ///logout();
            }
        } catch (error) {
            handleExceptionError(error.message);
        }
    }

    const isEffectExecutedRef = useRef(false)
    useEffect(() => {
        if (!isEffectExecutedRef.current) {
            PageAccess()
            isEffectExecutedRef.current = true
        }

    }, []);

    const PageAccess = async () => {
        try {
            const res = await User_PageAccess("5");
            if (res.status == 200) {
                fetchStates()
                AddLocationData()
                if (id ? res.data.AllowUpdate === true : res.data.AllowAdd === true) {
                    setClientAccess(true)
                    if (LocationID) {
                        api_get();
                    }
                    setLoaderShow(false)
                }
                setLoaderShow(false)
            } else if (res.status === 400) {
                if (res.data.ErrorCode) {
                    const validationErrorMessage = res.data.ErrorMessage;
                    const errorMessagesArray = validationErrorMessage.split(', ');
                    errorMessagesArray.forEach(errorMessage => {
                        const [, fieldName, errorMessageText] = errorMessage.match(/\"(.*?)\" (.*)/);
                        handleExceptionError(`${fieldName} - ${errorMessageText}`);
                        setLoaderShow(false)
                    });
                }
                if (res.data.Message) {
                    handleExceptionError(res.data.Message);
                    setLoaderShow(false)
                }
            } else if (res.status === 401) {
                handleExceptionError('Unauthorized');
                setLoaderShow(false)
                setTimeout(() => {
                    localStorage.clear();
                    navigate('/')
                }, 1000);
                ///logout();
            }
            else if (res.status === 200) {
                var dataError = res.data.Errors;
                dataError.map((message, index) => {
                    handleExceptionError(message.Message);
                    setLoaderShow(false)
                })
            }
            else if (res.status === 500) {
                handleExceptionError(res.statusText);
                setLoaderShow(false)
            }
        } catch (error) {
            handleExceptionError(error.message);
        }
        finally {
            // setLoading(false);
        }
    }

    const handleLocationNameChange = async (value) => {
        setData((prevData) => ({ ...prevData, LocationName: value.slice(0, 200) }));
        if (value.trim() == '') {
            return;
        }
        var ob = {
            "table": "Location",
            "name": value.trim(),
            "id": LocationID ? LocationID : "0",
        }
        const resp = await CheckBoxDuplicateRecord(ob);
        if (resp.status === 200) {
            setDisabledBtn(false)
            // setValidationErrors((prevErrors) => ({ ...prevErrors, Username: '' }));
            if (value.trim().length > 200) {
                setValidationErrors((prevErrors) => ({ ...prevErrors, LocationName: 'Location Name length should be 50 digits.' }));
            } else {
                setValidationErrors((prevErrors) => ({ ...prevErrors, LocationName: '' }));
            }
        } else if (resp.status === 400) {
            setDisabledBtn(true)
            setValidationErrors((prevErrors) => ({ ...prevErrors, LocationName: resp.data.Errors[0].Message }));
        } else if (resp.status === 401) {

            handleExceptionError('Unauthorized');
            setTimeout(() => {
                localStorage.clear();
                navigate('/')
            }, 1000);
            ///logout();
        }
        else if (resp.status === 500) {
            setDisabledBtn(false)
            handleExceptionError(resp.statusText);
        }
    };

    const handleAddLine2Change = (value) => {
        if (value.trim().length > 200) {
            setValidationErrors((prevErrors) => ({ ...prevErrors, AddLine2: 'AddLine 2 length should be 200 digits.' }));
        } else {
            setValidationErrors((prevErrors) => ({ ...prevErrors, AddLine2: '' }));
        }
        setData((prevData) => ({ ...prevData, AddLine2: value.slice(0, 200) }));
        // setValidationErrors((prevErrors) => ({ ...prevErrors, AddLine2: '' }));
    };
    const handleAddLine1Change = (value) => {
        if (value.trim().length > 200) {
            setValidationErrors((prevErrors) => ({ ...prevErrors, AddLine1: 'AddLine 1 length should be 200 digits.' }));
        } else {
            setValidationErrors((prevErrors) => ({ ...prevErrors, AddLine1: '' }));
        }
        setData((prevData) => ({ ...prevData, AddLine1: value.slice(0, 200) }));
        // setValidationErrors((prevErrors) => ({ ...prevErrors, AddLine1: '' }));
    };
    const handlePincodeChange = (value) => {
        if (/^\d*$/.test(value)) {
            if (value.trim().length > 6) {
                setValidationErrors((prevErrors) => ({ ...prevErrors, Pincode: 'Pincode length should be 6 digits.' }));
            } else {
                setValidationErrors((prevErrors) => ({ ...prevErrors, Pincode: '' }));
            }
            setData((prevData) => ({ ...prevData, Pincode: value.slice(0, 6) }));
            // setValidationErrors((prevErrors) => ({ ...prevErrors, Pincode: '' }));
        }
    };

    const handlePANChange = (value) => {
        if (value.trim().length > 10) {
            setValidationErrors((prevErrors) => ({ ...prevErrors, PAN: 'PAN length should be 10 digits.' }));
        } else {
            setValidationErrors((prevErrors) => ({ ...prevErrors, PAN: '' }));
        }
        setData((prevData) => ({ ...prevData, PAN: value.slice(0, 10) }));
        // setValidationErrors((prevErrors) => ({ ...prevErrors, PAN: '' }));
    };
    const handleGSTINChange = (value) => {
        if (value.trim().length > 15) {
            setValidationErrors((prevErrors) => ({ ...prevErrors, GSTIN: 'GSTIN length should be 15 digits.' }));
        } else {
            setValidationErrors((prevErrors) => ({ ...prevErrors, GSTIN: '' }));
        }
        setData((prevData) => ({ ...prevData, GSTIN: value.slice(0, 15) }));
        // setValidationErrors((prevErrors) => ({ ...prevErrors, GSTIN: '' }));
    };

    const handlePFNumberChange = (value) => {
        if (value.trim().length > 15) {
            setValidationErrors((prevErrors) => ({ ...prevErrors, PFNumber: 'PF Number length should be 15 digits.' }));
        } else {
            setValidationErrors((prevErrors) => ({ ...prevErrors, PFNumber: '' }));
        }
        setData((prevData) => ({ ...prevData, PFNumber: value.slice(0, 15) }));
        // setValidationErrors((prevErrors) => ({ ...prevErrors, PFNumber: '' }));
    };
    const handlePFPasswordChange = (value) => {
        if (value.trim().length > 50) {
            setValidationErrors((prevErrors) => ({ ...prevErrors, PFPassword: 'PF Password length should be 50 digits.' }));
        } else {
            setValidationErrors((prevErrors) => ({ ...prevErrors, PFPassword: '' }));
        }
        setData((prevData) => ({ ...prevData, PFPassword: value.slice(0, 50) }));
        // setValidationErrors((prevErrors) => ({ ...prevErrors, PFPassword: '' }));
    };
    const handlePFMobileNoChange = (value) => {
        if (/^\d*$/.test(value)) {
            if (value.trim().length > 10) {
                setValidationErrors((prevErrors) => ({ ...prevErrors, PFMobileNo: 'PF Mobile No length should be 10 digits.' }));
            } else {
                setValidationErrors((prevErrors) => ({ ...prevErrors, PFMobileNo: '' }));
            }
            setData((prevData) => ({ ...prevData, PFMobileNo: value.slice(0, 10) }));
            // setValidationErrors((prevErrors) => ({ ...prevErrors, PFMobileNo: '' }));
        }
    };
    const handlePFUsernameChange = (value) => {
        if (value.trim().length > 50) {
            setValidationErrors((prevErrors) => ({ ...prevErrors, PFUsername: 'PF User Name length should be 50 digits.' }));
        } else {
            setValidationErrors((prevErrors) => ({ ...prevErrors, PFUsername: '' }));
        }
        setData((prevData) => ({ ...prevData, PFUsername: value.slice(0, 50) }));
        // setValidationErrors((prevErrors) => ({ ...prevErrors, PFUsername: '' }));
    };
    const handlePFEmailIDChange = (value) => {
        if (value.trim().length > 50) {
            setValidationErrors((prevErrors) => ({ ...prevErrors, PFEmailID: 'PF Email ID length should be 50 digits.' }));
        } else {
            setValidationErrors((prevErrors) => ({ ...prevErrors, PFEmailID: '' }));
        }
        setData((prevData) => ({ ...prevData, PFEmailID: value.slice(0, 50) }));
        // setValidationErrors((prevErrors) => ({ ...prevErrors, PFEmailID: '' }));
    };

    const handleESICNumberChange = (value) => {
        if (value.trim().length > 15) {
            setValidationErrors((prevErrors) => ({ ...prevErrors, ESICNumber: 'ESIC No length should be 15 digits.' }));
        } else {
            setValidationErrors((prevErrors) => ({ ...prevErrors, ESICNumber: '' }));
        }
        setData((prevData) => ({ ...prevData, ESICNumber: value.slice(0, 15) }));
        // setValidationErrors((prevErrors) => ({ ...prevErrors, ESICNumber: '' }));
    };
    const handleESICPasswordChange = (value) => {
        if (value.trim().length > 50) {
            setValidationErrors((prevErrors) => ({ ...prevErrors, ESICPassword: 'ESIC Password length should be 50 digits.' }));
        } else {
            setValidationErrors((prevErrors) => ({ ...prevErrors, ESICPassword: '' }));
        }
        setData((prevData) => ({ ...prevData, ESICPassword: value.slice(0, 50) }));
        // setValidationErrors((prevErrors) => ({ ...prevErrors, ESICPassword: '' }));
    };
    const handleESICMobileNoChange = (value) => {
        if (/^\d*$/.test(value)) {
            if (value.trim().length > 10) {
                setValidationErrors((prevErrors) => ({ ...prevErrors, ESICMobileNo: 'ESIC Mobile Number length should be 10 digits.' }));
            } else {
                setValidationErrors((prevErrors) => ({ ...prevErrors, ESICMobileNo: '' }));
            }
            setData((prevData) => ({ ...prevData, ESICMobileNo: value.slice(0, 10) }));
            // setValidationErrors((prevErrors) => ({ ...prevErrors, ESICMobileNo: '' }));
        }
    };
    const handleESICUsernameChange = (value) => {
        if (value.trim().length > 50) {
            setValidationErrors((prevErrors) => ({ ...prevErrors, ESICUsername: 'ESIC User Name length should be 50 digits.' }));
        } else {
            setValidationErrors((prevErrors) => ({ ...prevErrors, ESICUsername: '' }));
        }
        setData((prevData) => ({ ...prevData, ESICUsername: value.slice(0, 50) }));
        // setValidationErrors((prevErrors) => ({ ...prevErrors, ESICUsername: '' }));
    };
    const handleESICEmailIDChange = (value) => {
        if (value.trim().length > 50) {
            setValidationErrors((prevErrors) => ({ ...prevErrors, ESICEmailID: 'ESIC Email ID length should be 50 digits.' }));
        } else {
            setValidationErrors((prevErrors) => ({ ...prevErrors, ESICEmailID: '' }));
        }
        setData((prevData) => ({ ...prevData, ESICEmailID: value.slice(0, 50) }));
        // setValidationErrors((prevErrors) => ({ ...prevErrors, ESICEmailID: '' }));
    };

    const handlePTNumberChange = (value) => {
        if (value.trim().length > 15) {
            setValidationErrors((prevErrors) => ({ ...prevErrors, PTNumber: 'Professional Tax Registration Number length should be 15 digits.' }));
        } else {
            setValidationErrors((prevErrors) => ({ ...prevErrors, PTNumber: '' }));
        }
        setData((prevData) => ({ ...prevData, PTNumber: value.slice(0, 15) }));
        // setValidationErrors((prevErrors) => ({ ...prevErrors, PTNumber: '' }));
    };
    const handlePTEmployerNoChange = (value) => {
        if (value.trim().length > 15) {
            setValidationErrors((prevErrors) => ({ ...prevErrors, PTEmployerNo: 'PT Employer No length should be 15 digits.' }));
        } else {
            setValidationErrors((prevErrors) => ({ ...prevErrors, PTEmployerNo: '' }));
        }
        setData((prevData) => ({ ...prevData, PTEmployerNo: value.slice(0, 15) }));
        // setValidationErrors((prevErrors) => ({ ...prevErrors, PTEmployerNo: '' }));
    };
    const handlePTEmployeeNoChange = (value) => {
        if (value.trim().length > 15) {
            setValidationErrors((prevErrors) => ({ ...prevErrors, PTEmployeeNo: 'PT Employee No length should be 15 digits.' }));
        } else {
            setValidationErrors((prevErrors) => ({ ...prevErrors, PTEmployeeNo: '' }));
        }
        setData((prevData) => ({ ...prevData, PTEmployeeNo: value.slice(0, 15) }));
        // setValidationErrors((prevErrors) => ({ ...prevErrors, PTEmployeeNo: '' }));
    };

    const handleFactoryLicenseNoChange = (value) => {
        if (value.trim().length > 15) {
            setValidationErrors((prevErrors) => ({ ...prevErrors, FactoryLicenseNo: 'Factory License No length should be 15 digits.' }));
        } else {
            setValidationErrors((prevErrors) => ({ ...prevErrors, FactoryLicenseNo: '' }));
        }
        setData((prevData) => ({ ...prevData, FactoryLicenseNo: value.slice(0, 15) }));
        // setValidationErrors((prevErrors) => ({ ...prevErrors, FactoryLicenseNo: '' }));
    };

    const handleStartDate = date => {
        if (!date) return;

        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because getMonth() returns zero-based index
        const day = date.getDate().toString().padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`; // Change format to YYYY-MM-DD

        if (formattedDate < data.EndDate) {
            setValidationErrors(prevErrors => ({
                ...prevErrors,
                StartDate: "Start date cannot be after end date",
            }));
        } else {
            setValidationErrors(prevErrors => ({
                ...prevErrors,
                StartDate: "", // Clear the error message
            }));
        }

        setData(prevData => ({
            ...prevData,
            StartDate: formattedDate,
        }));
    };

    const handleEndDate = date => {
        if (!date) return;
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because getMonth() returns zero-based index
        const day = date.getDate().toString().padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`

        if (formattedDate.length > data.StartDate.length) {
            setValidationErrors(prevErrors => ({
                ...prevErrors,
                EndDate: "End date cannot be before start date",
            }));
        } else {
            setValidationErrors(prevErrors => ({
                ...prevErrors,
                EndDate: "", // Clear the error message
            }));
        }
        setData(prevData => ({
            ...prevData,
            EndDate: formattedDate,
        }));
    };

    function clearErrors(id) {
        setExceptionError(prevMessages =>
            prevMessages.filter(msg => msg.id !== id)
        );
    }
    function clearSuccess(id) {
        setSuccessMessages(prevMessages =>
            prevMessages.filter(msg => msg.id !== id)
        );
    }

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            const NumberValidation = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;
            const PincodeValidation = /^[0-9]{6}$/;

            const errors = {};

            if (data.LocationName.trim() === '') {
                errors.LocationName = 'Office/Location Name is required.';
            }
            if (data.AddLine2.trim() === '') {
                errors.AddLine2 = 'Registered Address Line 2 is required.';
            }
            if (data.CityID === 0 || data.CityID === "") {
                errors.CityID = 'City is required.';
            }
            if (data.AddLine1.trim() === '') {
                errors.AddLine1 = 'Registered Address Line 1 is required.';
            }
            if (data.StateID === 0 || data.StateID === "") {
                errors.StateID = 'State is required.';
            }
            if (data.Pincode.trim() === '') {
                errors.Pincode = 'Invalid Pincode number.';
            }
            if (data.Pincode) {
                if (!PincodeValidation.test(data.Pincode)) {
                    errors.Pincode = 'Invalid Pincode number.';
                }
            }

            if (data.PAN.trim() === '') {
                errors.PAN = 'PAN Number is required.';
            }
            if (data.IsGSTApplicable) {
                if (data.GSTIN.trim() === '') {
                    errors.GSTIN = 'GSTIN is required.';
                }
            }

            if (data.IsPFApplicable) {
                if (data.PFNumber.trim() === '') {
                    errors.PFNumber = 'PF Number is required.';
                }
                // if (data.PFPassword.trim() === '') {
                //     errors.PFPassword = 'PF Password is required.';
                // }
                if (data.PFMobileNo.trim() === '') {
                    errors.PFMobileNo = 'PF Mobile Number is required.';
                }
                if (data.PFMobileNo) {
                    if (!NumberValidation.test(data.PFMobileNo)) {
                        errors.PFMobileNo = 'Invalid PF Mobile number.';
                    }
                }
                if (data.PFUsername.trim() === '') {
                    errors.PFUsername = 'PF Username is required.';
                }
                if (data.PFEmailID) {
                    const email_pattern = /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
                    if (!email_pattern.test(data.PFEmailID)) {
                        errors.PFEmailID = "Invalid email format";
                    }
                }
                if (data.PFEmailID.trim() === '') {
                    errors.PFEmailID = 'PF Email ID is required.';
                }
                if (data.IsESICApplicable) {
                    if (data.ESICNumber.trim() === '') {
                        errors.ESICNumber = 'ESIC Number is required.';
                    }
                    // if (data.ESICPassword.trim() === '') {
                    //     errors.ESICPassword = 'ESIC Password is required.';
                    // }

                    if (data.ESICMobileNo.trim() === '') {
                        errors.ESICMobileNo = 'ESIC Mobile Number is required.';
                    }
                    if (data.ESICMobileNo) {
                        if (!NumberValidation.test(data.ESICMobileNo)) {
                            errors.ESICMobileNo = 'Invalid ESIC Mobile number.';
                        }
                    }
                    if (data.ESICUsername.trim() === '') {
                        errors.ESICUsername = 'ESIC User Name is required.';
                    }
                    if (data.ESICEmailID) {
                        const email_pattern = /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
                        if (!email_pattern.test(data.ESICEmailID)) {
                            errors.ESICEmailID = "Invalid email format";
                        }
                    }
                    if (data.ESICEmailID.trim() === '') {
                        errors.ESICEmailID = 'ESIC Email ID is required.';
                    }
                }

                if (data.IsPTApplicable) {
                    if (data.PTNumber.trim() === '') {
                        errors.PTNumber = 'Professional Tax Registration Number is required.';
                    }
                    if (data.PTEmployerNo.trim() === '') {
                        errors.PTEmployerNo = 'PT Employer No is required.';
                    }
                    if (data.PTEmployeeNo.trim() === '') {
                        errors.PTEmployeeNo = 'PT Employer No is required.';
                    }
                }
                if (data.IsFactoryLicenseApplicable) {
                    if (data.FactoryLicenseNo.trim() === '') {
                        errors.FactoryLicenseNo = 'Factory License No is required.';
                    }
                    if (data.EndDate.trim() === '') {
                        errors.EndDate = 'End Date is required.';
                    }
                    if (data.StartDate.trim() === '') {
                        errors.StartDate = 'Start Date is required.';
                    }
                }
            }
            if (Object.keys(errors).length > 0) {
                setValidationErrors(errors);
                return;
            }
            setValidationErrors({});

            if (LocationID) {
                setLoaderShow2(true)
                const res = await ClientLocation_edit(ClientID, LocationID, data);
                console.log(res)
                if (res.status == 200) {
                    handleExceptionSuccessMessages(res.data);
                    handleFormReset();
                    setLoaderShow2(false)
                    setTimeout(function () {
                        navigate('/client_panel')
                    }, 2000);
                    localStorage.removeItem('ClientID');
                } else if (res.status === 201) {
                    var dataError = res.data.Errors;
                    dataError.map((message, index) => {
                        handleExceptionError(message.Message);
                        setLoaderShow2(false)
                    })
                } else if (res.status === 400) {
                    if (res.data.ErrorCode) {
                        const validationErrorMessage = res.data.ErrorMessage;
                        const errorMessagesArray = validationErrorMessage.split(', ');

                        errorMessagesArray.forEach(errorMessage => {
                            const [, fieldName, errorMessageText] = errorMessage.match(/\"(.*?)\" (.*)/);
                            handleExceptionError(`${fieldName} - ${errorMessageText}`);
                            setLoaderShow2(false)
                        });
                    }
                    if (res.data.Message) {
                        handleExceptionError(res.data.Message);
                        setLoaderShow2(false)
                    }
                } else if (res.status === 401) {
                    handleExceptionError('Unauthorized');
                    setLoaderShow2(false)
                    setTimeout(() => {
                        localStorage.clear();
                        navigate('/')
                    }, 1000);
                    ///logout();
                } else if (res.status === 500) {
                    handleExceptionError(res.statusText);
                    setLoaderShow2(false)
                } else if (res.response.status === 400) {
                    handleExceptionError(res.response.data.Message);
                    setLoaderShow2(false)
                }
            } else if (id) {
                setLoaderShow2(true)
                const res = await Add_Client_Location(id, data);
                if (res.status == 200) {
                    handleExceptionSuccessMessages(res.data);
                    handleFormReset();
                    setLoaderShow2(false)
                    if (buttonClicked == 'submit') {
                        setTimeout(function () {
                            navigate('/client_panel')
                        }, 2000);
                    }
                    localStorage.removeItem('ClientID');
                } else if (res.status === 201) {
                    var dataError = res.data.Errors;
                    dataError.map((message, index) => {
                        handleExceptionError(message.Message);
                        setLoaderShow2(false)
                    })
                }
                else if (res.status === 400) {
                    if (res.data.ErrorCode) {
                        const validationErrorMessage = res.data.ErrorMessage;
                        const errorMessagesArray = validationErrorMessage.split(', ');

                        errorMessagesArray.forEach(errorMessage => {
                            const [, fieldName, errorMessageText] = errorMessage.match(/\"(.*?)\" (.*)/);
                            handleExceptionError(`${fieldName} - ${errorMessageText}`);
                            setLoaderShow2(false)
                        });
                    }
                    if (res.data.Message) {
                        handleExceptionError(res.data.Message);
                        setLoaderShow2(false)
                    }
                } else if (res.status === 401) {
                    handleExceptionError('Unauthorized');
                    setLoaderShow2(false)
                    setTimeout(() => {
                        localStorage.clear();
                        navigate('/')
                    }, 1000);
                    ///logout();
                }
                else if (res.status === 500) {
                    handleExceptionError(res.statusText);
                    setLoaderShow2(false)
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    function handleExceptionSuccessMessages(resp) {
        setSuccessMessages(successMessages => [
            ...successMessages,
            { id: Date.now(), message: resp },
        ]);
    }
    function handleExceptionError(res) {
        setExceptionError(ExceptionError => [
            ...ExceptionError,
            { id: Date.now(), message: res },
        ]);
    }

    const handleFormReset = () => {
        setData({
            LocationName: "",
            AddLine2: "",
            CityID: "",
            AddLine1: "",
            StateID: "",
            Pincode: "",

            PAN: "",
            IsGSTApplicable: true,
            GSTIN: "",
            IsPFApplicable: true,
            PFNumber: "",
            PFPassword: "",
            PFMobileNo: "",
            PFUsername: "",
            PFEmailID: "",

            IsESICApplicable: true,
            ESICNumber: "",
            ESICPassword: "",
            ESICMobileNo: "",
            ESICUsername: "",
            ESICEmailID: "",

            IsPTApplicable: true,
            PTNumber: "",
            PTEmployerNo: "",
            PTEmployeeNo: "",

            IsFactoryLicenseApplicable: true,
            FactoryLicenseNo: "",
            EndDate: "",
            StartDate: "",
        });
        setValidationErrors({
            LocationName: "",
            AddLine2: "",
            CityID: "",
            AddLine1: "",
            StateID: "",
            Pincode: "",

            PAN: "",
            IsGSTApplicable: '',
            GSTIN: "",
            IsPFApplicable: '',
            PFNumber: "",
            PFPassword: "",
            PFMobileNo: "",
            PFUsername: "",
            PFEmailID: "",

            IsESICApplicable: '',
            ESICNumber: "",
            ESICPassword: "",
            ESICMobileNo: "",
            ESICUsername: "",
            ESICEmailID: "",

            IsPTApplicable: '',
            PTNumber: "",
            PTEmployerNo: "",
            PTEmployeeNo: "",

            IsFactoryLicenseApplicable: '',
            FactoryLicenseNo: "",
            EndDate: "",
            StartDate: "",
        })
    };

    const [stateOption, setstateoption] = useState([]);
    const [cityOption, setCityOption] = useState([]);

    const fetchStates = async () => {
        try {
            const res = await State_get();
            if (res.status === 200) {
                const data = res.data.data;
                if (Array.isArray(data)) {
                    const newOptions1 = data
                        .filter((item) => item.IsActive === true)
                        .map((item) => ({
                            id: item.StateID,
                            name: item.StateName,
                            countryId: item.CountryID,
                        }));
                    setstateoption(newOptions1);
                } else {
                    console.error("State data is not an array:", data);
                }
            } else if (res.status === 401) {
                handleExceptionError('Unauthorized');
                setTimeout(() => {
                    localStorage.clear();
                    navigate('/');
                }, 1000);
            }
        } catch (error) {
            console.error('Error fetching states:', error);
        }
    };

    const handleStateIDChange = async (stateId, value) => {
        console.log(stateId)
        try {
            const res = await City_get(value.id);
            if (res.status === 200) {
                const data = res.data.data;
                if (Array.isArray(data)) {
                    const newOptions1 = data
                        .filter((item) => item.IsActive === true)
                        .map((item) => ({
                            id: item.CityID,
                            name: item.CityName,
                            stateId: item.StateID,
                        }));
                    setCityOption(newOptions1);
                } else {
                    console.error("City data is not an array:", data);
                }
            } else if (res.status === 401) {
                handleExceptionError('Unauthorized');
                setTimeout(() => {
                    localStorage.clear();
                    navigate('/');
                }, 1000);
            }
        } catch (error) {
            console.error('Error fetching cities:', error);
        }

        setValidationErrors((prevErrors) => ({ ...prevErrors, StateID: '' }));
        setData((prevData) => ({
            ...prevData,
            StateID: value ? value.id : '',
        }));
    };

    const handleSelectChange2 = (cityId, value) => {
        console.log(cityId);
        setValidationErrors((prevErrors) => ({ ...prevErrors, CityID: '' }));
        setData((prevData) => ({
            ...prevData,
            CityID: value ? value.id : ''
        }));
    }

    const [showPasswordPF, setShowPasswordPF] = useState(false);
    const [passwordFieldTypePF, setPasswordFieldTypePF] = useState("password");

    const togglePasswordPF = () => {
        setShowPasswordPF(!showPasswordPF);
        setPasswordFieldTypePF(showPasswordPF ? "password" : "text");
    };
    const [showPasswordESIC, setShowPasswordESIC] = useState(false);
    const [passwordFieldTypeESIC, setPasswordFieldTypeESIC] = useState("password");

    const togglePasswordESIC = () => {
        setShowPasswordESIC(!showPasswordESIC);
        setPasswordFieldTypeESIC(showPasswordESIC ? "password" : "text");
    };

    const [isChecked, setIsChecked] = useState(true);

    const handleActiveChange = (value) => {
        setIsChecked(!isChecked);
        // setData((prevData) => ({ ...prevData, active: !prevData.active }));
    };

    const [ClientLocationList, setClientLocationList] = useState([])


    const [ClientLocationOption, setClientLocationOption] = useState([])

    const AddLocationData = async () => {
        try {
            const ClientID = JSON.parse(localStorage.getItem('ClientID'));
            const res = await Client_Location_get(LocationID ? ClientID : id);
            if (res.status === 200) {
                const LocationData = res.data.ClientLocation;
                setClientLocationList(LocationData);

                if (Array.isArray(LocationData)) {
                    const newOptions1 = LocationData
                        .map((item) => ({
                            label: item.LocationName,
                            value: item.LocationID,
                        }));
                    setClientLocationOption(newOptions1)


                } else {
                    console.error("State data is not an array:", data);
                }
            } else if (res.status === 400) {
                handleExceptionError(res.data.ErrorMessage);
            } else if (res.status === 401) {
                handleExceptionError('Unauthorized');
                setLoaderShow(false)
                setTimeout(() => {
                    localStorage.clear();
                    navigate('/');
                }, 1000);
            } else if (res.status === 500) {
                handleExceptionError(res.data.errorMessage);
                setLoaderShow(false)
            }

        } catch (error) {
            handleExceptionError(error.message);
        }
    };
    const [SelectedClientLocationPF, setSelectedClientLocationPF] = useState([]);
    const [SelectedClientLocationESIC, setSelectedClientLocationESIC] = useState([]);
    const [SelectedClientLocationPT, setSelectedClientLocationPT] = useState([]);
    const [SelectedClientLocationFactory, setSelectedClientLocationFactory] = useState([]);

    const handleLocationPF = async (event, option) => {
        const selected = event.target.value;
        console.log(option)
        const selectedLocation = ClientLocationList.find(location => location.LocationID === Number(selected));
        if (selectedLocation) {
            const updatedPFValues = ClientLocationList
                .filter(location => location.LocationID === Number(selected))
                .map(location => ({
                    PFNumber: location.PFNumber,
                    PFMobileNo: location.PFMobileNo,
                    PFUsername: location.PFUsername,
                    PFPassword: decryption(location.PFPassword),
                    PFEmailID: decryption(location.PFEmailID)
                }))[0];

            setData(prevData => ({
                ...prevData,
                ...updatedPFValues
            }));
        }
        setSelectedClientLocationPF([selected])
    };
    const handleLocationESIC = async (event) => {
        const selected = event.target.value;
        const selectedLocation = ClientLocationList.find(location => location.LocationID === Number(selected));
        if (selectedLocation) {
            const updatedPFValues = ClientLocationList
                .filter(location => location.LocationID === Number(selected))
                .map(location => ({
                    ESICUsername: location.ESICUsername,
                    ESICNumber: location.ESICNumber,
                    ESICMobileNo: location.ESICMobileNo,
                    ESICPassword: decryption(location.ESICPassword),
                    ESICEmailID: decryption(location.ESICEmailID)
                }))[0];

            setData(prevData => ({
                ...prevData,
                ...updatedPFValues
            }));
        }
        setSelectedClientLocationESIC([selected])
    };
    const handleLocationPT = async (event) => {
        const selected = event.target.value;
        const selectedLocation = ClientLocationList.find(location => location.LocationID === Number(selected));
        if (selectedLocation) {
            const updatedPFValues = ClientLocationList
                .filter(location => location.LocationID === Number(selected))
                .map(location => ({
                    PTNumber: location.PTNumber,
                    PTEmployeeNo: location.PTEmployeeNo,
                    PTEmployerNo: location.PTEmployerNo,
                }))[0];

            setData(prevData => ({
                ...prevData,
                ...updatedPFValues
            }));
        }
        setSelectedClientLocationPT([selected])
    };
    const handleLocationFactory = async (event) => {
        const selected = event.target.value;
        const selectedLocation = ClientLocationList.find(location => location.LocationID === Number(selected));
        if (selectedLocation) {
            const updatedPFValues = ClientLocationList
                .filter(location => location.LocationID === Number(selected))
                .map(location => ({
                    FactoryLicenseNo: location.FactoryLicenseNo,
                    StartDate: location.StartDate,
                    EndDate: location.EndDate,
                }))[0];

            setData(prevData => ({
                ...prevData,
                ...updatedPFValues
            }));
        }
        setSelectedClientLocationFactory([selected])
    };

    return (
        <Dashboard title={"Add Location"}>
            <Helmet>
                <title>Add Location | J mehta</title>
            </Helmet>
            <ErrorSnackbar errorMessages={ExceptionError} onClearErrors={clearErrors} />
            <SuccessSnackbar successMessages={successMessages} onclearSuccess={clearSuccess} />
            {LoaderShow ? (
                <LoaderComponent />
            ) : (
                <>
                    {ClientAccess ? (
                        <div>
                            <div className="new_client_title mb-3">
                                <Link to='/client_panel'><button>
                                    <AiFillCloseCircle /> Close
                                </button></Link>
                            </div>
                            <hr className='mb-4'></hr>
                            <div>
                                <form onSubmit={handleSubmit} onReset={handleFormReset}>
                                    <div>
                                        <div className="row new_client_form">
                                            <div className="col new_client_form">
                                                <div className="new_client_part_1 w-100">
                                                    <Input2 placeholder="Enter Your PAN Number" label="PAN"
                                                        value={data.PAN} required
                                                        onChange={(e) => handlePANChange(e)} />
                                                    {validationErrors.PAN && <div className="error">{validationErrors.PAN}</div>}
                                                </div>
                                            </div>
                                            <div className="col ">
                                                <div className="new_client_part_1 w-100">
                                                    <div className="toggle_switch_btn gst">
                                                        <h3>Is GST Applicable? {data.IsGSTApplicable && <span style={{ color: 'red' }}>*</span>} </h3>
                                                        <label className="switch">
                                                            <input
                                                                type="checkbox"
                                                                checked={data.IsGSTApplicable}
                                                                onChange={() => {
                                                                    const newValue = !data.IsGSTApplicable;
                                                                    setData(prevData => ({ ...prevData, IsGSTApplicable: newValue }));
                                                                    setValidationErrors(prevErrors => ({
                                                                        ...prevErrors,
                                                                        GSTIN: ''
                                                                    }))
                                                                }}
                                                            />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </div>
                                                    <Input2
                                                        placeholder="Enter Your GST Number"
                                                        disabled={!data.IsGSTApplicable}
                                                        style={{ opacity: data.IsGSTApplicable ? 1 : 0.5 }}
                                                        value={data.IsGSTApplicable ? data.GSTIN : ''}
                                                        onChange={(e) => handleGSTINChange(e)}
                                                    />
                                                    {validationErrors.GSTIN && <div className="error">{validationErrors.GSTIN}</div>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row new_client_form">
                                            <div className="col new_client_form">
                                                <div className="new_client_part_1 w-100">
                                                    <Input2 required
                                                        placeholder="Enter Here" label="Office/Location Name"
                                                        value={data.LocationName}
                                                        onChange={(e) => handleLocationNameChange(e)} />
                                                    {validationErrors.LocationName && <div className="error">{validationErrors.LocationName}</div>}

                                                </div>
                                            </div>
                                            <div className="col ">
                                                <div className="new_client_part_1 w-100">
                                                    <Input2 placeholder="Enter Here" label="Registered Address Line 1"
                                                        value={data.AddLine1} required
                                                        onChange={(e) => handleAddLine1Change(e)} />
                                                    {validationErrors.AddLine1 && <div className="error">{validationErrors.AddLine1}</div>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row new_client_form">
                                            <div className="col new_client_form">
                                                <div className="new_client_part_1 w-100">
                                                    <Input2 required
                                                        placeholder="Enter Here" label="Registered Address Line 2"
                                                        value={data.AddLine2}
                                                        onChange={(e) => handleAddLine2Change(e)} />
                                                    {validationErrors.AddLine2 && <div className="error">{validationErrors.AddLine2}</div>}
                                                </div>
                                            </div>
                                            <div className="col ">
                                                <div className="new_client_part_1 w-100">
                                                    <div class="dashboard_input_feild">
                                                        <h3 className='mb-2'>State <span style={{ color: 'red' }}>*</span> </h3>
                                                        <Autocomplete
                                                            className='mt-0 border-0'
                                                            value={data.StateID ? stateOption.find(option => option.id === data.StateID) || null : null}
                                                            onChange={handleStateIDChange}
                                                            options={stateOption}
                                                            getOptionLabel={(option) => option.name} renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    placeholder='Select State'
                                                                    variant="outlined"
                                                                    InputLabelProps={{ className: "custom-label", }}
                                                                />
                                                            )}
                                                        />
                                                        {validationErrors.StateID && <div className="error mt-1">{validationErrors.StateID}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row new_client_form">
                                            <div className="col new_client_form">
                                                <div className="new_client_part_1 w-100">
                                                    <div class="dashboard_input_feild">
                                                        <h3 className='mb-2'>City <span style={{ color: 'red' }}>*</span> </h3>
                                                        <Autocomplete
                                                            className='mt-0 border-0'
                                                            options={cityOption}
                                                            value={data.CityID ? cityOption.find(option => option.id === data.CityID) || null : null}
                                                            onChange={handleSelectChange2}
                                                            getOptionLabel={(option) => option.name}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    placeholder='Select City'
                                                                    variant="outlined"
                                                                    InputLabelProps={{ className: "custom-label", }}
                                                                />
                                                            )}
                                                        />
                                                        {validationErrors.CityID && <div className="error mt-1">{validationErrors.CityID}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col ">
                                                <div className="new_client_part_1 w-100">
                                                    <Input2 placeholder="Enter Here" label="Pin-code"
                                                        value={data.Pincode} required
                                                        onChange={(e) => handlePincodeChange(e)} />
                                                    {validationErrors.Pincode && <div className="error">{validationErrors.Pincode}</div>}
                                                </div>
                                            </div>
                                        </div>

                                        <hr />
                                        <div className="toggle_switch_btn">
                                            <h3>Is PF Applicable?</h3>
                                            <label class="switch">
                                                <input type="checkbox" checked={data.IsPFApplicable}
                                                    onChange={() => {
                                                        const newValue = !data.IsPFApplicable;
                                                        // setData(prevData => ({ ...prevData, IsPFApplicable: newValue }));

                                                        if (data.IsPFApplicable) {
                                                            setData(prevData => ({ ...prevData, IsPFDuplicate: false }));
                                                            setData(prevData => ({ ...prevData, IsPFApplicable: newValue }));
                                                        } else {
                                                            setData(prevData => ({ ...prevData, IsPFApplicable: newValue }));
                                                        }

                                                        setValidationErrors(prevErrors => ({
                                                            ...prevErrors,
                                                            PFNumber: '', PFPassword: '', PFMobileNo: "", PFUsername: "", PFEmailID: ""
                                                        }))
                                                    }}
                                                />
                                                <span class="slider round"></span>
                                            </label>
                                            <h3 className='ms-4'>Is Same PF Applicable?</h3>
                                            <label class="switch">
                                                <input
                                                    type="checkbox"
                                                    checked={data.IsPFDuplicate} // Set to data.IsPFDuplicate if data.IsPFApplicable is true, otherwise false
                                                    onChange={() => {
                                                        const newValue = !data.IsPFDuplicate;
                                                        if (!data.IsPFApplicable) {
                                                            setData(prevData => ({ ...prevData, IsPFDuplicate: false }));
                                                        } else {
                                                            setData(prevData => ({ ...prevData, IsPFDuplicate: newValue }));
                                                        }
                                                    }}
                                                />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                        {data.IsPFDuplicate ?
                                            <div className="row new_client_form">
                                                <div className="col new_client_form">
                                                    <div className="new_client_part_1 w-100">
                                                        <div className="dashboard_input_feild mb-0">
                                                            <h3 className='mb-0'>Location List <span style={{ color: 'red' }}>*</span></h3>
                                                        </div>
                                                        <select className='py-0 me_height'
                                                            value={SelectedClientLocationPF}
                                                            selectedOptions={SelectedClientLocationPF}
                                                            // placeholder='Select Location'
                                                            onChange={handleLocationPF}>
                                                            <option value="">Select Location</option>
                                                            {ClientLocationOption.map(option => (
                                                                <option key={option.value} value={option.value}>{option.label}</option>
                                                            ))}
                                                        </select>
                                                        {/* <div class="dashboard_input_feild">
                                                            <h3 className='mb-2'>Location <span style={{ color: 'red' }}>*</span> </h3>
                                                            <Autocomplete
                                                                className='mt-0 border-0'
                                                                options={ClientLocationOption}
                                                                value={ClientLocationOption.find(option => option.value === SelectedClientLocationPF)}
                                                                onChange={handleLocationPF}
                                                                getOptionLabel={(option) => option.label}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder='Select Location'
                                                                        variant="outlined"
                                                                        InputLabelProps={{ className: "custom-label", }}
                                                                    />
                                                                )}
                                                            />
                                                            {validationErrors.CityID && <div className="error mt-1">{validationErrors.CityID}</div>}
                                                        </div> */}

                                                        {/* {validationErrors.LegalFilingStatus && <div className="error">{validationErrors.LegalFilingStatus}</div>} */}
                                                    </div>
                                                </div>

                                                <div className="col ">
                                                    <div className="new_client_part_1 w-100">

                                                    </div>
                                                </div>
                                            </div>
                                            : ''}

                                        <div className="row new_client_form">
                                            <div className="col new_client_form">
                                                <div className="new_client_part_1 w-100">
                                                    <Input2 placeholder="Enter Here" label="PF Number" disabled={data.IsPFDuplicate || !data.IsPFApplicable} required={data.IsPFApplicable}
                                                        style={{ opacity: data.IsPFApplicable ? 1 : 0.5 }}
                                                        value={data.IsPFApplicable || data.IsPFDuplicate ? data.PFNumber : ''}
                                                        // value={data.IsPFApplicable ? data.PFNumber : ''}
                                                        onChange={(e) => handlePFNumberChange(e)} />
                                                    {validationErrors.PFNumber && <div className="error">{validationErrors.PFNumber}</div>}
                                                </div>
                                            </div>
                                            <div className="col ">
                                                <div className="new_client_part_1 w-100">
                                                    <Input2 placeholder="Enter Here" label="PF Username" required={data.IsPFApplicable}
                                                        // disabled={!data.IsPFApplicable}
                                                        disabled={data.IsPFDuplicate || !data.IsPFApplicable}
                                                        style={{ opacity: data.IsPFApplicable ? 1 : 0.5 }}
                                                        value={data.IsPFApplicable || data.IsPFDuplicate ? data.PFUsername : ''}
                                                        // value={data.IsPFApplicable ? data.PFUsername : ''}
                                                        onChange={(e) => handlePFUsernameChange(e)} />
                                                    {validationErrors.PFUsername && <div className="error">{validationErrors.PFUsername}</div>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row new_client_form">
                                            <div className="col new_client_form">
                                                <div className="new_client_part_1 w-100 position-relative">
                                                    <Input2 placeholder="Enter Here" label="PF Password" disabled={data.IsPFDuplicate || !data.IsPFApplicable} required={data.IsPFApplicable}
                                                        type="password"
                                                        style={{ opacity: data.IsPFApplicable ? 1 : 0.5 }}
                                                        value={data.IsPFApplicable || data.IsPFDuplicate ? data.PFPassword : ''}
                                                        // value={data.IsPFApplicable ? data.PFPassword : ''}
                                                        onChange={(e) => handlePFPasswordChange(e)}
                                                        showPassword={data.IsPFApplicable ? showPasswordPF : 'd-none'} // Pass showPassword state to Input2
                                                        togglePasswordVisibility={togglePasswordPF}
                                                    />
                                                    {/* {data.IsPFApplicable ?
                                                        <div
                                                            className="password-toggleUser top-40 right-6"
                                                            onClick={togglePasswordPF}
                                                        >
                                                            {showPasswordPF ? (
                                                                <FaEyeSlash />
                                                            ) : (
                                                                <FaRegEye />
                                                            )}
                                                        </div>
                                                        : ''} */}
                                                    {validationErrors.PFPassword && <div className="error">{validationErrors.PFPassword}</div>}
                                                </div>
                                            </div>
                                            <div className="col ">
                                                <div className="new_client_part_1 w-100">
                                                    <Input2 placeholder="Enter Here" label="PF Email ID" disabled={data.IsPFDuplicate || !data.IsPFApplicable}
                                                        style={{ opacity: data.IsPFApplicable ? 1 : 0.5 }} required={data.IsPFApplicable}
                                                        value={data.IsPFApplicable || data.IsPFDuplicate ? data.PFEmailID : ''}
                                                        // value={data.IsPFApplicable ? data.PFEmailID : ''}
                                                        onChange={(e) => handlePFEmailIDChange(e)} />
                                                    {validationErrors.PFEmailID && <div className="error">{validationErrors.PFEmailID}</div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row new_client_form">
                                            <div className="col new_client_form">
                                                <div className="new_client_part_1 w-100">
                                                    <Input2 placeholder="Enter Here" label="PF Mobile No." disabled={data.IsPFDuplicate || !data.IsPFApplicable} required={data.IsPFApplicable}
                                                        style={{ opacity: data.IsPFApplicable ? 1 : 0.5 }}
                                                        value={data.IsPFApplicable || data.IsPFDuplicate ? data.PFMobileNo : ''}
                                                        // value={data.IsPFApplicable ? data.PFMobileNo : ''}
                                                        onChange={(e) => handlePFMobileNoChange(e)} />
                                                    {validationErrors.PFMobileNo && <div className="error">{validationErrors.PFMobileNo}</div>}
                                                </div>
                                            </div>
                                            <div className="col new_client_form">
                                                <div className="new_client_part_1 w-100">
                                                </div>
                                            </div>
                                        </div>

                                        <hr />
                                        <div className="toggle_switch_btn">
                                            <h3>Is ESIC Applicable?</h3>
                                            <label class="switch">
                                                <input
                                                    type="checkbox"
                                                    checked={data.IsESICApplicable}
                                                    onChange={() => {
                                                        const newValue = !data.IsESICApplicable;
                                                        // setData(prevData => ({ ...prevData, IsESICApplicable: newValue }));

                                                        if (data.IsESICApplicable) {
                                                            setData(prevData => ({ ...prevData, IsESICDuplicate: false }));
                                                            setData(prevData => ({ ...prevData, IsESICApplicable: newValue }));
                                                        } else {
                                                            setData(prevData => ({ ...prevData, IsESICApplicable: newValue }));
                                                        }

                                                        setValidationErrors(prevErrors => ({
                                                            ...prevErrors,
                                                            PFNumber: '', PFPassword: '', PFMobileNo: "", PFUsername: "", PFEmailID: ""
                                                        }))
                                                    }}
                                                />
                                                <span class="slider round"></span>
                                            </label>
                                            <h3 className='ms-4'>Is Same ESIC Applicable?</h3>
                                            <label class="switch">
                                                <input
                                                    type="checkbox"
                                                    checked={data.IsESICDuplicate} // Set to data.IsPFDuplicate if data.IsPFApplicable is true, otherwise false
                                                    onChange={() => {
                                                        const newValue = !data.IsESICDuplicate;
                                                        if (!data.IsESICApplicable) {
                                                            setData(prevData => ({ ...prevData, IsESICDuplicate: false }));
                                                        } else {
                                                            setData(prevData => ({ ...prevData, IsESICDuplicate: newValue }));
                                                        }
                                                    }}
                                                />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                        {data.IsESICDuplicate ?
                                            <div className="row new_client_form">
                                                <div className="col new_client_form">
                                                    <div className="new_client_part_1 w-100">
                                                        <div className="dashboard_input_feild mb-0">
                                                            <h3 className='mb-0'>Location List <span style={{ color: 'red' }}>*</span></h3>
                                                        </div>
                                                        <select className='py-0 me_height'
                                                            value={SelectedClientLocationESIC}
                                                            selectedOptions={SelectedClientLocationESIC}
                                                            onChange={handleLocationESIC}>
                                                            <option value="">Select Location</option>
                                                            {ClientLocationOption.map(option => (
                                                                <option key={option.value} value={option.value}>{option.label}</option>
                                                            ))}
                                                        </select>
                                                        {/* {validationErrors.LegalFilingStatus && <div className="error">{validationErrors.LegalFilingStatus}</div>} */}
                                                    </div>
                                                </div>

                                                <div className="col ">
                                                    <div className="new_client_part_1 w-100">

                                                    </div>
                                                </div>
                                            </div>
                                            : ''}

                                        <div className="row new_client_form">
                                            <div className="col new_client_form">
                                                <div className="new_client_part_1 w-100">
                                                    <Input2 placeholder="Enter Here" label="ESIC Number" disabled={data.IsESICDuplicate || !data.IsESICApplicable}
                                                        style={{ opacity: data.IsESICApplicable ? 1 : 0.5 }} required={data.IsESICApplicable}
                                                        value={data.IsESICApplicable || data.IsESICDuplicate ? data.ESICNumber : ''}
                                                        // value={data.IsESICApplicable ? data.ESICNumber : ''}
                                                        onChange={(e) => handleESICNumberChange(e)} />
                                                    {validationErrors.ESICNumber && <div className="error">{validationErrors.ESICNumber}</div>}
                                                </div>
                                            </div>
                                            <div className="col ">
                                                <div className="new_client_part_1 w-100">
                                                    <Input2 placeholder="Enter Here" label="ESIC Username" disabled={data.IsESICDuplicate || !data.IsESICApplicable}
                                                        style={{ opacity: data.IsESICApplicable ? 1 : 0.5 }} required={data.IsESICApplicable}
                                                        value={data.IsESICApplicable || data.IsESICDuplicate ? data.ESICUsername : ''}
                                                        // value={data.IsESICApplicable ? data.ESICUsername : ''}
                                                        onChange={(e) => handleESICUsernameChange(e)} />
                                                    {validationErrors.ESICUsername && <div className="error">{validationErrors.ESICUsername}</div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row new_client_form">
                                            <div className="col new_client_form">
                                                <div className="new_client_part_1 w-100 position-relative">
                                                    <Input2 placeholder="Enter Here" label="ESIC Password" disabled={data.IsESICDuplicate || !data.IsESICApplicable}
                                                        style={{ opacity: data.IsESICApplicable ? 1 : 0.5 }} required={data.IsESICApplicable}
                                                        type="password"
                                                        value={data.IsESICApplicable || data.IsESICDuplicate ? data.ESICPassword : ''}
                                                        // value={data.IsESICApplicable ? data.ESICPassword : ''}
                                                        onChange={(e) => handleESICPasswordChange(e)}
                                                        showPassword={data.IsESICApplicable ? showPasswordESIC : 'd-none'} // Pass showPassword state to Input2
                                                        togglePasswordVisibility={togglePasswordESIC}
                                                    />
                                                    {/* {data.IsESICApplicable ?
                                                        <div
                                                            className="password-toggleUser top-40"
                                                            onClick={togglePasswordESIC}
                                                        >
                                                            {showPasswordESIC ? (
                                                                <FaEyeSlash />
                                                            ) : (
                                                                <FaRegEye />
                                                            )}
                                                        </div>
                                                        : ''} */}
                                                    {validationErrors.ESICPassword && <div className="error">{validationErrors.ESICPassword}</div>}
                                                </div>
                                            </div>
                                            <div className="col ">
                                                <div className="new_client_part_1 w-100">
                                                    <Input2 placeholder="Enter Here" label="ESIC Email ID" disabled={data.IsESICDuplicate || !data.IsESICApplicable}
                                                        style={{ opacity: data.IsESICApplicable ? 1 : 0.5 }} required={data.IsESICApplicable}
                                                        value={data.IsESICApplicable || data.IsESICDuplicate ? data.ESICEmailID : ''}
                                                        // value={data.IsESICApplicable ? data.ESICEmailID : ''}
                                                        onChange={(e) => handleESICEmailIDChange(e)} />
                                                    {validationErrors.ESICEmailID && <div className="error">{validationErrors.ESICEmailID}</div>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row new_client_form">
                                            <div className="col new_client_form">
                                                <div className="new_client_part_1 w-100">
                                                    <Input2 placeholder="Enter Here" label="ESIC Mobile No" disabled={data.IsESICDuplicate || !data.IsESICApplicable}
                                                        style={{ opacity: data.IsESICApplicable ? 1 : 0.5 }} required={data.IsESICApplicable}
                                                        value={data.IsESICApplicable || data.IsESICDuplicate ? data.ESICMobileNo : ''}
                                                        // value={data.IsESICApplicable ? data.ESICMobileNo : ''}
                                                        onChange={(e) => handleESICMobileNoChange(e)} />
                                                    {validationErrors.ESICMobileNo && <div className="error">{validationErrors.ESICMobileNo}</div>}
                                                </div>
                                            </div>
                                            <div className="col ">
                                                <div className="new_client_part_1 w-100">
                                                </div>
                                            </div>
                                        </div>

                                        <hr />
                                        <div className="toggle_switch_btn">
                                            <h3>Is PT Applicable?</h3>
                                            <label class="switch">
                                                <input type="checkbox" checked={data.IsPTApplicable}
                                                    onChange={() => {
                                                        const newValue = !data.IsPTApplicable;
                                                        // setData(prevData => ({ ...prevData, IsPTApplicable: newValue }));

                                                        if (data.IsPTApplicable) {
                                                            setData(prevData => ({ ...prevData, IsPTDuplicate: false }));
                                                            setData(prevData => ({ ...prevData, IsPTApplicable: newValue }));
                                                        } else {
                                                            setData(prevData => ({ ...prevData, IsPTApplicable: newValue }));
                                                        }
                                                        setValidationErrors(prevErrors => ({
                                                            ...prevErrors,
                                                            PTNumber: '', PTEmployerNo: '', PTEmployeeNo: "" // Clear the error message
                                                        }))
                                                    }} />
                                                <span class="slider round"></span>
                                            </label>
                                            <h3 className='ms-4'>Is Same PT Applicable?</h3>
                                            <label class="switch">
                                                <input
                                                    type="checkbox"
                                                    checked={data.IsPTDuplicate} // Set to data.IsPFDuplicate if data.IsPFApplicable is true, otherwise false
                                                    onChange={() => {
                                                        const newValue = !data.IsPTDuplicate;
                                                        if (!data.IsPTApplicable) {
                                                            setData(prevData => ({ ...prevData, IsPTDuplicate: false }));
                                                        } else {
                                                            setData(prevData => ({ ...prevData, IsPTDuplicate: newValue }));
                                                        }
                                                    }}
                                                />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                        {data.IsPTDuplicate ?
                                            <div className="row new_client_form">
                                                <div className="col new_client_form">
                                                    <div className="new_client_part_1 w-100">
                                                        <div className="dashboard_input_feild mb-0">
                                                            <h3 className='mb-0'>Is PT Location <span style={{ color: 'red' }}>*</span></h3>
                                                        </div>
                                                        <select className='py-0 me_height'
                                                            value={SelectedClientLocationPT}
                                                            selectedOptions={SelectedClientLocationPT}
                                                            onChange={handleLocationPT}>
                                                            <option value="">Select Location</option>
                                                            {ClientLocationOption.map(option => (
                                                                <option key={option.value} value={option.value}>{option.label}</option>
                                                            ))}
                                                        </select>
                                                        {/* {validationErrors.LegalFilingStatus && <div className="error">{validationErrors.LegalFilingStatus}</div>} */}
                                                    </div>
                                                </div>

                                                <div className="col ">
                                                    <div className="new_client_part_1 w-100">

                                                    </div>
                                                </div>
                                            </div>
                                            : ''}

                                        <div className="row new_client_form">
                                            <div className="col new_client_form">
                                                <div className="new_client_part_1 w-100">
                                                    <Input2 placeholder="Enter Here" label="Professional Tax Registration Number"
                                                        disabled={data.IsPTDuplicate || !data.IsPTApplicable} required={data.IsPTApplicable}
                                                        style={{ opacity: data.IsPTApplicable ? 1 : 0.5 }}
                                                        value={data.IsPTApplicable || data.IsPTDuplicate ? data.PTNumber : ''}
                                                        // value={data.IsPTApplicable ? data.PTNumber : ''}
                                                        onChange={(e) => handlePTNumberChange(e)} />
                                                    {validationErrors.PTNumber && <div className="error">{validationErrors.PTNumber}</div>}
                                                </div>
                                            </div>
                                            <div className="col ">
                                                <div className="new_client_part_1 w-100">
                                                    <Input2 placeholder="Enter Here" label="PT Employer No" disabled={data.IsPTDuplicate || !data.IsPTApplicable}
                                                        style={{ opacity: data.IsPTApplicable ? 1 : 0.5 }} required={data.IsPTApplicable}
                                                        value={data.IsPTApplicable || data.IsPTDuplicate ? data.PTEmployerNo : ''}
                                                        // value={data.IsPTApplicable ? data.PTEmployerNo : ''}
                                                        onChange={(e) => handlePTEmployerNoChange(e)} />
                                                    {validationErrors.PTEmployerNo && <div className="error">{validationErrors.PTEmployerNo}</div>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row new_client_form">
                                            <div className="col new_client_form">
                                                <div className="new_client_part_1 w-100">
                                                    <Input2 placeholder="Enter Here" label="PT Employee No" disabled={data.IsPTDuplicate || !data.IsPTApplicable}
                                                        style={{ opacity: data.IsPTApplicable ? 1 : 0.5 }} required={data.IsPTApplicable}
                                                        value={data.IsPTApplicable || data.IsPTDuplicate ? data.PTEmployeeNo : ''}
                                                        // value={data.IsPTApplicable ? data.PTEmployeeNo : ''}
                                                        onChange={(e) => handlePTEmployeeNoChange(e)} />
                                                    {validationErrors.PTEmployeeNo && <div className="error">{validationErrors.PTEmployeeNo}</div>}
                                                </div>
                                            </div>
                                            <div className="col ">
                                                <div className="new_client_part_1 w-100">
                                                </div>
                                            </div>
                                        </div>

                                        <hr />
                                        <div className="toggle_switch_btn">
                                            <h3>Is Factory License Applicable?</h3>
                                            <label class="switch">
                                                <input
                                                    type="checkbox"
                                                    checked={data.IsFactoryLicenseApplicable}
                                                    onChange={() => {
                                                        const newValue = !data.IsFactoryLicenseApplicable;
                                                        // setData(prevData => ({ ...prevData, IsFactoryLicenseApplicable: newValue }));

                                                        if (data.IsFactoryLicenseApplicable) {
                                                            setData(prevData => ({ ...prevData, IsFactoryDuplicate: false }));
                                                            setData(prevData => ({ ...prevData, IsFactoryLicenseApplicable: newValue }));
                                                        } else {
                                                            setData(prevData => ({ ...prevData, IsFactoryLicenseApplicable: newValue }));
                                                        }
                                                        setValidationErrors(prevErrors => ({
                                                            ...prevErrors,
                                                            EndDate: '', StartDate: '', FactoryLicenseNo: ""
                                                        }))
                                                    }}
                                                />
                                                <span class="slider round"></span>
                                            </label>
                                            <h3 className='ms-4'>Is Same Factory Applicable?</h3>
                                            <label class="switch">
                                                <input
                                                    type="checkbox"
                                                    checked={data.IsFactoryDuplicate} // Set to data.IsPFDuplicate if data.IsPFApplicable is true, otherwise false
                                                    onChange={() => {
                                                        const newValue = !data.IsFactoryDuplicate;
                                                        if (!data.IsFactoryLicenseApplicable) {
                                                            setData(prevData => ({ ...prevData, IsFactoryDuplicate: false }));
                                                        } else {
                                                            setData(prevData => ({ ...prevData, IsFactoryDuplicate: newValue }));
                                                        }
                                                    }}
                                                />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                        {data.IsFactoryDuplicate ?
                                            <div className="row new_client_form">
                                                <div className="col new_client_form">
                                                    <div className="new_client_part_1 w-100">
                                                        <div className="dashboard_input_feild mb-0">
                                                            <h3 className='mb-0'>Location List <span style={{ color: 'red' }}>*</span></h3>
                                                        </div>
                                                        <select className='py-0 me_height'
                                                            value={SelectedClientLocationFactory}
                                                            selectedOptions={SelectedClientLocationFactory}
                                                            onChange={handleLocationFactory}>
                                                            <option value="">Select Location</option>
                                                            {ClientLocationOption.map(option => (
                                                                <option key={option.value} value={option.value}>{option.label}</option>
                                                            ))}
                                                        </select>
                                                        {/* {validationErrors.LegalFilingStatus && <div className="error">{validationErrors.LegalFilingStatus}</div>} */}
                                                    </div>
                                                </div>

                                                <div className="col ">
                                                    <div className="new_client_part_1 w-100">

                                                    </div>
                                                </div>
                                            </div>
                                            : ''}

                                        <div className="row new_client_form">
                                            <div className="col new_client_form">
                                                <div className="new_client_part_1 w-100">
                                                    <Input2 required={data.IsFactoryLicenseApplicable}
                                                        placeholder="Enter Here"
                                                        label="Factory License No"
                                                        disabled={data.IsFactoryDuplicate || !data.IsFactoryLicenseApplicable}
                                                        style={{ opacity: data.IsFactoryLicenseApplicable ? 1 : 0.5 }}
                                                        value={data.IsFactoryLicenseApplicable || data.IsFactoryDuplicate ? data.FactoryLicenseNo : ''}
                                                        // value={data.IsFactoryLicenseApplicable ? data.FactoryLicenseNo : ''}
                                                        onChange={(e) => handleFactoryLicenseNoChange(e)} />
                                                    {validationErrors.FactoryLicenseNo && <div className="error">{validationErrors.FactoryLicenseNo}</div>}
                                                </div>
                                            </div>
                                            <div className="col ">
                                                <div className="new_client_part_1 w-100">
                                                    <div className='dashboard_input_feild client_locationDate'>
                                                        <div>
                                                            <h3 htmlFor="startDate">Start Date {data.IsFactoryLicenseApplicable && <span style={{ color: 'red' }}>*</span>} </h3>
                                                        </div>
                                                        <DatePicker
                                                            id="startDate"
                                                            placeholderText="Enter Here"
                                                            selected={data.IsFactoryLicenseApplicable || data.IsFactoryDuplicate ? data.StartDate : ''} // Pass Date object directly
                                                            // selected={data.IsFactoryLicenseApplicable ? data.StartDate : ''} // Pass Date object directly
                                                            onChange={handleStartDate}
                                                            dateFormat="dd/MM/yyyy"
                                                            disabled={data.IsFactoryDuplicate || !data.IsFactoryLicenseApplicable}
                                                            style={{ opacity: data.IsFactoryLicenseApplicable ? 1 : 0.5 }}
                                                        />
                                                    </div>
                                                    {validationErrors.StartDate && <div className="error">{validationErrors.StartDate}</div>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row new_client_form">
                                            <div className="col new_client_form">
                                                <div className="new_client_part_1 w-100">
                                                    <div className='dashboard_input_feild client_locationDate'>
                                                        <div>
                                                            <h3 htmlFor="endDate">End Date {data.IsFactoryLicenseApplicable && <span style={{ color: 'red' }}>*</span>} </h3>
                                                        </div>
                                                        <DatePicker
                                                            id="endDate"
                                                            placeholderText="Enter Here"
                                                            selected={data.IsFactoryLicenseApplicable || data.IsFactoryDuplicate ? data.EndDate : ''} // Pass Date object directly
                                                            // selected={data.IsFactoryLicenseApplicable ? data.EndDate : ''} // Pass Date object directly
                                                            onChange={handleEndDate}
                                                            minDate={data.StartDate} // Use Date object directly
                                                            dateFormat="dd/MM/yyyy"
                                                            disabled={data.IsFactoryDuplicate || !data.IsFactoryLicenseApplicable}
                                                            style={{ opacity: data.IsFactoryLicenseApplicable ? 1 : 0.5 }}
                                                        />
                                                    </div>
                                                    {validationErrors.EndDate && <div className="error">{validationErrors.EndDate}</div>}
                                                </div>
                                            </div>
                                            <div className="col ">
                                                <div className="new_client_part_1 w-100">
                                                </div>
                                            </div>
                                        </div>                                    </div>

                                    <div className="btn_save btn-right me-0 d-flex justify-content-end">
                                        <button type='reset' className="tab1 save_button me-4">
                                            <img src="../img/clockwise.svg" />
                                            Reset
                                        </button>
                                        {(ClientID === undefined && (<button type='submit' disabled={disabledBtn} onClick={() => setButtonClicked('saveAndMore')} className="tab1 save_button me-4">
                                            <>
                                                <img src="../img/Save.svg" />
                                                Save & More
                                                {LoaderShow2 && buttonClicked === 'saveAndMore' && (
                                                    <LoaderComponent2 />
                                                )}
                                            </>
                                        </button>))}
                                        <button className="tab1 save_button" disabled={disabledBtn} onClick={() => setButtonClicked('submit')} type="submit">
                                            <>
                                                <img src="../img/Save.svg" />
                                                {(ClientID === undefined ? 'Save' : 'Update')}
                                                {LoaderShow2  && (
                                                    <LoaderComponent2 />
                                                )}
                                            </>
                                        </button>
                                    </div>
                                </form>
                            </div >
                        </div>
                    ) : (
                        <NoPermission />
                    )}
                </>
            )}
        </Dashboard >
    );
}
