// App.js
import React, { useState, useEffect, useRef } from "react";
import Dashboard from "../components/dashboard.js";
import Modal from "react-modal";
import { AiFillPlusCircle } from "react-icons/ai";
import { Helmet } from "react-helmet";
import {
  Department_entry_get,
  Department_configiration_update,
  Delete_department_by_id,
  Department_export_api,
  UploadFile_api,
  ImportDepartment_api,
  ImportDepartment_api_final,
  User_PageAccess,
} from "../service/api.js";
import { Link, useNavigate } from "react-router-dom";
import CustomPagination from "../components/CustomPagination.js";
import ErrorSnackbar from "./../components/ErrorSnackbar.js";
import SuccessSnackbar from "./../components/SuccessSnackbar.js";
import EditActionBtn from "../components/EditActionBtn.js";
import DeleteActionBtn from "../components/DeleteActionBtn.js";
import Swal from "sweetalert2";
import NoPermission from "../components/NoPermission.js";
import LoaderComponent from "../components/LoaderComponent.js";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Department_list = () => {
  const [LoaderShow, setLoaderShow] = useState(true);
  var searchData = "";
  var currentPageItem = 1;
  var recordPerPageItem = 10;
  const [depa_list, setDepaList] = useState([]);
  const [isOptionsDropdownOpen, setIsOptionsDropdownOpen] = useState(false);
  const [isColumnChooserDropdownOpen, setIsColumnChooserDropdownOpen] =
    useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [ExceptionError, setExceptionError] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [columns, setColumns] = useState([]);
  const [reason, setReason] = useState(false);
  const [StoreFileName, setStoreFileName] = useState("");
  const [draggedColumn, setDraggedColumn] = useState(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const [ViewAccess, setViewAccess] = useState(true);
  const [AllAccess, setAllAccessData] = useState({});

  const isAllowUpdate = () => {
    return AllAccess.AllowUpdate === "1" && AllAccess.AllowView === "1";
  };

  const isAllowDelete = () => {
    return AllAccess.AllowDelete === "1" && AllAccess.AllowView === "1";
  };

  const isAllowAdd = () => {
    return AllAccess.AllowAdd === "1";
  };

  const shouldRenderTh =
    AllAccess.AllowView === "1" &&
    AllAccess.AllowDelete === "0" &&
    AllAccess.AllowUpdate === "0";

  const isEffectExecutedRef = useRef(false);
  useEffect(() => {
    if (!isEffectExecutedRef.current) {
      PageAccess();
      isEffectExecutedRef.current = true;
    }
  }, []);

  const PageAccess = async () => {
    try {
      const res = await User_PageAccess("2");
      if (res.status == 200) {
        if (res.data.AllowView === true) {
          fetchData();
        } else {
          setLoaderShow(false);
        }
        const accessData = {
          AllowView: res.data.AllowView ? "1" : "0",
          AllowAdd: res.data.AllowAdd ? "1" : "0",
          AllowDelete: res.data.AllowDelete ? "1" : "0",
          AllowUpdate: res.data.AllowUpdate ? "1" : "0",
        };
        setViewAccess(res.data.AllowView);
        setAllAccessData(accessData);
      } else if (res.status === 400) {
        if (res.data.ErrorCode) {
          const validationErrorMessage = res.data.ErrorMessage;
          const errorMessagesArray = validationErrorMessage.split(", ");

          errorMessagesArray.forEach((errorMessage) => {
            const [, fieldName, errorMessageText] =
              errorMessage.match(/\"(.*?)\" (.*)/);
            handleExceptionError(`${fieldName} - ${errorMessageText}`);
          });
        }
        if (res.data.Message) {
          handleExceptionError(res.data.Message);
        }
      } else if (res.status === 401) {
        handleExceptionError("Unauthorized");
        setTimeout(() => {
          localStorage.clear();
          navigate("/");
        }, 1000);
        ///logout();
      } else if (res.status === 200) {
        var dataError = res.data.Errors;
        dataError.map((message, index) => {
          handleExceptionError(message.Message);
        });
      } else if (res.status === 500) {
        handleExceptionError(res.statusText);
      }
    } catch (error) {
      handleExceptionError(error.message);
    } finally {
      // setLoaderShow(false);
    }
  };

  function handleExceptionError(res) {
    setExceptionError((ExceptionError) => [
      ...ExceptionError,
      { id: Date.now(), message: res },
    ]);
  }
  function handleExceptionSuccessMessages(resp) {
    setSuccessMessages((successMessages) => [
      ...successMessages,
      { id: Date.now(), message: resp },
    ]);
  }
  function clearErrors(id) {
    setExceptionError((prevMessages) =>
      prevMessages.filter((msg) => msg.id !== id)
    );
  }
  function clearSuccess(id) {
    setSuccessMessages((prevMessages) =>
      prevMessages.filter((msg) => msg.id !== id)
    );
  }
  const handleDragStart = (e, index) => {
    setDraggedColumn(index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
  };
  const handleDrop = (e, index) => {
    console.log(e);
    e.preventDefault();
    const newColumns = [...columns];
    const draggedItem = newColumns[draggedColumn];
    newColumns.splice(draggedColumn, 1);
    newColumns.splice(index, 0, draggedItem);
    setColumns(newColumns);
    setDraggedColumn(null);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    currentPageItem = page;
    fetchData();
  };
  const fetchData = async () => {
    try {
      var dataObject = {
        filter: searchData,
        page: currentPageItem,
        limit: recordPerPageItem,
      };
      const res = await Department_entry_get(dataObject);

      if (res) {
        if (res.status === 200) {
          var logConfig = res.data.DepartmentLogConfig;
          var logColumns = res.data.DepartmentLogColumns;
          var newColumnArray = [];

          if (logConfig && logConfig.length > 0) {
            logColumns.forEach((column) => {
              if (column.Status === "Config") {
                let configMatch = logConfig.find(
                  (config) => config.ColumnName === column.ColumnName
                );
                if (configMatch) {
                  column.IsActive = configMatch.IsActive;
                  column.Priority = configMatch.Priority;
                  if (configMatch.Alias) {
                    column.Alias = configMatch.Alias;
                  }
                }
                newColumnArray.push({ ...column });
                newColumnArray = sortByPriority(newColumnArray);
              }
            });
          } else {
            logColumns.forEach((data) => {
              if (data.Status === "Config") {
                data.IsActive = true;
                newColumnArray.push(data);
              }
            });
          }

          setDepaList(res.data.data);
          console.log(res.data.data, "DepartList");
          setTotalPage(res.data.totalAllData);
          setColumns(newColumnArray);
          console.log(newColumnArray, "columnList");
          setReason(false);
          setLoaderShow(false);
        } else if (res.status === 400) {
          handleExceptionError(res.data.ErrorMessage);
        } else if (res.status === 401) {
          handleExceptionError("Unauthorized");
          setTimeout(() => {
            localStorage.clear();
            navigate("/");
          }, 1000);
          ///logout();
        } else if (res.status === 500) {
          handleExceptionError(res.data.message);
        }
      } else {
        handleExceptionError(res.data.message);
      }
    } catch (error) {
      handleExceptionError(error.message);
    }
  };

  const sortByPriority = (dataArray) => {
    return dataArray.sort((a, b) => {
      return parseInt(a.Priority) - parseInt(b.Priority);
    });
  };
  const handleCheckBoxChange = (index) => {
    const updatedColumns = [...columns];
    updatedColumns[index].IsActive = !updatedColumns[index].IsActive;
    setColumns(updatedColumns); // Check if this line is causing an infinite loop
    console.log(columns);
  };

  const handleSearchBox = (event) => {
    const value = event.target.value;
    setSearch(value);
    searchData = value;
    fetchData();
  };

  const handleConfigurationSave = async () => {
    var newData = [];
    columns.map((columnData, index) => {
      var oj = {
        ColumnName: columnData.ColumnName,
        Priority: index + 1,
        IsActive: columnData.IsActive,
      };
      newData.push(oj);
    });

    try {
      const res = await Department_configiration_update(newData);
      if (res.status == 200) {
        handleExceptionSuccessMessages(res.data.message);
        setIsColumnChooserDropdownOpen(false);
      } else if (res.status === 401) {
        handleExceptionError("Unauthorized");
        setTimeout(() => {
          localStorage.clear();
          navigate("/");
        }, 1000);
        ///logout();
      } else {
        handleExceptionError(res.statusText);
      }
    } catch (error) {
      handleExceptionError(error.message);
    }
  };
  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    setRecordPerPage(newItemsPerPage);
    recordPerPageItem = newItemsPerPage;
    fetchData();
  };

  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }
  const DeleteDepartment = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this data!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await Delete_department_by_id(id);
          if (res) {
            if (res.status === 200) {
              handleExceptionSuccessMessages(res.data.message);
              fetchData();
              // PageAccess()
              console.log(fetchData);
            } else if (res.status === 400) {
              if (res.data.ErrorCode) {
                handleExceptionError(res.data.ErrorMessage);
              }
              if (res.data.Message) {
                handleExceptionError(res.data.Message);
              }
            } else if (res.status === 401) {
              handleExceptionError("Unauthorized");
              setTimeout(() => {
                localStorage.clear();
                navigate("/");
              }, 1000);
              ///logout();
            } else if (res.status === 500) {
              handleExceptionError(res.statusText);
            }
          }
        } catch (error) {
          handleExceptionError(error.message);
        }
      }
    });
  };
  const ExportDepartment = async () => {
    try {
      const res = await Department_export_api();
      if (res.status == 200) {
        fetch(res.data)
        .then(response => response.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'DepartmentDemoRpt.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        })
        .catch(error => {
            console.error('Error fetching the file:', error);
        });
        // const url = res.data;
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", "data.csv");
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        // URL.revokeObjectURL(url);
        handleExceptionSuccessMessages(res.data.message);
      } else if (res.status === 401) {
        handleExceptionError("Unauthorized");
        setTimeout(() => {
          localStorage.clear();
          navigate("/");
        }, 1000);
        ///logout();
      } else {
        handleExceptionError(res.statusText);
      }
    } catch (error) {
      handleExceptionError(error.message);
    }
  };
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0]; // Get the first file from the FileList
    if (file) {
      console.log("Selected file:", file);
      try {
        const res = await UploadFile_api(file);
        event.target.value = "";
        if (res.status == 200) {
          var FileName = res.data[0][0].filename;
          setStoreFileName(FileName);
          var result = await ImportDepartment_api(FileName);
          if (result.status == 200) {
            handleExceptionSuccessMessages(res.data.message);
            fetchData();
          } else if (result.status == 400) {
            setDepaList(result.data.ErrorMessage);
            setReason(true);
            setIsOptionsDropdownOpen(false);
            const errorMessage =
              "Something is wrong. Please check the reason column.";
            handleExceptionError(errorMessage);
          } else if (res.status === 401) {
            handleExceptionError("Unauthorized");
            setTimeout(() => {
              localStorage.clear();
              navigate("/");
            }, 1000);
            ///logout();
          }
        } else {
          handleExceptionError(res.statusText);
        }
      } catch (error) {
        handleExceptionError(error.message);
      }
    }
  };
  const ViewImportFileFormat = async () => {
    try {

      fetch('http://hrmsapi.resolutesolutions.co/uploads/ExportExcelDemo/DepartmentDemoRpt.xlsx')
      .then(response => response.blob())
      .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'DepartmentDemoRpt.xlsx');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
      })
      .catch(error => {
          console.error('Error fetching the file:', error);
      });
    } catch (error) {
      handleExceptionError(error.message);
    }

  };
  const skipAndContinueImport = async () => {
    try {
      const res = await ImportDepartment_api_final(StoreFileName);
      console.log(res);
      if (res) {
        if (res.status === 200) {
          fetchData();
          handleExceptionSuccessMessages(res.data.message);
        } else if (res.status === 400) {
          res = "Request failed with status code 400";
          handleExceptionError(res);
        } else if (res.status === 401) {
          handleExceptionError("Unauthorized");
          setTimeout(() => {
            localStorage.clear();
            navigate("/");
          }, 1000);
          ///logout();
        } else if (res.status === 500) {
          res = "Request failed with status code 500";
          handleExceptionError(res);
        }
      }
    } catch (error) {
      handleExceptionError(error.message);
    }
  };
  return (
    <Dashboard title={"Department"}>
      <Helmet>
        <title>Department List | J mehta</title>
      </Helmet>
      <ErrorSnackbar
        errorMessages={ExceptionError}
        onClearErrors={clearErrors}
      />
      <SuccessSnackbar
        successMessages={successMessages}
        onclearSuccess={clearSuccess}
      />
      {LoaderShow ? (
        <LoaderComponent />
      ) : (
        <>
          {ViewAccess && (
            <div className="client_panel_menu new_client_title">
              <div className="d-flex align-items-center me-auto">
                <div className="client_panel_search client_panel_option">
                  <input
                    value={search}
                    onChange={handleSearchBox}
                    type="text"
                    placeholder="Search"
                    autoFocus
                  />
                </div>

                <div className="d-flex">
                  {isAllowAdd() ? (
                    <div className="client_panel_option">
                      <button
                        onClick={() =>
                          setIsOptionsDropdownOpen(!isOptionsDropdownOpen)
                        }
                      >
                        <img src="./img/Menu.svg" alt="Options" />
                        Options
                      </button>

                      <ul
                        className={`dropdown-menu ${
                          isOptionsDropdownOpen ? "open" : ""
                        }`}
                      >
                        <li onClick={ExportDepartment} className="ExportImport">
                          {" "}
                          <img src="./img/upload.svg" alt="Export " />
                          Export
                        </li>
                        <li
                          className="ExportImport"
                          onClick={handleButtonClick}
                        >
                          {" "}
                          <img src="./img/uploadBigArrow.svg" alt="Import" />
                          <input
                            type="file"
                            id="fileInput"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                          />{" "}
                          Import
                        </li>
                        <li
                          onClick={ViewImportFileFormat}
                          className="ExportImport"
                        >
                          {" "}
                          <img src="./img/Menu(3).svg" alt="Export " />
                          Export Department Records{" "}
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <div style={{ display: "none" }}></div>
                  )}
                  <div className="client_panel_column_chooser">
                    <button
                      onClick={() =>
                        setIsColumnChooserDropdownOpen(
                          !isColumnChooserDropdownOpen
                        )
                      }
                    >
                      <img src="./img/right.svg" alt="Column Chooser" />
                      Column Chooser
                    </button>

                    <ul
                      className={`dropdown-menu ${
                        isColumnChooserDropdownOpen ? "open" : ""
                      }`}
                    >
                      {columns.map((col, index) => {
                        return (
                          <li key={`${index}-${col.Alias}`}>
                            <label className="label_main">
                              {col.Alias}
                              <input
                                type="checkbox"
                                checked={col.IsActive}
                                onChange={() => handleCheckBoxChange(index)}
                              />
                              <span className="geekmark"> </span>
                            </label>
                          </li>
                        );
                      })}

                      <li>
                        <button
                          type="button"
                          onClick={handleConfigurationSave}
                          className="btn btn-theme-primary"
                        >
                          Save
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {isAllowAdd() ? (
                <div className="client_panel_new ms-auto">
                  <Link to="/department">
                    <button className="border-0">
                      <AiFillPlusCircle />
                      New
                    </button>
                  </Link>
                </div>
              ) : (
                <div className="d-none"></div>
              )}
            </div>
          )}

          {ViewAccess ? (
            <div className="overflow-hidden">
              <div className="client_panel_list d-flex align-items-start scroll-container">
                <table
                  className="client_panel_list_table mb-4"
                  cellPadding="0"
                  cellSpacing="0"
                >
                  <tr>
                    <th>Sr. No.</th>
                    {reason === false && (
                      <>
                        <th
                          style={{
                            display: shouldRenderTh ? "none" : "table-cell",
                          }}
                        >
                          Action
                        </th>
                      </>
                    )}
                    {reason === true && (
                      <>
                        <th>Reason</th>
                      </>
                    )}
                    {columns.map(
                      (col, index) =>
                        col.IsActive && (
                          <th
                            key={col.Alias}
                            draggable
                            onDragStart={(e) => handleDragStart(e, index)}
                            onDragOver={(e) => handleDragOver(e, index)}
                            onDrop={(e) => handleDrop(e, index)}
                          >
                            {col.Alias}
                          </th>
                        )
                    )}
                  </tr>
                  {depa_list.map((row, rowIndex) => (
                    <tr key={row.id}>
                      <td>
                        {(currentPage - 1) * recordPerPage + rowIndex + 1}
                      </td>
                      <td
                        key={rowIndex}
                        style={{
                          display:
                            reason === false && shouldRenderTh
                              ? "none"
                              : "table-cell",
                        }}
                      >
                        {reason === false && (
                          <div className="d-flex justify-content-center align-content-center">
                            {isAllowUpdate() && (
                              <EditActionBtn to={`/department/${row["ID"]}`} />
                            )}
                            {isAllowDelete() && (
                              <DeleteActionBtn
                                onClickHandle={() =>
                                  DeleteDepartment(row["ID"])
                                }
                                id={row["ID"]}
                              />
                            )}
                          </div>
                        )}
                        {reason === true && (
                          <>
                            {row["Reason"].split(", ").map((item, index) => (
                              <React.Fragment key={index}>
                                {index + 1 + ". " + item}
                                <br />
                              </React.Fragment>
                            ))}
                          </>
                        )}
                      </td>

                      {columns.map((col) => {
                        let cellContent = null;
                        switch (col.ColumnName) {
                          case "DepartmentID":
                            cellContent = row["ID"];
                            break;
                          case "DepartmentName":
                            cellContent = row["Department"];
                            break;
                          case "DepartmentSName":
                            cellContent = row["ShortName"];
                            break;
                          case "Description":
                            cellContent = row["Description"];
                            break;
                          case "IsManagementDes":
                            cellContent = row["ManagementLevel"];
                            break;
                          case "AddedOn":
                            const AddedOnDate = new Date(row["AddedOn"]);
                            const AddedOnDay = String(
                              AddedOnDate.getDate()
                            ).padStart(2, "0");
                            const AddedOnMonth = String(
                              AddedOnDate.getMonth() + 1
                            ).padStart(2, "0");
                            const AddedOnYear = AddedOnDate.getFullYear();
                            cellContent = `${AddedOnDay}-${AddedOnMonth}-${AddedOnYear}`;
                            break;
                          case "AddedBy":
                            cellContent = row["AddedBy"];
                            break;
                          case "IsFactoryData":
                            cellContent = row["IsFactoryData"];
                            break;
                          case "LastModifiedOn":
                            const LastModifiedOnDate = new Date(
                              row["LastModifiedOn"]
                            );
                            const LastModifiedOnDay = String(
                              LastModifiedOnDate.getDate()
                            ).padStart(2, "0");
                            const LastModifiedOnMonth = String(
                              LastModifiedOnDate.getMonth() + 1
                            ).padStart(2, "0");
                            const LastModifiedOnYear =
                              LastModifiedOnDate.getFullYear();
                            cellContent = `${LastModifiedOnDay}-${LastModifiedOnMonth}-${LastModifiedOnYear}`;
                            break;
                          case "LastModifiedBy":
                            cellContent = row["LastModifiedBy"];
                            break;
                          case "IsActive":
                            cellContent = row["Active"] ? (
                              <span className="badge badge-primary">
                                Active
                              </span>
                            ) : (
                              <span className="badge badge-warning">
                                Inactive
                              </span>
                            );
                            break;
                          default:
                            break;
                        }
                        return (
                          col.IsActive && (
                            <td key={`${row.id}-${col.Alias}`}>
                              {cellContent}
                            </td>
                          )
                        );
                      })}
                    </tr>
                  ))}
                </table>

                <Modal
                  isOpen={modalIsOpen}
                  onAfterOpen={afterOpenModal}
                  onRequestClose={closeModal}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <div className="client_panel_option m-0">
                    <ul className="model_popup p-0">
                      <li>
                        {" "}
                        <img src="./img/Direct-access.svg" alt="Delete" />
                        Manage Functional Access
                      </li>
                      <li>
                        {" "}
                        <img src="./img/Direct-access.svg" alt="Location" />
                        Manage Client Access
                      </li>
                    </ul>
                  </div>
                </Modal>
              </div>
              {reason === false && (
                <>
                  <CustomPagination
                    totalItems={totalPage}
                    itemsPerPage={recordPerPage}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                    onPageSelect={handleItemsPerPageChange}
                  />
                </>
              )}
              {reason === true && (
                <>
                  <div className="client_panel_new mt-4">
                    <button
                      onClick={skipAndContinueImport}
                      className="btn btn-md"
                    >
                      Skip & Continue Import
                    </button>
                  </div>
                </>
              )}
            </div>
          ) : (
            <NoPermission />
          )}
        </>
      )}
    </Dashboard>
  );
};

export default Department_list;
