import { BrowserRouter as Router, Switch, Route, Routes } from 'react-router-dom';
import PrivateRoutes from './components/PrivateRoutes.js';
import Login from './pages/login.js';
import Forgotpass from './pages/forgotpass.js';
import Otp from './pages/otp.js';
import Resetpass from './pages/resetpass.js';
import New_client_panel from './pages/new_client_panel.js';
import Dashboard_main from './pages/dashboard.js';
import Client_panel from './pages/client_panel.js';
import User_entry from './pages/user_entry.js';
import Designation from './pages/designation.js';
import Department from './pages/department.js';
import Employee_entry from './pages/employee_entry.js';
import Page_list from './pages/page_list.js';
import User_panel from './pages/user_panel.js';
import Client_panel_checkbox from './pages/client_panel_checkbox.js';
import Designation_list from './pages/designation_list.js';
import Department_list from './pages/department_list.js';

import Table1 from './pages/Table.js';
import NotFound from './pages/not_found.js';
import NoPermission from './components/NoPermission.js';
import ClientLocationAdd from './pages/client_location_add.js';
import SalaryData from './pages/salary_data.js';
import SalaryEntry from './pages/salary_entry.js';
import ProfessionalTax from './pages/professionalTax_entry.js';
import ProfessionalTax_Data from './pages/professionalTax_data.js';
export const Routes1 = () => {

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/forgotpass" element={<Forgotpass />} />
                <Route path="/otp" element={<Otp />} />
                <Route path="/resetpass" element={<Resetpass />} />
                <Route element={<PrivateRoutes />}>
                    <Route path="/dashboard" element={<Dashboard_main />} />
                    <Route path="/new_client_panel" element={<New_client_panel />} />
                    <Route path="/edit-client/:id" element={<New_client_panel />} />
                    <Route path="/client_panel" element={<Client_panel />} />
                    <Route path="/add-user" element={<User_entry />} />
                    <Route path="/edit-user/:id" element={<User_entry />} />
                    <Route path="/designation" element={<Designation />} />
                    <Route path="/designation/:id" element={<Designation />} />
                    <Route path="/department/:id" element={<Department />} />
                    <Route path="/department" element={<Department />} />
                    <Route path="/employee_entry" element={<Employee_entry />} />
                    <Route path="/page_list" element={<Page_list />} />
                    <Route path="/all-users" element={<User_panel />} />
                    <Route path="/client_panel_checkbox" element={<Client_panel_checkbox />} />
                    <Route path="/all-designation" element={<Designation_list />} />
                    <Route path="/all-department" element={<Department_list />} />
                    <Route path="/test-table" element={<Table1 />} />
                    <Route path="/no-permission" element={<NoPermission />} />
                    <Route path="/client_location_add/:id" element={<ClientLocationAdd />} />
                    {/* <Route path="/client_location_edit/:id" element={<ClientLocationAdd />} /> */}
                    <Route path="/client_location_edit/:LocationID" element={<ClientLocationAdd />} />

                    {/* <Route path="/salary-entry" element={<SalaryEntry />} />
                    <Route path="/salary-entry/:id" element={<SalaryEntry />} />
                    <Route path="/salary-data" element={<SalaryData />} /> */}
                    
                    <Route path="/professionalTax-entry" element={<ProfessionalTax />} />
                    <Route path="/professionalTax-edit/:id" element={<ProfessionalTax />} />
                    <Route path="/professionalTax-data" element={<ProfessionalTax_Data />} />

                </Route>
                <Route path='*' element={<NotFound />} />

            </Routes>
        </Router>
    );
}