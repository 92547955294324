// components/PrivateRoute.js
import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoutes = ({ component: Component, ...rest }) => {
 
  const isAuthenticated = localStorage.getItem('token');
  return (
        isAuthenticated ? <Outlet  /> : <Navigate to="/" />
  );
};

export default PrivateRoutes;
