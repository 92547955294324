// Dashboard.js
import React from 'react';
import Header from './parts/header.js';
import Sidebar from './parts/sidebar.js';

const Dashboard = ({ children, title }) => {
  return (
    <>
      <Header title={title} />
      <Sidebar />
      <div className="dashboard">
        <div className="dashboard-content">
          <main>{children}</main>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
