import React, { useState } from 'react';


const ImageUpload = ({ label }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    setSelectedImage(imageFile);
  };

  return (
    <>
    <div className='dashboard_image_feild mb-4'>
    <label>{label}</label>
    </div>
    <div className="image-upload">
        
        <label htmlFor="upload-input" className="upload-label">
          <img src='/img/uploadimg.png' alt="Static" className="static-image" /><br></br>
          {selectedImage ? 'Change Image' : 'Upload Image'}
        </label>
        <input
          type="file"
          id="upload-input"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: 'none' }} />
        {selectedImage && (
          <div className="image-preview">
            <img src={URL.createObjectURL(selectedImage)} alt="Preview" />
          </div>
        )}
      </div></>
  );
};

export default ImageUpload;
