// App.js
import React, { useState, useEffect } from 'react';
import Dashboard from '../components/dashboard.js';
import { AiFillCloseCircle } from 'react-icons/ai';
import Input2 from '../components/parts/input2.js';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { AiFillPlusCircle } from 'react-icons/ai';
import Select from '../components/parts/select.js';
import { Helmet } from 'react-helmet';




const Client_panel_checkbox = () => {
    const [isOptionsDropdownOpen, setIsOptionsDropdownOpen] = useState(false);
    const [isColumnChooserDropdownOpen , setIsColumnChooserDropdownOpen] = useState(false);
    const [setsrno, setSetsrno] = useState(true);
    const [Users, setUsers] = useState(true);
    const [Address, setAddress] = useState(true);
    const [PhoneNo, setPhoneNo] = useState(true);
    const [Email, setEmail] = useState(true);
    const handleCheckboxChange = (event) => {
        setSetsrno(event.target.checked);
    };
    const handleUsersChange = (event) => {
        setUsers(event.target.checked);
    };
    const handleAddressChange = (event) => {
        setAddress(event.target.checked);
    };
    const handlePhoneNoChange = (event) => {
        setPhoneNo(event.target.checked);
    };
    const handleEmailChange = (event) => {
        setEmail(event.target.checked);
    };
    return (
        <Dashboard>
            <Helmet>
        <title>Client List | J mehta</title>
      </Helmet>
            <div className='client_panel_menu new_client_title'>
                <div className='client_panel_title'>
                    <h2>Client</h2>
                </div>
                <div className='client_panel_search'>
                    <input type="text" placeholder='Search' />
                </div>
                <div className='client_panel_option'>
                    <button  onClick={() => setIsOptionsDropdownOpen(!isOptionsDropdownOpen)}><img src="./img/Menu.svg"/>Options</button>
                    <ul className={`dropdown-menu ${isOptionsDropdownOpen ? 'open' : ''}`}>
                        <li> <img src="./img/Editing.svg" alt="Edit" />Edit</li>
                        <li> <img src="./img/Delete.svg" alt="Delete" />Delete</li>
                        <li> <img src="./img/Location.svg" alt="Location" />Add a New Location</li>
                        <li> <img src="./img/Power_Button.svg" alt="Activate" />De-activate & Activate</li>
                    </ul>
                </div>
                <div className='client_panel_column_chooser'>
                <button  onClick={() => setIsColumnChooserDropdownOpen(!isColumnChooserDropdownOpen )}><img src="./img/right.svg"/>Column Chooser</button>
                <ul className={`dropdown-menu ${isColumnChooserDropdownOpen  ? 'open' : ''}`}>
                        <li>
                            <label className='label_main'>Sr.no
                                <input type="checkbox" checked={setsrno}
                                    onChange={handleCheckboxChange}/>
                                <span class="geekmark"> </span>
                            </label>
                        </li>
                        <li>
                            <label className='label_main'>Users
                                <input type="checkbox" checked={Users}
                                    onChange={handleUsersChange}/>
                                <span class="geekmark"> </span>
                                
                            </label>
                        </li>
                        <li>
                            <label className='label_main'>Address
                                <input type="checkbox" checked={Address}
                                    onChange={handleAddressChange}/>
                                <span class="geekmark"></span>
                            </label>
                        </li>
                        <li>
                            <label className='label_main'>Phone No.
                                <input type="checkbox" checked={PhoneNo}
                                    onChange={handlePhoneNoChange}/>
                                <span class="geekmark"></span>
                            </label>
                        </li>
                        <li>
                            <label className='label_main'>Email
                                <input type="checkbox"checked={Email}
                                    onChange={handleEmailChange} />
                                <span class="geekmark"></span>
                            </label>
                        </li>
                        
                    </ul>
                </div>
                <div className='client_panel_new'>
                <button><AiFillPlusCircle />New</button>

                </div>
                <div className='client_panel_close'>
                <button><AiFillCloseCircle/>Close</button>

                </div>
            </div>
            <div className='client_panel_list'>
                <table className='client_panel_list_table border-none' cellPadding="0" cellSpacing="0">

                    <tr>
                        <th className='border-none'>Select</th>
                    {setsrno == true && (
                        <th>Sr. no</th>
                    )}
                    {Users == true && (
                        <th>Users</th>
                    )}
                    {Address == true && (
                        <th>Address</th>
                    )}
                    {PhoneNo == true && (
                        <th>Phone No.</th>
                    )}
                    {Email == true && (
                        <th>Email</th>
                        )}
                        </tr>
                        {Array.from({ length: 10 }).map((_, i) => (
            <tr key={i}>
                <td className="border-none d-center">
                    <label className="label_main">
                        <input type="checkbox" />
                        <span className="geekmark"></span>
                    </label>
                </td>
                {setsrno && <td>01</td>}
                {Users && <td>Olive Yew</td>}
                {Address && <td>Surat, Gujarat</td>}
                {PhoneNo && <td>+91 98798 79870</td>}
                {Email && <td>info.example@gmail.com</td>}
            </tr>
        ))}

                </table>
            </div>
        </Dashboard>
    );
};

export default Client_panel_checkbox;